import { Component, OnInit, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from "@angular/forms";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
import * as jsPDF from 'jspdf';
//import * as html2canvas from 'html2canvas';
declare var html2canvas:any;

declare var $:any;
declare var Chart:any;
import * as moment from 'moment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  @ViewChild('capture', {static: true}) capture: ElementRef;

  chart = [
      {id: 1, name: 'Line View'},
      {id: 2, name: 'Bar View'},
      {id: 3, name: 'List View'}
  ];
  display: boolean = true;
  selectedChart = 1;
  restriction: boolean =  true;
  all_data;

  channels;
  compare_channels = [];
  compare_array = [];
  page_arr = [];
  dropt: boolean = false;
  choosen_date = {startDate: moment().subtract('15', 'days'), endDate: moment().add('15', 'days'), days: 14};

  choosen_range = [];
  showing_dates = [];

  graph_arr = [];
  points = [];

  border_colors = ['#283ed8', '#e46300', '#ec12a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff', '#48fff3' ];

  donut_color = [
    'red',
    'yellow',
    'green',
    '#7ea5ff',
    'purple',
    'maroon',
    'pink',
    '#808000',
    '#09fb3d',
    '#a7002d',
    '#ffc107',
    '#9a00ff',
    '#48fff3'
  ];

  donutgrey = [
    '',
    'grey',
    '#9B9C9D',
    '#bfbfbf',
    '#bfbbbb',
    '#544e4e',
    '#7b7373',
    '#707070',
    '#d0d0d0',
    '#A7A7A7',
    '#9B9C9D',
    '#9B9C9D',
    '#9B9C9D'
  ]

  stats_box = {x: 760, y: 0};
  graph_line_switch = 0;
  stats_box_data = {
    id: 0,
    image: '',
    checkins: 0,
    date: moment(),
    revenue: 0,
    rev_par: 0,
    avg_daily_rate: 0,
    avrr: 0,
    type: ''
  }

  current_page = 1;
  pagination = [];
  total_set = {
    occupany_rate: 0,
    total_nights: 0,
    avg_nights_per_room: 0,
    avg_daily_rate: 0,
    rev_par: 0,
    avg_lead: 0,
    channel_commission: 0,
    revenue_earned: 0,
    net_revenue: 0,
    reservations: 0,
    cancellation: 0
  };

  metrics = [
    {slug: 'metrics', name: 'Metrics'},
    {slug: 'checkins', name: 'Occupancy Rate'},
    {slug: 'avg_daily_rate', name: 'Average Daily Rate'},
    {slug: 'rev_par', name: 'Revenue Per Available Room'},
  ];
  current_metric = 'metrics';

  metrics_room = [
    {name: 'By Channels'},
    {name: 'By Rooms'},
  ]
  current_donut = 'By Channels';

  showTool:boolean = false;
  toolColor:any = 0;

  d_data = [];
  r_data:any;

  ebr_total:any;
  occ_total:any;
  adr_total:any;
  rev_total:any;

  list_data = [];
  darkMode: boolean = false;
  exte:boolean=true;
  current_currency;
  currency_format = localStorage.getItem('currency');





  @ViewChild('grid_background', {static: false}) grid_background: ElementRef;

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private renderer: Renderer2,
    private elementRef:ElementRef
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    console.log('reports')
    //setTimeout(()=>{this.showEBRChart('EBR')}, 1000);
    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
    });

    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });

    $(".input-checkbox").change(function(){
        $(".input-checkbox").prop('checked',false);
        $(this).prop('checked',true);
    });

    this.drawDoughnut()

    $("li").click(function() {
       // remove classes from all
       $("li").removeClass("active");
       // add class to the one we clicked
       $(this).addClass("active");
    });

    $("#prev").click(function() {
      $('li.active').removeClass('active').prev().addClass('active');
      if ($(this).hasClass("active"))
        $(this).removeClass('active').next().addClass('active');
    });

    $("#next").click(function() {
      $('li.active').removeClass('active').next().addClass('active');
      if ($(this).hasClass("active"))
      // next element per jao
        $(this).removeClass('active').prev().addClass('active');
    });

    //this.getChannels();
    this.get_range();
    this.drag()
  }

  move_box(e){
    if (e.layerX > 0 && e.layerX < 1600) {
      this.stats_box.x = e.layerX - 105;
      this.stats_box.y = e.layerY - 90;
      if (this.graph_line_switch != undefined) {
        this.box_data(e);
      }
    }
  }

  box_data(evt){
    let current_line = this.graph_arr.find(p => p.channel_id == this.graph_line_switch);

    // Find your root SVG element
    let svg = document.querySelector('svg');

    // Create an SVGPoint for future math
    let pt = svg.createSVGPoint();
    pt.x = evt.clientX; pt.y = evt.clientY;
    let pos = pt.matrixTransform(svg.getScreenCTM().inverse());

    if (current_line) {
      let res = current_line.data.find((cl, idx, com) => {
        if (com[idx] && com[idx+1]) {
          let min = com[idx].width || 0;
          let max = com[idx+1].width || 1600;
          return pos.x >= Math.ceil(min) && pos.x <= Math.ceil(max);
        }
        return false;
      });
      if (res) {
        console.log(res);
        this.stats_box_data.checkins = res.check_in;
        this.stats_box_data.revenue = res.revenue;
        this.stats_box_data.rev_par = res.rev_par;
        this.stats_box_data.avg_daily_rate = res.avg_daily_rate;
        //this.stats_box_data.avrr = res.avrr;
        this.stats_box_data.date = moment(res.date, 'YYYY-MM-DD');
      }
    }
  }

  get_range() {
    let range = [];
    let cd_start = this.choosen_date.startDate;
    let cd_end = this.choosen_date.endDate;
    let start = moment(cd_start).toDate();
    let end = moment(cd_end).toDate();
    let space = 0;
    let duration = moment.duration(cd_end.diff(cd_start)).asDays();
    let box_width = 1500/duration;
    let counter = 1;
    this.showing_dates = [];
    while (start < end) {
      range.push({date: moment(start), location: box_width*counter});
      start = new Date(start.setDate(start.getDate() + 1));
      counter++;
    }
    this.choosen_range = range;
    space = range.length > 30 ? range.length > 60 ? 6 : 4 : 3;
    let date_arr = range.map(x => x.date);
    this.showing_dates = date_arr.filter((date, index) => {
      return index%space == 0 ? date : false;
    });
    this.get_graph_data();

    this.current_donut == 'By Channels' ? this.getRoomData() : this.getDonutData();
  }

  get_points(ch){
    // this.points = [];
    const smoothing = 0.15;
    // let arr = [
    //   [12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78],
    //   [12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78, 12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78],
    //   [65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78],
    //   [66,45, 65, 28, 98 ,67, 58, 38,58, 23, 12, 78,12,5 ,12, 160,45, 65, 28, 98, 79, 64, 58, 23, 12, 78],
    //   [65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78, 12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 64, 58, 23, 12, 78],
    //   [45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78, 12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 3, 79, 64, 58, 23, 12, 78]
    // ];

    // let maxRow = ch.map(function(row){ return Math.max.apply(Math, row.check_in); });
    // let max_value = Math.max.apply(null, maxRow);

    const options = {
      yMin: 0,
      yMax: 380,
      xMin: 0,
      xMax: 1500
    }

    const map = (value, inMin, inMax, outMin, outMax) => {
      return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    };

    const pointsPositionsCalc = (points, w, h, options) => points.map(e => {
      const x = map(e.width, options.xMin, options.xMax, 0, w)
      const y = map(e.height, options.yMin, options.yMax, h, 0)
      return [x, y]
      // return [e[0], e[1]]
    })

    const line = (pointA, pointB) => {
      const lengthX = pointB[0] - pointA[0]
      const lengthY = pointB[1] - pointA[1]
      return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
      }
    }

    const controlPoint = (line, smooth) => (current, previous, next, reverse) => {
      const p = previous || current
      const n = next || current
      const l = line(p, n)

      const angle = l.angle + (reverse ? Math.PI : 0)
      const length = l.length * smooth
      const x = current[0] + Math.cos(angle) * length
      const y = current[1] + Math.sin(angle) * length
      return [x, y]
    }

    const bezierCommand = (controlPoint) => (point, i, a) => {
      const cps = controlPoint(a[i - 1], a[i - 2], point)
      const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
      const close = i === a.length - 1 ? ' z':''
      return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}${close}`
    }

    const svgPath = (points, command, h) => {
      const d = points.reduce((acc, e, i, a) => i === 0
          ? `M ${a[a.length - 1][0]},${h} L 0,${h} L 0,${e[1]} l0,0`
          : `${acc} ${command(e, i, a)}`
      , '')

      // return `<path d="${d}" class="svg-path" />`
      return d;
    }

    // const svgCircles = points => points.reduce((acc, point, i, a) => {
    //   return `${acc} <circle cx="${point[0]}" cy="${point[1]}" r="2.5" class="svg-circles" v-for="p in pointsPositions"/>`
    //   // return {data: acc, x: point[0], y: point[1]}
    // }, '')

    const w = 1500;
    const h = 380;

    // let imgs = ['ctrip.png', 'booking.png', 'goibibo.png', 'expedia.png'];
    // for (let i = 0; i < ch.length; i++) {
      // let points = [];

      // this.choosen_range.forEach((obj, index) => {
      //   if (index < ch[i].length) {
      //     points.push([obj.location, (360/100)*((arr[i][index]/max_value)*100), arr[i][index], index]);
      //   }
      // });

      const pointsPositions = pointsPositionsCalc(ch, w, h, options)
      const bezierCommandCalc = bezierCommand(controlPoint(line, smoothing))
      const path = svgPath(pointsPositions, bezierCommandCalc, h);
      // this.points.push({id: i, d: path, name: 'path'+i, img: imgs[i], data_arr: points});
    // }

    return path;
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  switch_paths(name){
    this.graph_line_switch = name;
    if(this.graph_arr){
    let current_line = this.graph_arr.find(p => p.channel_id == this.graph_line_switch);
    this.stats_box_data.id = current_line.channel_id;
    this.stats_box_data.image = current_line.channel_img;
    this.stats_box_data.type = current_line.type
    }
  }

  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
      this.get_range();
      //this.getChannels();
    }
  }

  getChannels(){
    let start = this.choosen_date.startDate.format('YYYY-MM-DD');
    let end = this.choosen_date.endDate.format('YYYY-MM-DD');
    let property_id = localStorage.getItem("current_property");
    this.api.post('api/report/channel_performance' , { property_id: property_id, date_departure_from: start, date_departure_to: end} , true).subscribe((res :any) => {
      this.channels = res.body.data;
      if (this.channels != undefined) {
        this.total_set.revenue_earned = 0;
        this.channels.map(c => this.total_set.revenue_earned += c.total);
        this.total_set.avg_daily_rate = this.channels.map(c => c.avg_daily_rate).reduce((a, b) => a+b, 0);
        this.total_set.total_nights = this.channels.map(c => c.total_nights).reduce((a, b) => a+b, 0);
        this.total_set.channel_commission = this.channels.map(c => c.channel_commission).reduce((a, b) => a+b, 0);
        this.total_set.avg_lead = this.channels.map(c => c.avg_lead).reduce((a, b) => a+b, 0);
        this.total_set.net_revenue = this.channels.map(c => c.net_revenue).reduce((a, b) => a+b, 0);
        this.total_set.rev_par = this.channels.map(c => c.rev_par).reduce((a, b) => a+b, 0);
        this.total_set.occupany_rate = this.channels.map(c => c.occupany_rate).reduce((a, b) => a+b, 0);
        this.total_set.avg_nights_per_room = this.channels.map(c => c.total_nights/c.room_count).reduce((a, b) => a+b, 0);
        this.total_set.cancellation =  this.channels.map(c => c.cancellation).reduce((a, b) => a+b, 0);
        this.total_set.reservations =  this.channels.map(c => c.reservations).reduce((a, b) => a+b, 0);

        this.channels.forEach((c, i) => {
          c.color = this.border_colors[i%this.border_colors.length];
          c.avg_nights_per_room = Math.round(c.total_nights/c.room_count);
          c.rev_percentage = Math.round(((c.net_revenue + c.channel_commission)/this.total_set.revenue_earned)*100);
          c.per_occupany_rate = Math.round(parseInt(((c.occupany_rate/this.total_set.occupany_rate)*100).toFixed(2))) + '%';
          c.per_rev_par = Math.round(parseInt(((c.rev_par/this.total_set.rev_par)*100).toFixed(2))) + '%';
          c.per_total_nights = Math.round(parseInt(((c.total_nights/this.total_set.total_nights)*100).toFixed(2))) + '%';
          c.per_avg_daily_rate = Math.round(parseInt(((c.avg_daily_rate/this.total_set.avg_daily_rate)*100).toFixed(2))) + '%';
          c.per_avg_lead = Math.round(parseInt(((c.avg_lead/this.total_set.avg_lead)*100).toFixed(2))) + '%';
          c.per_channel_commission = Math.round(parseInt(((c.channel_commission/this.total_set.channel_commission)*100).toFixed(2))) + '%';
          c.per_revenue_earned = Math.round(parseInt(((c.total/this.total_set.revenue_earned)*100).toFixed(2))) + '%';
          c.per_net_revenue = Math.round(parseInt(((c.net_revenue/this.total_set.net_revenue)*100).toFixed(2))) + '%';
          c.per_avg_nights_per_room = Math.round(parseInt(((c.avg_nights_per_room/this.total_set.avg_nights_per_room)*100).toFixed(2))) + '%';
        });
      }else{
      }
      this.set_compare_channels();
    })
  }

  drawDoughnut(){
    var DonutChart = function (canvas, radius, lineWidth, arraySlices, label) {
      this._radius = radius;
      this._lineWidth = lineWidth; //px
      this._arraySlices = arraySlices;
      this._label = label;
      this._x_center = canvas.width / 2;
      this._y_center = canvas.height / 2;
      this._context = canvas.getContext("2d");

      this.drawCircle = function () {
          var context = this._context;
          context.lineWidth = this._lineWidth;
          var radius = this._radius;
          var offset_radians = -0.5 * Math.PI;
          var start_radians = offset_radians;
          var counterClockwise = true;
          var self = this;
          this._arraySlices.forEach(function (slice) {
              context.beginPath();
              context.strokeStyle = slice.color;
              var end_radians = start_radians - (Math.PI * 2) * slice.percent / 100;
              context.arc(self._x_center, self._y_center, radius,
              start_radians, end_radians, counterClockwise);
              context.stroke();
              start_radians = end_radians;
          });
      };

      this.drawText = function () {
          var fontSize = this._label.font_size;
          var context = this._context;
          context.font = 'bolder 45px Roboto Black';
          context.textAlign = 'center';
          context.fillStyle = this._label.color;
          context.background_color = this._label.background_color
          var text = this._label.text;
          context.fillText(text, this._x_center, this._y_center + fontSize / 2);
      };

      this.render = function () {
          this.drawCircle();
          this.drawText();
      };

    };

    $(function () {
        //create canvas
        var canvasElem$ = $("<canvas/>");
        $("#content").append(canvasElem$);
        var canvas = $(canvasElem$).get(0);
        var sideLength = 250;
        canvas.width = canvas.height = sideLength;
        var lineWidth = 50;
        var radius = (sideLength - lineWidth) / 2;
        // var label = "Label text";

        var slices = [{
            percent: 15,
            color: "red"
        }, {
            percent: 1,
            color: "#ffffff"
        }, {
            percent: 15,
            color: "#ec13a0"
        },{
          percent: 1,
          color: "#ffffff"
      },
        {
            percent: 15,
            color: "#283ed8"
        }, {
          percent: 1,
          color: "#ffffff"
      }, {
            percent: 8,
            color: "#9a00ff"
        }, {
          percent: 1,
          color: "#ffffff"
      },
      {
            percent: 8,
            color: "#a7002d"
        }, {
          percent: 1,
          color: "#ffffff"
      },
        {
            percent: 5,
            color: "#48fff3"
        }, {
          percent: 1,
          color: "#ffffff"
      },
        {
            percent: 6,
            color: "#e46300"
        }, {
          percent: 1,
          color: "#ffffff"
      },
        {
            percent: 22,
            color: "#09fb3d"
        } ,
        {
          percent: 1,
          color: "#ffffff"
      }
      ];

        var label = {
            text: "73%",
            color: "#7e7cea",
            font_size: 25,
            font_weight: 800,
            font_family: 'robotoblack',
            background_color: "#ffffff",

        };

        var donutChart = new DonutChart(canvas, radius, lineWidth, slices, label);
        donutChart.render();
    });
  }

  dougnuts(){

    this.compare_array.forEach((c, i) => {
      setTimeout(() => {
        const segments = document.querySelectorAll(".donut-segment"+i);
        // First segment's offset
        const firstSegmentOffset = parseInt(segments[0].getAttribute("stroke-dashoffset"));

        // Total of all preceding segments length
        // For use in stroke-dashoffset calculation
        let preSegmentsTotalLength = parseInt(segments[0].getAttribute("data-per"));

        // let preSegmentsTotalLength = 0;
        segments.forEach((segment, i) => {
          // percentage occupied by current segment
          let percent = parseInt(segment.getAttribute("data-per"));

          // current segments stroke-dasharray calculation
          let strokeDasharray = `${percent} ${100 - percent}`;
          // setting stroke-dasharray for all segments
          segment.setAttribute("stroke-dasharray", strokeDasharray);

          if (i != 0) {
            // current segments stroke-dashoffset calculation
            let strokeDashoffset = `${100 - preSegmentsTotalLength + firstSegmentOffset}`;

            // setting stroke-dasharray for all segments
            segment.setAttribute("stroke-dashoffset", strokeDashoffset);

            // Updating total of all preceding segments length
            preSegmentsTotalLength += percent;
          }
        })
      }, 1000);
    })

  }

  set_compare_channels(){
    this.compare_array = [];
    if (this.compare_channels.length > 1) {
      this.compare_array = this.channels.filter(c => this.compare_channels.indexOf(c.channel_id) != -1);
    }else{
      this.compare_array = this.channels != undefined ? JSON.parse(JSON.stringify(this.channels)) : [];
    }

    this.create_pagination();
    //this.dougnuts();
  }

  change_page(move_to){
    if (move_to == 'forward') {
      this.current_page < this.pagination.length ? this.current_page++ : null;
    }else if (move_to == 'backward') {
      this.current_page > 1 ? this.current_page-- : null;
    }else if (move_to == 'first') {
      this.current_page = 1;
    }else if (move_to == 'last') {
      this.current_page = this.pagination.length;
    }else{
      this.current_page = move_to;
    }
  }

  create_pagination(){
    let chunk = 5;
    this.page_arr = [];
    for(let i=0; i<this.list_data.length; i+=chunk){
        this.page_arr.push(this.list_data.slice(i, i+chunk));
    };
    this.pagination = this.page_arr.map((m, i) => i+1);
  }

  reset_filter(){
    this.selectedChart = 1;
    this.compare_channels = [];
    this.choosen_date = {startDate: moment().subtract('15','days'), endDate: moment().add('15', 'days'), days: 15}
    this.current_page = 1;
    this.get_range();
    //this.getChannels();
  }

  get_graph_data(){
    let graph = [];
    let a = {
      property_id : localStorage.getItem('current_property'),
      date_departure_from: moment(this.choosen_date.startDate).format('YYYY-MM-DD'),
      date_departure_to : moment(this.choosen_date.endDate).format('YYYY-MM-DD')
    };
    this.api.post('api/report/get_graph_data', a, true).subscribe((res: any) => {
      let data = res.body.data;
      let max_value = Math.max.apply(null, data.map(m => m.total_sum));
      data = data.reduce((a, b) => {
        if (b.channel != null) {
          a[b.channel.id] = [...a[b.channel.id] || [], b];
        }
        return a;
      }, [])
      data = data.sort(function(a,b) {
          return a[0].total_sum - b[0].total_sum;
      });
      data = data.reverse();
      data.forEach((ch) => {
        let arr = [];
        this.choosen_range.forEach((r, i) => {
          let date = r.date.format('YYYY-MM-DD');
          let already = ch.find(f => date == f.date_departure);
          if (!already) {
            arr.push({
              id: i,
              date: date,
              check_in: 0,
              revenue: 0,
              rev_par: 0,
              avg_daily_rate: 0,
              avrr: 0,
              width: r.location,
              height: (360/100)*((0/max_value)*100),
            });
          }else{
            arr.push({
              id: i,
              date: date,
              check_in: already.check_in_count,
              revenue: already.total_sum,
              rev_par: already.rev_par,
              avg_daily_rate: already.avg_daily_rate,
              avrr: parseInt(already.avrr),
              width: r.location,
              height: (360/100)*((already.total_sum/max_value)*100),
            });
          }
        });
        arr.sort((a,b) => {
          return new Date(b.date) > new Date(a.date) ? -1 : new Date(b.date) < new Date(a.date) ? 1 : 0;
        });
        graph.push({channel_id: ch[0].channel.id, channel_img: ch[0].channel.image, data: arr, points: this.get_points(arr), type: ch[0].channel.type})
      });

      this.graph_arr = graph;
      //this.setGraph();
      if(graph.length != 0) this.switch_paths(graph[0].channel_id);
    });
    // setTimeout(()=>{
    //   this.setGraph();
    // },1000)
  }

  date_change(e){
    if (e) {
      this.get_range();
      //this.getChannels();
    }
  }

  change_center_date(date){
    let days = this.choosen_date.days;
    this.choosen_date = {startDate: moment(date).subtract(days, 'days'), endDate: moment(date).add(days, 'days'), days: days};
    this.get_range();
    //this.getChannels();
  }

  // Earning by Channels
  getDonutData(){
    let a = {
      property_id: localStorage.getItem('current_property'),
      date_departure_from: moment(this.choosen_date.startDate).format("YYYY-MM-DD"),
      date_departure_to: moment(this.choosen_date.endDate).format("YYYY-MM-DD")
    };
    this.api.post('api/report/report_by_room_new', a, true).subscribe((res:any)=>{
      this.d_data = res.body.data;
      console.clear();

      setTimeout(()=>{
        this.d_data.map((val, i)=>{
          let a = [];
          let b = [];

          let c = ['','grey','#9B9C9D','#bfbfbf','#bfbbbb','#544e4e','#7b7373','#707070','#d0d0d0','#A7A7A7','#9B9C9D','#9B9C9D','#9B9C9D'];
          c[0] = this.donut_color[i];

          val.channels.map((v)=>{
            a.push(val.total_sum);
            b.push(v.channel_name);
          });

          console.log(a)
          console.log(b)
          if(i%2 == 0){
            console.log(a);
            this.showChart(i, a, b, c);
          }else{
            this.showChart2(i, a, b, c);
          }
        }, 250)
      })
    })
  }

  showChart(id, val, n, c){
    console.log(val);
    var ctx = document.getElementById('myChart_'+id);
    var myChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: n,
          legend: {position: 'bottom'},
          datasets: [{
            data: val,
            backgroundColor: c,
            borderWidth: 0
          }]
        },
        options: {
          cutoutPercentage: 70,
          legend: {
            display: false
          },
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function (tooltipModel) {

              // Tooltip Element
              var tooltipEl: any = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = "";

                titleLines.forEach(function (title) {
                  innerHtml += "<div>" + title + "</div>";
                });
                innerHtml += "<div>";

                bodyLines.forEach(function (body, i) {
                  var colors = tooltipModel.labelColors[i];
                  let n = body[0];
                  let re = n.split(':')[0].toLowerCase();
                  let amt = n.split(':')[1];
                  innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                    <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                    <div style='font-weight: bold; font-size: 16px;text-align:right;'>${amt} %</div>
                  </div>`;
                });
                innerHtml += "</div>";
                var tableRoot = tooltipEl.querySelector('.cus-box');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          }
        }
    });
  }

  showChart2(id, val, n, c){
    var ctx = document.getElementById('myChart_1'+id);
    var myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: n,
        legend: {position: 'bottom'},
        datasets: [{
          data: val,
          backgroundColor: c,
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:right;'>${amt} %</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      }
    });
  }

  // Earning by Channels

  chandrop(id) {
    let a =  document.getElementById(id)
    if(a.classList.contains('rotat')){
        a.classList.remove('rotat')
    }
    else{
      a.classList.add('rotat')
    }
  }

  sortCompareArr(items = []){
    return items.sort((a, b) => b.percentage - a.percentage);
  }
  //Donut WOrk End

  //List and Bar API
  getList_barData(type){
    if(type!=null){
      let a = {
        type: type,
        property_id: localStorage.getItem('current_property'),
        date_departure_from: moment(this.choosen_date.startDate).format("YYYY-MM-DD"),
        date_departure_to: moment(this.choosen_date.endDate).format("YYYY-MM-DD")
      }
      this.api.post('api/report/report_bar_and_list', a, true).subscribe((res:any)=>{

        this.list_data = this.sortCompareArr(res.body.data);
        this.create_pagination();
      })
    }

  }

  changeView(current_donut){

    if(current_donut == 'By Channels'){
      this.getRoomData();
    }else{
      this.current_donut = 'By Room';
      this.getDonutData();
    }
    setTimeout(()=>{
      this.drag();
    },500)
  }

  //Earning by ROOm section
  getRoomData(){
    let a = {
      property_id: localStorage.getItem('current_property'),
      date_departure_from: moment(this.choosen_date.startDate).format("YYYY-MM-DD"),
      date_departure_to: moment(this.choosen_date.endDate).format("YYYY-MM-DD")
    };
    this.api.post('api/report/report_by_room_new_by_sort', a, true).subscribe((res:any)=>{
      this.r_data = res.body.data;
      this.calculateEbr();
    })
  }

  calculateEbr(){
    this.ebr_total = 0;
    this.occ_total = 0;
    this.adr_total = 0;
    this.rev_total = 0;

    let EbrA  = [];
    let Ebrl = [];
    this.r_data.Room_earn.map((v)=>{
      EbrA.push(v.percentage);
      Ebrl.push(v.room_type_name)
      this.ebr_total += v.room_sum;
    });

    let occA = [];
    let occL = [];
    this.r_data.Occ.map((val)=>{
      occA.push(val.occ);
      occL.push(val.channel_image.split('.')[0]);
      this.occ_total += val.channel_sum;
    });

    let adrA = [];
    let adrL = [];
    this.r_data.ADR.map((val)=>{
      adrA.push(val.percentage);
      adrL.push(val.channel_image.split('.')[0]);
      this.adr_total += val.channel_sum;
    });

    let revA = [];
    let revL = [];
    this.r_data.RevPar.map((val)=>{
      revA.push(val.percentage);
      revL.push(val.channel_image.split('.')[0]);
      this.rev_total += val.rev_par;
    });

    setTimeout(()=>{
      this.setEbr(EbrA,Ebrl);
      this.setOcc(occA, occL);
      this.setAdr(adrA, adrL);
      this.setRev(revA, revL);
    },250);
  }

  setEbr(d, l){
    let ctx = document.getElementById('EBR1');
    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: {position: 'bottom'},
        datasets: [{
          data: d,
          backgroundColor: [
            'red',
            '#9100ff',
            '#3c008b',
            '#0019ff',
            '#00ffee',
            '#00aa06',
            '#d8d800',
            '#ff7700'
          ],
          borderWidth: 0
        }]
      },
      options: {
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            padding: 20
          }
        },
         aspectRatio: 0,
        cutoutPercentage: 60,
        responsive: false,
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
      }

    });
  }

  setOcc(d, l){
    let ctx = document.getElementById('occ1');
    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: {position: 'bottom'},
        datasets: [{
          data: d,
          backgroundColor: [
            'red',
            '#9100ff',
            '#3c008b',
            '#0019ff',
            '#00ffee',
            '#00aa06',
            '#d8d800',
            '#ff7700'
          ],
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:right;'>${amt} %</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      }
    });
  }

  setAdr(d, l){
    let ctx = document.getElementById('ADR1');
    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: {position: 'bottom'},
        datasets: [{
          data: d,
          backgroundColor: [
            'red',
            '#9100ff',
            '#3c008b',
            '#0019ff',
            '#00ffee',
            '#00aa06',
            '#d8d800',
            '#ff7700'
          ],
          borderWidth: 0
        }]
      },
      // options: {
      //   legend: {
      //     display: false,
      //     position: 'bottom',
      //     labels: {
      //       boxWidth: 20,
      //       padding: 20
      //     }
      //   },
      //    aspectRatio: 0,
      //   cutoutPercentage: 60,
      //   responsive: false,
      //   title: {
      //     display: false,
      //   },
      //   animation: {
      //     animateScale: true,
      //     animateRotate: true
      //   }
      // }
      options: {
        cutoutPercentage: 70,
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:right;'>${amt} %</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      }
    });
  }

  setRev(d, l){
    let ctx = document.getElementById('REV1');
    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: {position: 'bottom'},
        datasets: [{
          data: d,
          backgroundColor: [
            'red',
            '#9100ff',
            '#3c008b',
            '#0019ff',
            '#00ffee',
            '#00aa06',
            '#d8d800',
            '#ff7700'
          ],
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:right;'>${amt} %</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      }
    });
  }
  //Earning by ROOm section  End

  //Generate PDF
  makePDF(){
    this.exte = false
    setTimeout(()=>{
      let HTML_Width = this.capture.nativeElement.offsetWidth;
    let HTML_Height = this.capture.nativeElement.offsetHeight;
    let top_left_margin = 15;
    let PDF_Width = HTML_Width+(top_left_margin*2);
    let PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    let canvas_image_width = HTML_Width;
    let canvas_image_height = HTML_Height;
    let totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
    html2canvas(this.capture.nativeElement,{allowTaint:true}).then(function(canvas) {
      canvas.getContext('2d');
      let imgData = canvas.toDataURL();
      let pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'SVG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(imgData, 'SVG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
      }
      pdf.save("Reports.pdf");

    });
    this.exte = true
    },1000)
  };
  //Generate PDF END
  chngeEx(img){
    let a = img.split('.')
    return a[0]
  }

  drag(){
    // drag function start----------------------------------------------
    $(function () {
      setTimeout(function(){
          $(".drag_box").dragsort({
            dragSelector: ".headingOne0",
            dragBetween: true,
            dragEnd: () => {
              var data = $(".drag_box .drag_item").map(function () {
                      return $(this).children().html();
                  }).get();
              },
              placeHolderTemplate: "<div class='placeHolder' style='height:auto !important; width: 100%!important; padding-right:10px; padding-bottom:10px'>" +
              "<div style='margin:3px;'>&nbsp;</div>" +
              "</div>",
              cursor: "grab"
          });
      }, 1000)
    });
    // drag function end---------------------------------------------
  }

  // getDivs(a){
  //   let f = $($( "#first div" ).first()).html();
  //   let b = $("#"+a).html();
  //   $('#'+a).html(f);
  //   $($( "#first div" ).first()).html(b);
  // }

}





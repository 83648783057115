// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-stays2',
//   templateUrl: './stays2.component.html',
//   styleUrls: ['./stays2.component.css']
// })
// export class Stays2Component implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { Component, OnInit, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
import * as jsPDF from 'jspdf';
import * as html2canvas from "html2canvas";
import { FormBuilder,FormGroup,Validators,ValidationErrors, FormArray,FormGroupDirective } from "@angular/forms";
declare var $: any;
import * as moment from 'moment';
import ScrollBooster from 'scrollbooster';
import { from, race } from 'rxjs';

@Component({
  selector: 'app-stays2',
  templateUrl: './stays2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./stays2.component.css']
})

export class Stays2Component implements OnInit {
  selectedRow: number = 0;
  colors = ['#f98282', '#49fc6d', '#9093e2', '#ef85ff', '#be7f2f', '#ffe878', '#43eecc', '#49fc6d'];
  rateData;
  returnee :string = 'New'
  showCalendar:boolean = true
  scroller;
  stop_scroll = false;
  showsmoke:boolean = true;
  showwheel:boolean = true;
  showpets:boolean = true;
  darkMode: boolean = false;
  resWidth;
  totalnights;
  room_number;
   room_id;
  allPackages;
  allPackages2;
  current_season_id;
  current_season;
  pricesArray = [];
  todayDate;
  submitted=false;
  allChannels:any;
  allPromo = [];
  promoDiscount = null;
  weather_update = [];
  seperO;
  seperA;
  applyChkInDate;
  applyChkOutDate : boolean = false;
  check_out;
  check_in;
  comaArrayRate = [];
  prico = [];
  current_property = localStorage.getItem('current_property')
  WHcolors = ['#ffc107','#ec1361','#09fb3d','#ff51f3','#48fff3','#9a00ff','#ff6f00'];
  perDayData = [];
  disabledPop=true;
  toolChkData;
  start;
  end
  minDate_dep:moment.Moment = moment().subtract(-1, "days");
  roomFilter:boolean=true;
  segmonLeft:boolean = true;
  bookingStatus:boolean = true;
  minDate: moment.Moment = moment().subtract(0, 'days');
  maxDate: moment.Moment = moment().add(2, 'month');
  onlyShowBooking;
  Discount = 'false';
  user = JSON.parse(localStorage.getItem("user"));
  randomColors = []
  TaxInclusive;
  subtotal;
  Tax
  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {

  }

  @HostListener('scroll', ['$event'])
  onTableScroll(event) {
    let gap = Math.floor(event.target.scrollLeft/180);
    if ((event.target.scrollLeft + event.target.clientWidth) == event.target.scrollWidth) {
      this.calendar_date.endDate = this.calendar_date.endDate.add('8', 'days');
      this.get_range();
    }

    if (event.target.scrollLeft == 0 && this.current_view.scrollx == event.target.scrollTop && this.current_view.scrolly != event.target.scrollLeft) {
      this.calendar_date.startDate = this.calendar_date.startDate.subtract('4', 'days');
      this.get_range();
      this.current_view.startDate = this.current_view.startDate.subtract('4', 'days')
      this.current_view.endDate = this.current_view.endDate.subtract('4', 'days');
      gap = 1;
      event.target.scrollLeft = 5;
    }

    if (gap != this.current_view.gap) {
      let space = gap - this.current_view.gap;
      this.current_view.startDate = this.current_view.startDate.add(space, 'days')
      this.current_view.endDate = this.current_view.endDate.add(space, 'days')

      clearTimeout(this.current_view.interval);
      let id = setTimeout(() => {
        this.current_table();
      }, 250);

      this.current_view.gap = gap;
      this.current_view.interval = id;
    }

    this.current_view.scrollx = event.target.scrollTop;
    this.current_view.scrolly = event.target.scrollLeft;
  }

  ngOnInit() {
    this.get_roomtypes();
    this.checkDarkMode();
    this.get_range();
    this.current_table();
    this.table_drag();
    this.createMaintenanceForm();
    this.getseason();
    this.RoomIconFilter(true,true);
    this.getChannels()
    this.getWeather()
    this.recievedData();
    this.permissions();
    $(() => {
      $('table').on('click', '.dropdown-menu > *', function (e) {
        e.stopPropagation();
      });
      $('table').on('click', '.channel-menu > *', function (e) {
        e.stopPropagation();
      });

      $('table').on('click', '#closechannel', function (e) {
        $(this).closest('.otas_connected').toggleClass("open");
      });

      $('#bulk_update').on('show.bs.modal', () => {
        //this.get_bulk_update();
      });

      $('#edit_rate').on('show.bs.modal', () => {
        //this.get_selected_packages();
      });

      $( "#bulk_update" ).draggable();
      $( "#bulk_update" ).draggable('disable');

      $( "#showBulkUpdate" ).draggable();
      $( "#showBulkUpdate" ).draggable('disable');

      $( "#housekeeping" ).draggable();
      $( "#housekeeping" ).draggable('disable');

      $( "#channel" ).draggable();
      $( "#channel" ).draggable('disable');

    })
    $(function() {
      $(".modal").mouseup(function(){
        $(".bluheader").css({"cursor":"grab"})
      });
      $(".modal").mousedown(function(){
        $(".bluheader").css({"cursor":"grabbing"})
      });
    });
    //drag function code--------------------
    $(function () {
      $("#drag-list").dragsort({
        placeHolderTemplate:`<div class='placeHolder' style=''><div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 70px;margin-bottom:5px;'></div></div>`,
        cursor: "move"
      });
    });
    $(function () {
      setTimeout(function(){
          $(".drag_box").dragsort({
              dragSelector: "div",
              dragBetween: true,
              dragEnd: () => {
                  var data = $(".drag_box .drag_item").map(function () {
                      return $(this).children().html();
                  }).get();
              },
              placeHolderTemplate: "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
              "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
              "</div>",
              cursor: "move"
          });
      }, 1000)
    });
    // drag function end-------------------
    this.resWidth = window.innerWidth
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  get mf(){ return this.maintenence_form.controls;};
  get rf(){return this.reservation_form.controls;};

  remove_vowel(str) {
    str = str.split(' ')[0] + (str.split(' ')[1] || '');
    str = str.replace(/[aeiou]/gi, '');
    return str;
  }

  chnageicon1(){
   this.showsmoke = !this.showsmoke;
  }

  chnageicon2(){
    this.showwheel = !this.showwheel;
  }

  chnageicon3(){
    this.showpets = !this.showpets;
  }

  dodrag(id){
    $( "#"+id ).draggable('enable');
  }

  dontdrag(id){
    $("#"+id).draggable('disable');
  }

  rotateDrop(id){
    let a = document.getElementById(id);
    a.classList.contains('rotate') ? a.classList.remove('rotate') : a.classList.add('rotate')
  }

  //New Inventory Work
  roomtypes = [];
  selectedRoom = [30, 17];
  inventory_calendar = [];
  inventory = [];
  calendar_date = { startDate: moment(), endDate: moment().add('30', 'days') };
  calendar_range = [];
  current_view = { startDate: moment().subtract('1', 'days'), endDate: moment().add('8', 'days'), gap: 0, interval: null, scrollx: 0, scrolly: 0};
  current_range = [];

  get_roomtypes(){
    this.api.post('api/roomtype/index?per_page=50', {property_id:localStorage.getItem("current_property")},true).subscribe((res:any) => {
      this.roomtypes = res.body.data;
      for(let i = 0; i < 2; i++){
        //this.selectedRoomType.push(this.roomtypes[i].id)
      }


    }, err => this.error_handling.handle_error(err.status));
  }

  selectTopRoom(selectedRoom){
    this.selectedRoom = selectedRoom;
    if(selectedRoom.length ==2){
      this.map_boxes();
    }
  }

  get_range(from_picker = false) {
    let range = [];

    let start = moment(this.calendar_date.startDate).toDate();
    let end = moment(this.calendar_date.endDate).toDate();

    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }

    if (from_picker) {
      document.querySelector('.scroll').scrollLeft = 5;
      while(range.length < 9){
        range.push(moment(range[range.length-1]).add('1', 'days'));
      }
      this.current_view.startDate = range[0];
      this.current_view.endDate = range[range.length-1];
      this.current_view.gap = 0;
    }

    this.calendar_range = range;
  }

  current_table(new_start = null, new_end = null){
    let range = [];
    let start = moment(new_start || this.current_view.startDate).toDate();
    let end = moment(new_end || this.current_view.endDate).toDate();

    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }

    this.current_range = range;
    this.get_inventory();
  }

  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
      this.get_range(true);
      this.current_table();
    }
  }

  get_inventory(){
    this.inventory = [];
    this.map_boxes();
  }

  map_boxes(){
    let a = {
      property_id: localStorage.getItem("current_property"),
      user_id: JSON.parse(localStorage.getItem('user')).id,
      start_date: this.current_view.startDate.format('YYYY-MM-DD'),
      end_date: this.current_view.endDate.format('YYYY-MM-DD'),
      //room_type_id: this.selectedRoom.join()
    };

    this.api.post('api/inventory/calendar', a, true).subscribe((res: any) => {
      this.inventory_calendar = res.body.data;
      this.ref.markForCheck();
    }, err => this.error_handling.handle_error(err.status, err.message))
  }

  week_move(move) {
    let scroll_obj = document.querySelector('.scroll');
    if (move == 'next') {
      scroll_obj.scrollLeft += 1440;
    }else{
      scroll_obj.scrollLeft -= 1440
    }
  }

  table_drag() {
    let viewport = <HTMLElement>document.querySelector('.scroll');
    let content = <HTMLElement>document.querySelector('.grid_calendar');

    this.scroller = new ScrollBooster({
      viewport,
      content,
      direction: 'horizontal',
      bounce: false,
      emulateScroll: false,
      inputsFocus: false,
      onUpdate: (state) => {
        viewport.scrollLeft = state.position.x;
      }
    });
  }

  check_pkg(pkg_id, date, e){
    // if (e.target.checked) {
    //   this.checked_pkg.push({date: date, pkg_id: pkg_id});
    // } else {
    //   this.checked_pkg = this.checked_pkg.filter((val, i) => {
    //     return val.date == date && val.pkg_id == pkg_id ? false : true;
    //   });
    // }
    // this.get_selected_packages();
  }

  change_check_pkg(i, day, e){
    // let change = false;
    // let chks = this.checked_pkg.map((chk) => chk.pkg_id).filter((item, pos, self) => {
    //   return self.indexOf(item) == pos;
    // });

    // if (e.target.checked) {
    //   chks.forEach(chk => {
    //     let start = moment(this.bulk_dates.startDate).toDate();
    //     let end = moment(this.bulk_dates.endDate).toDate();
    //     while (start < end) {
    //       if(moment(start, 'YYYY-MM-DD').format('dddd') == day){
    //         this.checked_pkg.push({date: moment(start).format('YYYY-MM-DD'), pkg_id: chk});
    //       }
    //       start = new Date(start.setDate(start.getDate() + 1));
    //     }
    //   });
    // } else {
    //   chks.forEach(chk => {
    //     this.checked_pkg = this.checked_pkg.filter((val, i) => {
    //       return moment(val.date, 'YYYY-MM-DD').format('dddd') == day && val.pkg_id == chk ? false : true;
    //     });
    //   });
    // }

    // this.get_selected_packages();
    // this.get_bulk_update();
  }

  change_date_pkg(e){
    // let start = moment(e.startDate).toDate();
    // let end = moment(e.endDate).toDate();
    // let chks = this.checked_pkg.map((chk) => chk.pkg_id).filter((item, pos, self) => {
    //   return self.indexOf(item) == pos;
    // });

    // this.checked_pkg = [];
    // while (start < end) {
    //   chks.forEach((chk) => {
    //     this.checked_pkg.push({date: moment(start).format('YYYY-MM-DD'), pkg_id: chk});
    //   });
    //   start = new Date(start.setDate(start.getDate() + 1));
    // }
    // this.get_selected_packages();
    // this.get_bulk_update();
  }

  is_pkg_checked(date, id){
    //return this.checked_pkg.some(e => e.date == date && e.pkg_id == id);
  }

  is_pkg_disabled(id){
    // if (this.checked_pkg.length != 0) {
    //   let pkg_id = this.checked_pkg[0].pkg_id;
    //   let pkg = this.selected_packages.filter((sp) => sp.id == pkg_id);
    //   return pkg[0].room_type_id == id ? false : true;
    // }
    // return false;
  }

  // check_box_status(res){
  //   //return res.some(r => r == 'cta' || r == 'ctd');
  //   return false;
  // }

  is_box_shown(boxes, date){
    return boxes.find(b => b.date == date.format('YYYY-MM-DD')) || false;
  }

  trackByInv(index, item){
    return index
  }

  trackByVen(index, item){
    return index
  }

  trackByBox(pkg_id, index, item){
    return index + '_' + pkg_id
  }

  trigger_click(){
    let obj = <HTMLElement>document.querySelector('.buttons .buttons_input .btn');
    obj.click();
  }

  input_focus($event){
    $event.target.focus();
  }

  count_availability(date, obj){
    let counter = 0;
    obj.forEach(o => {
      o.closed_dates.some(c => c == date) || counter++
    });
    return counter;
  }

  scrollNow(){
    let a = document.getElementById('scroll_wrapper');
    a.scrollTop = 1;
  }
  //New Inventory Work END

  box_channels_internal = [];
  box_channels_extarnal = [];
  //New Box Work
  open_channel_box(ch){
    ch.forEach(e => {
      if(e.type == 'External'){
        this.box_channels_extarnal.push(e);
      }else{
        this.box_channels_internal.push(e);
      }
    });
    $('#channel').modal({backdrop: 'static', keyboard: true});
    $('#channel').draggable();
  }

  selectShow(i){
    if(this.selectedRow  == i){
      this.selectedRow = undefined;
    }else{
      this.selectedRow = i;
    }
  }

  invCount(c, d){
    if(c != undefined && d !=undefined){
      let f = c.filter(e=> e.date == d)
      return f.length == 0 ? 0 : f[0].room_count;
    }
    return 0;
  }
  //New Box work END

  //Close for Maintenence
  vendorList = [];
  selectedVendor: any;
  maintenence_form: FormGroup;
  selectedVen:any;
  mSubmit:boolean = false;

  onclickhouse(ven, date){
    this.selectedVen = ven;
    this.selectedVen.date = date;
    this.getVandorList();
  }

  getVandorList(){
    this.api.post('api/user/vendor_list', {vendor_type: 'External'}, true).subscribe((res:any)=>{
      this.vendorList = res.body.data;
      setTimeout(()=>{
        this.maintenence_form.patchValue({
          building_id: this.selectedVen.building_id,
          room_no_id: this.selectedVen.id,
          floor_id: this.selectedVen.floor_id,
          start_date: moment(this.selectedVen.date).format('YYYY-MM-DD')
        })
      })
      $('#housekeeping').modal('show');
    }, err => this.error_handling.handle_error(err.status))
  }

  createMaintenanceForm(){
    this.maintenence_form = this.fb.group({
      //name: ['', Validators.required],
      assigned_by_id: JSON.parse(localStorage.getItem('user')).id,
      vendor_id : [null, Validators.required],
      building_id : ['', Validators.required],
      floor_id : ['', Validators.required],
      room_no_id : ['', Validators.required],
      start_date : ['', Validators.required],
      issue : ['', Validators.required]
    })
  }

  saveCloseMaintenance(){
    this.mSubmit == true;
    if (this.maintenence_form.invalid) {
      return;
    }

    this.api.post('api/maintenance/store', this.maintenence_form.value, true).subscribe((res:any)=>{
      this.map_boxes();
      this.helper.alert_success("Vendor Assigned Successfully");
    }, err => this.error_handling.handle_error(err.status))


  }
  //Close for Maintenence END

  //Reserveation popup WOrk
  reservation_form:FormGroup;
  reservationSubmit: boolean = false;
  room_name;

  RoomIconFilter(condition,pEvents){
    this.allPackages = this.allPackages2;
    if(condition == true){
      this.showsmoke = false; this.showwheel = false ; this.showpets = false
    }else{
      setTimeout(()=>{
        if(this.reservation_form.value.date_arrival.endDate != undefined && this.reservation_form.value.date_departure.endDate != undefined || this.reservation_form.value.date_arrival.endDate != null && this.reservation_form.value.date_departure.endDate != null ){
          this.getroomtype(this.showsmoke,this.showwheel,this.showpets,condition,moment(this.reservation_form.value.date_arrival.endDate._d).format('YYYY-MM-DD'),moment(this.reservation_form.value.date_departure.endDate._d).format('YYYY-MM-DD'))
        }
       else{
        this.getroomtype(this.showsmoke,this.showwheel,this.showpets,condition,this.reservation_form.value.date_arrival,this.reservation_form.value.date_departure)
       }
      },200)
    }
    if(!pEvents){
      // (<HTMLInputElement>document.getElementById('cNone')).style.pointerEvents = 'all';
     setTimeout(()=>{
      (<HTMLInputElement>document.getElementById('btnSaveEv')).style.display = 'block';
     },500)
      this.disabledPop = true
    }
  }
  getroomtype(smoking,wheelchair,pets,condition,dArr,dDep){

    let a = {
      property_id : localStorage.getItem("current_property"),
      start_date  : dArr,
      end_date    : dDep,
      wheelchairaccessibility:wheelchair.toString(),
      smoking:smoking.toString(),
      pets:pets.toString()
    };
    let b = {
      property_id : localStorage.getItem("current_property"),
      start_date  : dArr,
      end_date    : dDep,
    };
    this.api.post('api/booking/reservation_room_types', condition == false ? a : b, true).subscribe((res:any)=>{
       this.room_name=res.body.data;
    }, err => this.error_handling.handle_error(err.status))
  }

  // ******* reservation API *******

  getseason(){
    this.api.post('api/season_package/index',{property_id: localStorage.getItem('current_property')} ,true).subscribe((res:any) => {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      // let todayDate = mm + '-' + dd + '-' + yyyy;
       this.todayDate = yyyy + '-' + mm + '-' + dd;
      res.body.data.seasons.data.map((val1)=>{
        if(this.todayDate<=val1.date_from  && this.todayDate >=val1.date_to){
          this.current_season_id = val1.id
          this.current_season = val1
        }
      })
      this.createRservationForm();
      this.addNewGuest(this.reservation_form.controls.booking_items,null,null)
      this.reservation_form.patchValue({
        currency:localStorage.getItem('currency')
      })
    }, err => this.error_handling.handle_error(err.status, err.message))

  }

  getroomnumber(id,index){
    let roomIdForApi;
    if(id == undefined){
      return
    }else{
      this.room_name.map((val)=>{
        if(val.room_id == id.room_id){
          roomIdForApi = val.room_id
          val.pets == 'true' ? this.showpets = true : this.showpets = false
          val.wheelchairaccessibility == 'true' ? this.showwheel = true : this.showwheel = false
          val.smoking == 'true' ? this.showsmoke = true : this.showsmoke = false
        }
      })
    }
      let data  = ({
      date_arrival: moment(this.reservation_form.value.date_arrival.endDate).format('YYYY-MM-DD'),
      date_departure:  moment(this.reservation_form.value.date_departure.endDate).format('YYYY-MM-DD'),
      room_id : roomIdForApi,
      property_id :localStorage.getItem("current_property"),
      edit:false
    });



    this.api.post('api/housekeeping/get_room_no_by_room_id',{data},true ).subscribe((res:any)=>{
      this.room_number=res.body.data;
    }, err => this.error_handling.handle_error(err.status))
    this.onsubmit_reservation(index)
  }

  reservation(){
    this.api.post('api/booking/store', {property_id:localStorage.getItem("current_property")},true).subscribe((res:any) => {
      console.log(res.body.data)
      this.roomtypes = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }
  addNewGuest(control,data,index){
    if(data == null){
      control.push(
        this.fb.group({
          guest_name:  [null, Validators.required],
          room_id: [null, Validators.required],
          room_no_id: null,
          guest_id: '',
          package_id: null,
          season_id: this.current_season_id,
          promotion_id: '',
          no_of_adults: [null, Validators.required],
          no_of_childs: [null, Validators.required],
          no_of_infants: [null, Validators.required],
          breakfast:[null, Validators.required],
          check_in:null,
          check_out:null,
          prices:null,
          status:'Pending',
          pricesjson:null
        })
      );
    }else{
      let pId;
      if(data.package_id == 0){
        pId=null
      }else{
        pId = data.package_id
      }
      control.push(
        this.fb.group({
          guest_name:  [data.guest_name, Validators.required],
          room_id: [data.room_id, Validators.required],
          room_no_id: data.room_no_id,
          guest_id: data.guest_id,
          package_id: pId,
          season_id: data.season_id,
          promotion_id: data.promotion_id,
          no_of_adults: [data.no_of_adults, Validators.required],
          no_of_childs: [data.no_of_childs, Validators.required],
          no_of_infants: [data.no_of_infants, Validators.required],
          breakfast:[data.breakfast, Validators.required],
          check_in:[
            {startDate: moment(data.check_in), endDate: moment(data.check_in)},
            Validators.required],
          check_out:[
            {startDate: moment(data.check_out), endDate: moment(data.check_out)},
            Validators.required],
          prices:data.prices
        })
      );

    }
  }
  createRservationForm(){
    this.reservation_form = this.fb.group({
      property_id: localStorage.getItem("current_property"),
      channel_id: [null, Validators.required],
      room_id: "",
      package_id: "",
      season_id: this.current_season_id,
      reservation_id: "",
      date_arrival: [null, Validators.required],
      date_departure: [null, Validators.required],
      count_adult: "",
      count_child: "",
      total: [null, Validators.required],
      prices: [null, Validators.required],
      guest_firstname: [null, Validators.required],
      guest_lastname: "",
      payment_status: [null, Validators.required],
      status: [null, Validators.required],
      currency: null,
      child_ages: "",
      guest_phone: [null, Validators.required],
      guest_address: [null, Validators.required],
      guest_email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      comments: "clean",
      room_number: "",
      guest_city: [null, Validators.required],
      booking_items: this.fb.array([]),
      guest_card: [
        null,
        [
          Validators.pattern(
            "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
          ),
        ],
      ],
      guest_card_type: null, //[null, Validators.required],

      payee_name: null, //[null, Validators.required],
      guest_card_expiry: null, //[null, Validators.required],
      guest_card_series_code: null, //[null, Validators.required],
      privilege: [null, Validators.required],
      marketing_email: "",
      eta: [localStorage.getItem("eta"), Validators.required],
      etd: [localStorage.getItem("etd"), Validators.required],
      personal_id_type: [null, Validators.required],
      personal_id_value: [null, Validators.required],
      discount: this.promoDiscount,
      discount_package: "",
      company_name: "",
      promo: null,
      same_date: null,
      SpecialRequests: null,
      TaxInclusive: null,
      extraPerson: null,
      cashDeposit: null,
      Tax: null,
      subtotal: null,
    })
  }


  //Reservarion Popup Work END
  chkRadio(id1,id2,id3,id4){
    let a = (<HTMLInputElement>document.getElementById(id1));
    let b = (<HTMLInputElement>document.getElementById(id2));
    let c = (<HTMLInputElement>document.getElementById(id3));
    let d = (<HTMLInputElement>document.getElementById(id4));
    // b.style.color = '#666';
    // d.style.color = '#666';
    // a.checked == true ? b.style.color = 'orange' : b.style.color = '#666'
    // c.checked == true ? d.style.color = 'orange' : d.style.color = '#666'
  }

  getnigths(st, en) {

    let current_date = moment().subtract(0, "days");
    let end_date: any = moment(st.endDate);
    let difff = end_date.diff(current_date, "days");
    let difrence = ++difff;
    this.minDate_dep = moment().subtract(-difrence, "days");
    if (en == undefined || st == undefined) {
      return;
    }
    if (st == undefined) {
      return;
    } else if (en == undefined) {
      return;
    } else {
      var start = moment(st.endDate);
      var end = moment(en.endDate);
      this.start = moment(st.endDate);
      this.end = moment(en.endDate);
      let dif = end.diff(start, "days");
      this.totalnights = dif;

      this.getroomtype(
        this.showsmoke,
        this.showwheel,
        this.showpets,
        true,
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      this.getPackageByDate(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
    }
    let b = this.reservation_form.value.booking_items;
    if (start != undefined && end != undefined && b[0].room_id != null) {
      this.perDayData = [];
      // b.forEach(function(a,b){
      // this.onsubmit_reservation(b);
      // });
      for(let i = 0 ;  i < b.length ; i ++){
        this.onsubmit_reservation(i);
      }


    }
  }


  calculateprice() {
    if (this.reservation_form.value.prices != null) {
      let sum = 0;
      let comaArrayRate = [];
      let g = this.reservation_form.value.prices.split(",");
      let z = g.map((elem) => parseInt(elem, 10));
      for (let i = 0; i < z.length; i++) {
        sum += z[i];
        comaArrayRate.push(z[i]);
      }
      let r = sum / 100;
      let discountedTotal = sum - r * this.reservation_form.value.discount;
      var b = JSON.parse(localStorage.getItem("property"));
      let formula: any;
      this.TaxInclusive == "false"
        ? (formula = "0." + b.taxper)
        : (formula = 0);

      this.TaxInclusive = b.taxInclusive;

      this.TaxInclusive == "false" ? (this.subtotal = discountedTotal) : "";

      let percantage = discountedTotal * formula;
      this.TaxInclusive == "false" ? (b.taxper = formula) : "";

      this.TaxInclusive == "false" ? (this.Tax = Math.round(percantage)) : "";

      this.reservation_form.patchValue({
        total: Math.round(discountedTotal + percantage),
        season_id: this.current_season_id,
      });
    } else {
      return;
    }
  }

  get f(){ return this.reservation_form.controls }
  submitReserv(){
    this.reservation_form.value.status == 'hold' ? this.bookingStatus = false : this.bookingStatus = true
    this.submitted=true;
    if(this.applyChkOutDate){
      for(let i=0;i< this.reservation_form.value.booking_items.length;i++){
        this.reservation_form.value.booking_items[i].check_in = this.reservation_form.value.date_arrival
        this.reservation_form.value.booking_items[i].check_out = this.reservation_form.value.date_departure
      }
      this.pricesArray = [];
      this.comaArrayRate = [];
    }else{
      for(let i=0;i< this.reservation_form.value.booking_items.length;i++){
        if(this.reservation_form.value.booking_items[i].check_in.endDate){
          this.reservation_form.value.booking_items[i].check_in =  moment(this.reservation_form.value.booking_items[i].check_in.endDate).format('YYYY-MM-DD')
        }if(this.reservation_form.value.booking_items[i].check_out.endDate){
          this.reservation_form.value.booking_items[i].check_out =  moment(this.reservation_form.value.booking_items[i].check_out.endDate).format('YYYY-MM-DD')
        }
      }
    }
    if(this.reservation_form.value.date_arrival.endDate){
      this.reservation_form.patchValue({
        date_arrival:moment(this.reservation_form.value.date_arrival.endDate._d).format('YYYY-MM-DD')
      })
    }
    if(this.reservation_form.value.date_departure.endDate){
      this.reservation_form.patchValue({
        date_departure:moment(this.reservation_form.value.date_departure.endDate._d).format('YYYY-MM-DD')
      })
    }
    this.reservation_form.patchValue({
      same_date:this.applyChkOutDate
    })
    if(this.segmonLeft){
      this.reservation_form.patchValue({TaxInclusive:'true'})
    }else if(!this.segmonLeft){
      this.reservation_form.patchValue({TaxInclusive:'false'})
    }
    if(this.reservation_form.value.payment_status == 'unpaid'){
      this.reservation_form.patchValue({payment_status:'paynow'})
    }
    if (this.reservation_form.invalid) {
      console.log( this.reservation_form)
      return this.helper.alert_error('Please fill all required fields')
    }

    console.log(this.reservation_form.value.booking_items);
    console.log(this.reservation_form.value.booking_items.length);


    this.api.post('api/booking/store_bulk', this.reservation_form.value,true).subscribe((res:any) => {
      console.log(res)
      this.resetForm(this.reservation_form)
      $('#bulk_update').modal('hide');
      this.get_inventory()
      this.helper.alert_success('Reservation made successfully.')
    }, err => this.error_handling.handle_error(err.status));
  }

  resetForm(form){
    form.reset();
    form.controls.booking_items.controls.splice(1,1000)
    this.totalnights = null;
    this.submitted=false;
    this.reservation_form.patchValue({
      discount:this.promoDiscount,
      season_id: this.current_season_id,
      property_id: localStorage.getItem("current_property"),
      currency:'$',
      comments:'clean'
    })
    this.perDayData = []
    let a = (<HTMLInputElement>document.getElementById('sideBarDetail'))
    a.style.right = '0%'
  }
  getChannels(){
    this.api.post('api/channels/index?per_page=50', {property_id:localStorage.getItem("current_property")},true).subscribe((res:any) => {
      console.log(res.body.data)
      this.allChannels = []
      res.body.data.map((val)=>{
        if(val.type == "Internal" || val.type == "Custom" && val.connected == true){
          this.allChannels.push(val)
        }
      })
    }, err => this.error_handling.handle_error(err.status));
  }
  getChannelId(id){
    this.allPromo = [];
    let a = {
      channel_id:id.id,
      property_id:localStorage.getItem("current_property")
    }
    this.api.post('api/package/promo_search_by_channel',a,true).subscribe((res:any)=>{
      this.allPromo = res.body.data
    })
  }
  getPromo(data){
    let a = (<HTMLInputElement>document.getElementById('discountPromo'));
    let c = (<HTMLInputElement>document.getElementById('disClick'));
    if(data != undefined){
      a.readOnly = true
      c.classList.add('uncluck');
      this.reservation_form.patchValue({
          discount:data.promo.discount
        })
        this.promoDiscount = data.promo.discount
    }else{
      a.readOnly = false;
      c.classList.remove('uncluck');
      this.reservation_form.patchValue({
        discount:null
      })
    }
     this.calculateprice()
  }
  chkPackage(data){
    if(data == undefined){
      return
    }else{
      let b = [];
      if(this.allPackages == undefined){return}
      else{
        this.allPackages.map((val,index)=>{
          if(data.room_id == val.room_id){
            b.push(val)
          }
        })
      }
      this.allPackages = b
    }

  }
  getWeather(){
    let cities = ["new york", "toronto", "london","cape town"];
    let user = JSON.parse(localStorage.getItem('user'));

    cities.forEach(city => {
      let req = 'https://api.openweathermap.org/data/2.5/weather?q='+ city +'&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1';
      let forcast = 'https://api.openweathermap.org/data/2.5/forecast/daily?q='+ city +'&cnt=7&lang=en&units=metric&APPID=bfab95ebe3bbb8966c54139aefd539b4';
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split('.')[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString('en-us', { weekday: 'short' }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: []
        }

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach(element => {
            var day = new Date(element.dt * 1000).toLocaleTimeString('en-us', { weekday: 'short' });
            var icon = element.weather[0].icon.split('.')[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({day, icon, min_temp, max_temp});
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });
  }
  fliBox(){
    $(function(){
      setTimeout(()=>{
        $('#flip').flipbox({
          vertical: true,
          autoplay: true,
          autoplayReverse: false,
          autoplayWaitDuration: 5000,
          autoplayPauseOnHover: true
        });
        setTimeout(()=>{
          $('#flip2').flipbox({
            vertical: true,
            autoplay: true,
            autoplayReverse: false,
            autoplayWaitDuration: 5000,
            autoplayPauseOnHover: true
          });
        },400)
      },100);
      $("[data-toggle='tooltip']").tooltip();
    })
    setTimeout(()=>{
      let a = (<HTMLInputElement>document.getElementById('checkedForSep'))
      if(!a.checked){
        a.click()
      }
    },300)
    this.reservation_form.patchValue({
      eta:localStorage.getItem('eta'),
      etd:localStorage.getItem('etd')
    })
  }
  getValA(data){

    this.applyChkOutDate = data.target.checked;

    data.target.checked ? this.toolChkData = 'Uncheck the checkbox to apply seperate dates on each room.' : this.toolChkData = 'Check the checkbox to apply same dates on all rooms.'
    let a = <FormArray>this.reservation_form.controls["booking_items"];

    if(!this.applyChkOutDate){
  
      for(let i=0;i< this.reservation_form.value.booking_items.length;i++){
        a.controls[i].patchValue({
          check_in:{startDate: moment(new Date()), endDate: moment(new Date())},
          check_out:{startDate: moment(new Date()), endDate: moment(new Date())},

        })
      }
      this.pricesArray = [];
      this.comaArrayRate = [];
      this.perDayData = [];
    }
  }

  onsubmit_reservation(index){
    if(this.reservation_form.value.date_arrival.endDate != undefined && this.reservation_form.value.date_departure.endDate != undefined || this.reservation_form.value.date_arrival.endDate != null && this.reservation_form.value.date_departure.endDate != null ){
      this.reservation_form.patchValue({
        date_arrival: moment(this.reservation_form.value.date_arrival.endDate._d).format('YYYY-MM-DD'),
        date_departure: moment(this.reservation_form.value.date_departure.endDate._d).format('YYYY-MM-DD'),
      });
    }
    let langArr = <FormArray>this.reservation_form.controls["booking_items"];
    let a = {
      property_id: localStorage.getItem("current_property"),
      user_id: JSON.parse(localStorage.getItem('user')).id,
      start_date: this.reservation_form.value.date_arrival,
      end_date: this.reservation_form.value.date_departure,
    };
    if(a.start_date.endDate === null){
      return this.helper.alert_error('Please Select Check-in Date!');
    }
    if(a.end_date.endDate === null){
      return this.helper.alert_error('Please Select Check-out Date!');
    }
        if(!this.applyChkOutDate){
          if(this.reservation_form.value.booking_items[index].check_in.endDate != undefined){
            langArr.controls[index].patchValue({
              check_in:moment(this.reservation_form.value.booking_items[index].check_in.endDate._d).format('YYYY-MM-DD'),
              check_out:moment(this.reservation_form.value.booking_items[index].check_out.endDate._d).format('YYYY-MM-DD')
            });
          }
        }
        let b = this.reservation_form.value;
        if(b.date_arrival.endDate === null ){
          return this.helper.alert_error('Check-in date required!');
        }
        else if(b.date_departure.endDate === null){
          return this.helper.alert_error('Check-out Date required!');
        }
        let pId;
        if(b.booking_items[index].package_id === null ){
          pId = 0
        }else{
          pId = b.booking_items[index].package_id
        }

        let xom = {
          start_date:b.date_arrival,
          end_date:b.date_departure,
          property_id:localStorage.getItem("current_property"),
          room_id:b.booking_items[index].room_id,
          package_id:pId,
        }
        let xomio = {
          start_date:b.booking_items[index].check_in,
          end_date:b.booking_items[index].check_out,
          property_id:localStorage.getItem("current_property"),
          room_id:b.booking_items[index].room_id,
          package_id:pId,
        }

        this.api.post('api/booking/get_rates_by_checkin_checkout',this.applyChkOutDate ? xom : xomio,true).subscribe((res:any)=>{
          var ar = "";
          ar=""==res.body.data[0].display_name||null==res.body.data[0].display_name?res.body.data[0].name:res.body.data[0].display_name;

          this.perDayData.unshift({
            roomName: ar,
            roomIndex: index,
            rates: res.body.data,
            package_name: res.body.data[0].package_name || "",
          })

          console.log(this.perDayData)
          this.perDayData = Array.from(new Set(this.perDayData.map(a => a.roomIndex)))
          .map(roomIndex => {
            return this.perDayData.find(a => a.roomIndex === roomIndex)
          })
          langArr.controls[index].patchValue({
            pricesjson:JSON.stringify(this.perDayData[0])
          })
          this.ref.detectChanges();
      if(this.applyChkOutDate == true){
        if(b.date_arrival.endDate === null ){
          return this.helper.alert_error('Check-in date required!');
        }
        else if(b.date_departure.endDate === null){
          return this.helper.alert_error('Check-out Date required!');
        }
        else{
            this.pricesArray = [];
            this.perDayData.map((val)=>{
              val.rates.map((val2)=>{
                this.pricesArray.push({roomRate:val2.price});
              })
            })
            }
          let pro = []
          this.pricesArray.map((pr)=>{
            pro.push(pr.roomRate.toString())
           })
            this.reservation_form.patchValue({
              prices:pro.toString()
            })
           this.calculateprice()
      }
      else{
        let aNo = index+1
        if(this.reservation_form.value.booking_items[index].check_in === null || this.reservation_form.value.booking_items[index].check_in.endDate === null ){
          return this.helper.alert_error('Please Select Room'+' '+aNo+' '+' Check-in Date!');
        }
        else if(this.reservation_form.value.booking_items[index].check_out === null || this.reservation_form.value.booking_items[index].check_out.endDate === null){
          return this.helper.alert_error('Please Select Room'+' '+aNo+' '+' Check-in Date!');
        }
        else{
          let a = true
          let langArr = <FormArray>this.reservation_form.controls["booking_items"];
        this.comaArrayRate = [];
        let b = this.reservation_form.value
        this.perDayData.map((val)=>{
          if(val.roomIndex == index){
            val.rates.map((val2)=>{
              this.comaArrayRate.push(val2.price)
            })
          }
        })
          langArr.controls[index].patchValue({
            prices:this.comaArrayRate.toString()
          });
          this.pricesArray=[];
          for(let i = 0; i < this.reservation_form.value.booking_items.length; i++ ){
            this.pricesArray.push(this.reservation_form.value.booking_items[i].prices)
          }
          this.reservation_form.patchValue({
            prices:this.pricesArray.toString()
          })
        }
      }
      this.calculateprice()
    },err => this.error_handling.handle_error(err.status, err.message))

  }

  mapData_boxes(stdate,endate){
    let a = {
      property_id: localStorage.getItem("current_property"),
      user_id: JSON.parse(localStorage.getItem('user')).id,
      start_date: stdate,
      end_date: endate,
    };

    this.api.post('api/inventory/calendar', a, true).subscribe((res: any) => {
      this.rateData = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message))
  }

  recievedData(){
    if(history.state[0]){
      if(history.state[0].guest_firstname){
        setTimeout(()=>{
          let a = document.getElementById('fliboxw')
          a.click();
          this.reservation_form.patchValue({
            guest_firstname:history.state[0].guest_firstname,
            guest_phone:history.state[0].guest_phone,
            guest_email:history.state[0].guest_email,
            guest_address:history.state[0].guest_address,
            guest_city:history.state[0].guest_city,
            personal_id_type:history.state[0].personal_id_type,
            personal_id_value:history.state[0].personal_id_value,
            privilege:history.state[0].privilege
          })
          this.returnee = 'Returnee'
        },1500)
      }
    }
    else{
      this.returnee = 'New Guest'
    }
  }

  sideMenu(id1){
    let a = (<HTMLInputElement>document.getElementById(id1))
    a.style.right == '0px' ? a.style.right = '-244px' : a.style.right = '0px'
  }

  getPackageByDate(strD,EndD){
    let a  = {
      property_id:localStorage.getItem('current_property'),
      date_to:strD,
      date_from:EndD,
    }
    this.api.post('api/package/get_package_by_date',a,true).subscribe((res:any)=>{
      this.allPackages = res.body.data
      this.allPackages2 = res.body.data
    },err => this.error_handling.handle_error(err.status, err.message))
  }

  cnvertJsonName(data){
    let a = JSON.parse(data.pricesjson)
    return a
  }

  getRandomColor(){
    for(let i=0; i <=50 ;i++){
     this.randomColors.push(`#${Math.floor(Math.random()*16777215).toString(16)}`)
    }
   let elements
   elements = document.getElementsByClassName('dynamoColor');
   setTimeout(()=>{
    for (var x = 0; x < 100; x++) {
      elements[x].style.borderColor=this.randomColors[x];
    }
   },1000)
  }

  getBookingDetails(data){
    this.api.get('api/booking/show/'+data.booking_id,true).subscribe((res:any)=>{
      this.onlyShowBooking = res.body.data
      this.ref.detectChanges();
    })
  }

  chckChckbox(id,con){
		let a = (<HTMLInputElement>document.getElementById('test1'+id))
		let b = (<HTMLInputElement>document.getElementById('test2'+id))
		if(con == 'Paid'){
			a.checked = true
			b.checked = false
		}
		else if(con == 'Complimentry'){
			b.checked = true
			a.checked = false
		}
		else{
			a.checked = false
			b.checked = false
		}
  }

  changeSegment(con,id){
    let segment = (<HTMLInputElement>document.getElementById(id))
    con == 'true' ?
    (segment.style.left = '2%',this.segmonLeft = true) :
    con == 'false' ?
    (segment.style.left = '52%', this.segmonLeft = false) :
    segment.style.display = 'none'


  }

  removeRoom(index){
    let langArr = <FormArray>this.reservation_form.controls["booking_items"];
    if(this.reservation_form.value.date_arrival.endDate === null || this.reservation_form.value.date_departure.endDate === null){
      langArr.removeAt(index)
      return
    }
    this.perDayData.map((valk,i)=>{
      if(index == valk.roomIndex){
        this.perDayData.splice(i,1)
      }
    })
    langArr.removeAt(index);
    if(this.applyChkOutDate == true){
          this.pricesArray = [];
          this.perDayData.map((val)=>{
            val.rates.map((val2)=>{
              this.pricesArray.push({roomRate:val2.price});
            })
          })

        let pro = []
        this.pricesArray.map((pr)=>{
          pro.push(pr.roomRate.toString())
         })
          this.reservation_form.patchValue({
            prices:pro.toString()
          })
         this.calculateprice()
    }
    else{
      let aNo = index+1
        let a = true
        let langArr = <FormArray>this.reservation_form.controls["booking_items"];
      this.comaArrayRate = [];
      let b = this.reservation_form.value
      this.perDayData.map((val)=>{
        if(val.roomIndex == index){
          val.rates.map((val2)=>{
            this.comaArrayRate.push(val2.price)
          })
        }
      })
        langArr.controls[index].patchValue({
          prices:this.comaArrayRate.toString()
        });
        this.pricesArray=[];
        for(let i = 0; i < this.reservation_form.value.booking_items.length; i++ ){
          this.pricesArray.push(this.reservation_form.value.booking_items[i].prices)
        }
        this.reservation_form.patchValue({
          prices:this.pricesArray.toString()
        })
        this.calculateprice()
    }
  }
  permissions() {

    var data = this.user;
    console.log(data.role);
    for (let i = 0; i < data.length; i++) {
      if (this.user.permissions[i]["app_permission"]["name"] == "Discounts") {
        this.Discount = "true";
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  darkMode:boolean = false;
  base_url2;
  user;
  constructor(public events: EventsService,private api:ApiService) {
    this.base_url2 = this.api.base_url2
   }

  ngOnInit() {
      this.user = JSON.parse(localStorage.getItem('user')).profile_picture;
      this.events.recieveUserImg().subscribe((res:any)=>{
        this.user = res
      })
      this.checkDarkMode();

      this.events.receiveDarkMode().subscribe((res:any)=>{
        this.darkMode = res.darkMode;
      })
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }
}

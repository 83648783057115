import { Component, OnInit, HostBinding } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
declare var $: any;
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators,ValidationErrors  } from "@angular/forms";
import { Router } from '@angular/router';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css'],
})
export class CommunicationComponent implements OnInit {
  @HostBinding('class') colorClass = `${JSON.parse(localStorage.getItem('user')).mode}Mode`;
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private fb: FormBuilder,
    private error_handling: ErrorHandlingService,
    private router: Router,
    ) { }

  colors = [];
  checkedNames: Array<string> = [];
  guest_directory;
  modal_data;
  my_object;
  showhide;
  my_object2;
  darkMode:boolean = false;

  selectedUser = [];

  months = [
    {name: 'All'},
    {name: 'January'},
    {name: 'February'},
    {name: 'March'},
    {name: 'April'},
    {name: 'May'},
    {name: 'June'},
    {name: 'July'},
    {name: 'August'},
    {name: 'September'},
    {name: 'October'},
    {name: 'November'},
    {name: 'December'}
  ];

  numBer = [
    {no:1},
    {no:2}
  ]
  weather_update = [];
  filters = [
    {
      name:'All Guest', val: 'all',
    },
    {
      name:'In House Guest', val: 'in_house_guest',
    },
    {
      name:'Arriving Guest', val: 'loyalty_card_holder'
    },
    {
      name:'Loyalty Card Holder', val: 'arriving_guest'
    },
  ];
  g_directory = [];
  e_directory = []
  WHcolors = ['#ffc107','#ec1361','#09fb3d','#ff51f3','#48fff3','#9a00ff','#ff6f00'];
  directoryBorder = ['#780ca0','#ffa902','#1fde39','#001dfb','#eb155f','#9a9cff','#46fff5','#920806']
  directoryPopupBorder = ['#001dfb','#46fff5','#eb155f','#780ca0','#ffa902','#9a9cff','#920806','#1fde39']
  sConfig = {
    placeholder: '',
    tabsize: 2,
    height: 380,
     codemirror: {
      theme: 'cosmo'
    },
    theme: 'cosmo',
    //uploadImagePath: '/api/upload',
    toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['fontsize', ['fontname', 'fontsize']],
        ['para', ['paragraph']],
        ['insert', ['picture']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };
  e_templates = [];
  email:any;
  email_form : FormGroup;
  b_detail:any;
  show_add:boolean = false;
  month:any = null;
  filter:any = null;
  totalNights;
  pagi = []
  lastpage;
  selectedIndex;
  paginationData;
  roomName;
  delay:boolean=false
  chk_preview(y_id){

    // var my_id = (<HTMLInputElement>document.getElementById("{{ g_directory.id }}")).value;
    // this.my_object = this.g_directory.find(x => x.id === m_id );

  }

  ngOnInit() {
    this.checkDarkMode();
    setTimeout(()=>{
      this.getDirectory(1);
    },500)
    this.createEmailForm();
    this.getWeather()

    $(document).ready(function(){
      $('#plus-search').css("visibility", "hidden");
      $('.plus-img').click(function(){
        $('#plus-search').css({"visibility":"visible"});
      });
      $('#p-1').click(function(){
        $('#plus-search').css("visibility", "hidden");
      });
    })

    $(function () {
      $("#drag-list").dragsort({
        placeHolderTemplate: `
          <div class='placeHolder' style=''>
            <div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 70px;margin-bottom:5px;'></div>
          </div>`,
        cursor: "move"
      });
    });

    $( "#myModal").draggable();
    $( "#myModal").draggable('disable');
    $( "#myModal-2" ).draggable();
    $( "#myModal-2" ).draggable('disable');
    $( "#myModal-3" ).draggable();
    $( "#myModal-3" ).draggable('disable');

    $(function() {
      $(".modal").mouseup(function(){
        $(".dargmd").css({"cursor":"grab"});
      });
      $(".modal").mousedown(function(){
        $(".dargmd").css({"cursor":"grabbing"});
      });
    });
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  onChange(name: string , checked: boolean){
    if(checked){
      this.checkedNames.push(name)
    } else {
      let value1 = this.checkedNames.indexOf(name)
      this.checkedNames.splice(value1 , 1)
    }
  }

  makePdf() {
    const options = {
      background: '#f1f1f1',
      scale:1
    };
    html2canvas(document.querySelector(".capture"),options).then((canvas) => {
      var img = canvas.toDataURL('image/png');
      var doc = new jsPDF('p', 'mm', 'a4');
      const bufferX = 3;
      const bufferY = 3;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      doc.save('Communication.pdf');
    });
  }

  dodrag(id){
    $( "#"+id ).draggable('enable');
  }

  dontdrag(id){
    $("#"+id).draggable('disable');
  }

  rotateDrop(id){
		let a = document.getElementById(id);
		a.classList.contains('rotate') ? a.classList.remove('rotate') : a.classList.add('rotate')
  }

  getDirectory(pn){
    let i;
    let a = {
      property_id: localStorage.getItem('current_property'),
      month: '',
      filter: ''
    }
    this.api.post('api/booking/guest_directory?page='+pn, a,true).subscribe((res:any)=>{
      this.g_directory = res.body.data.data;
      this.pagi = [];
      this.selectedIndex = res.body.data.current_page
      this.lastpage = res.body.data.last_page;
      this.paginationData = res.body.data;
      this.delay = true
      for(i=1;i<=res.body.data.last_page;i++){
        this.pagi.push({page_no:i})
      }
      setTimeout(()=>{
        var elmnt = document.getElementById("cuspagidiv");
      pn == 1 ? elmnt.scrollLeft -= 100000 :
      pn == this.lastpage ? elmnt.scrollLeft += 10000000 :
      null
      },100)
     })
  }

  openDetails(email){
    this.api.post('api/booking/guest_directory_details', {property_id: localStorage.getItem('current_property'), email: email}, true).subscribe((res:any)=>{
      this.my_object = res.body.data;
      setTimeout(()=>{
        $('#myModal').modal('show');
        $("#myModal").draggable();
      }, 250);
    })
  }

  getNights(check_in, check_out){
    return moment(check_out).diff(moment(check_in), "days");
  }

  getEmail(guest){
    this.getTemplate();
    this.selectedUser.push(guest[0]);

    $('#myModal-2').modal({backdrop: 'static', keyboard: false })
  }

  getEmails(g){
    this.getTemplate();
    this.selectedUser.push(g)
  }

  removeUser(i){
    this.selectedUser.splice(i, 1);
  }

  getTemplate(){
    this.api.post('api/email_template/index', {property_id: localStorage.getItem('current_property')}, true).subscribe((res:any)=>{
      this.e_templates = res.body.data;
    })
  }

  setEmail(e){
    this.email = e.html;
    this.email_form.patchValue({
      html: this.email
    })
  }

  closeEmailModal(){
    this.selectedUser = [];
    this.email = '';
  }

  createEmailForm(){
    this.email_form = this.fb.group({
      property_id: localStorage.getItem('current_property'),
      sender_user_id: JSON.parse(localStorage.getItem('user')).id,
      emails: ['', Validators.required],
      html: ['', Validators.required],
      email_template_id: null,
      send_date: '',
      send_time: ''
    })
  }

  save(){
    let e = [];
    this.selectedUser.map((val)=>{
      e.push(val.guest_email)
    });
    this.email_form.patchValue({
      emails: e,
      send_date: this.email_form.value.email_template_id != null ? moment(this.email_form.value.send_date.startDate._d).format('YYYY-MM-DD') : ''
    })
    this.api.post('api/email_template/send_emails', this.email_form.value, true).subscribe((res:any)=>{
      if(res.status == 200){
        $('#myModal-2').modal('hide');
        this.helper.alert_success(res.body.message);
        this.email_form.reset();
      }
    })
  }

  nights(check_in, check_out){
    return moment(check_out).diff(moment(check_in), 'days');
  }

  filterNow(month, filter){
    let a = {
      property_id: localStorage.getItem('current_property'),
      month: month,
      filter: filter
    }
    this.api.post('api/booking/guest_directory?page=1', a, true).subscribe((res:any)=>{
      this.g_directory = res.body.data.data;
    })
  }
  getWeather(){
    let cities = ["karachi", "new york", "toronto", "london","cape town"];
    let user = JSON.parse(localStorage.getItem('user'));

    cities.forEach(city => {
      let req = 'https://api.openweathermap.org/data/2.5/weather?q='+ city +'&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1';
      let forcast = 'https://api.openweathermap.org/data/2.5/forecast/daily?q='+ city +'&cnt=7&lang=en&units=metric&APPID=bfab95ebe3bbb8966c54139aefd539b4';
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split('.')[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString('en-us', { weekday: 'short' }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: []
        }

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach(element => {
            var day = new Date(element.dt * 1000).toLocaleTimeString('en-us', { weekday: 'short' });
            var icon = element.weather[0].icon.split('.')[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({day, icon, min_temp, max_temp});
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });
  }
  fliBox(){
    $(function(){
      setTimeout(()=>{
        $('#flip').flipbox({
          vertical: true,
          autoplay: true,
          autoplayReverse: false,
          autoplayWaitDuration: 5000,
          autoplayPauseOnHover: true
      });
      },100)
    })
  }
  consoleG(m_id){
    m_id.bookingitems.map((val,i)=>{
			setTimeout(()=>{
				this.chckChckbox(i,val.breakfast)
			},500)
    })
    console.log(m_id)
  }
  nightsTotal(en,st){
    this.totalNights = moment(en).diff(moment(st),"days")
    this.fliBox()
  }
  chckChckbox(id,con){
		let a = (<HTMLInputElement>document.getElementById('test'+id))
		let b = (<HTMLInputElement>document.getElementById('test2'+id))
		if(con == 'Paid'){
			a.checked = true
			b.checked = false
		}
		else if(con == 'Complimentry'){
			b.checked = true
			a.checked = false
		}
		else{
			a.checked = false
			b.checked = false
		}
  }

  getDataInventory(data){
    this.router.navigate(['/app/inventory'],  { state: data })
  }

  grab(id,con){
    let ide = (<HTMLInputElement>document.getElementById(id))
    con == 'dont' ? ide.style.cursor = 'grab' : ide.style.cursor = 'grabbing'
  }

  sideTray(id,caret){
    let ide = (<HTMLInputElement>document.getElementById(id));
    let care = (<HTMLInputElement>document.getElementById(caret));
    ide.style.right == '0px' ? ide.style.right = '-245px' : ide.style.right = '0px'
    care.classList.contains('fa-caret-right') ? (care.classList.remove('fa-caret-right'), care.classList.add('fa-caret-left')) : (care.classList.add('fa-caret-right'), care.classList.remove('fa-caret-left'))
  }

  cnvertJsonName(data){
    let a = JSON.parse(data.pricesjson)
    return a
  }

  sub_total(a: any, b: any) {
    let c = b == null ? 0 : b;
    var strArr = a.split(",");
    var sum = strArr.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
    return parseInt(sum) + parseInt(c);
    //  return sum;
  }

  tax_per(a: any, b: any, tax: any) {
    let c = b == null ? 0 : b;
    var strArr = a.split(",");
    var sum = strArr.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
    var texes:any = `0.${tax}`;
    var sumes = parseInt(sum) + parseInt(c);
    return (sumes * texes );

    //  return sum;
  }
}

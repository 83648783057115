import { Component, OnInit } from '@angular/core';
declare var $: any;
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
import * as moment from 'moment';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {

  table_data = [];
  base_url;
  user = JSON.parse(localStorage.getItem('user'));
  users = [];
  page = 1;
  page_arr = [];
  darkMode: boolean = false;
  
  filter = {
    userId: null,
    table_name: null,
    _date: {startDate: null, endDate: null}
  }

  constructor(
    // private translate : TranslateService,
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService
  ) { }


  // categories = [
  //   {id: 1, name: 'Reservation'},
  //   {id: 1, name: 'Maintenance'},
  //   {id: 1, name: 'Housekeeping'},
  //   {id: 1, name: 'Hotel Setup'},
  //   {id: 1, name: 'Vendor & Suppliers'}
  // ];
  colors = ['#ff0000', '#c41eff', '#23bbff', '#58cf78', '#ffb061'];
  // constructor() { }

  ngOnInit() {
    this.checkDarkMode();

    // drag function code--------------------
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
    // drag function end-------------------
    this.get_table_data();
    this.get_users();
  }


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  get_table_data(obj = null) {
    this.api.post('api/helper/log?page='+this.page, obj, true).subscribe((res: any) => {
      res.body.data.data.forEach(row => {
        // row.before_change_json = JSON.stringify(JSON.parse(row.before_change), null, 4);
        // row.after_change_json = JSON.stringify(JSON.parse(row.after_change), null, 4);
        row.before_change_json = row.before_change
       row.after_change_json = row.after_change
        row.created_date = moment(row.created_at, 'YYYY-MM-DD HH:ii:ss').format('MMM DD YYYY')
        row.created_time = moment(row.created_at, 'YYYY-MM-DD HH:ii:ss').format('HH:mm:ss')
      });
      this.page_arr = Array.from(Array(Math.ceil(res.body.data.total/res.body.data.per_page)).keys());
      this.table_data = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  set_filter(){
    let data = {};

    if(this.filter.userId != null){
      data['user_id'] = this.filter.userId; 
    }

    if(this.filter.table_name != null){
      data['table_name'] = this.filter.table_name;
    }
    
    if(this.filter._date.startDate != null){
      data['_date'] = this.filter._date.startDate.format('YYYY-MM-DD');
    }

    console.log(data);
    this.get_table_data(data);
  }

  get_users(){
    this.api.post('api/user/index', {admin_id: this.user.id}, true).subscribe((res:any) => {
      this.users = res.body.data
    });
  }

  switch_page(page){
    if (page == 'back') {
      this.page > 1 ? this.page-=1 : this.page;
      document.getElementById("cuspagidiv1").scrollLeft -= 30;
    }

    if (page == 'forward') {
      this.page < this.page_arr.length ? this.page+=1 : this.page
      document.getElementById("cuspagidiv1").scrollLeft += 30;
    }

    if (typeof page == 'number') {
      this.scCustom(page);
      this.page = page
    }

    if(page == 'firstPage'){
      this.page = 1;
      document.getElementById("cuspagidiv1").scrollLeft = 0;
    }
    if(page == 'lastPage'){
      this.page = this.page_arr.length
      document.getElementById("cuspagidiv1").scrollLeft = 100000;
    }
    
    console.log(this.page);
    this.set_filter();
  }


  scCustom(no){
    var element = document.getElementById("cuspagidiv1");
 
    // this.allPcount > no ? element.scrollLeft += 30 : element.scrollLeft -= 30
    if(this.page > no){
      element.scrollLeft -= 30
 
    }else if(this.page < no){
      element.scrollLeft += 30
     
    }
  }


  tc(name){
    return name.replace(/_/g,' ')
  }

  reset(){
    this.get_table_data();
    this.filter = {
      userId: null,
      table_name: null,
      _date: {startDate: null, endDate: null}
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute, Router } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
// import {Component} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
declare var $: any;
export interface Room {
  name: string;
}
@Component({
  selector: 'app-create-rooms',
  templateUrl: './create-rooms.component.html',
  styleUrls: ['./create-rooms.component.css']
})


export class CreateRoomsComponent implements OnInit {
  open_custom = false;
  room;
  buildings0;
  amenities;
  roomtypes;
  bedtypes;
  photos;
  base_url;
  base_url2;
  photo_list = [];
  submitted=false;
  room_name_images=[];
  building_detail = [];
  Floor_no;
  Room_no;
  enable;
  values = '';
  public matForm ;
  floorId;
  catchVar:any;
  fDetails:boolean = false
  addRoomInp:boolean = false
  addRoomplus:boolean = true
  tr = [true]
  add_floor_card = [];
  oldBuilding = [];
  room_type;
  room_classs;
  r_add;
  arr_room_empty:false
  colors = ['#ff0000', '#c41eff', '#23bbff', '#58cf78', '#ffb061'];
  aaa = [];
  bedExtra;
  cribsNum:any;
  personNum:any;
  RollWayNum:any;
  plusHover:boolean = false

  roomclass;
  bedSize;
  roomView;

  addInclu;
  costCrib:any;
  costCrib2:any;
  costCrib3:any;
  extraBed:boolean = false;

  pType = 'Person';
  pBedType;
  pQuantity;
  pSurcharge_type;
  pAmount;
  pSurcharge_amount_type;
  cType = 'Crib';
  cQuantity;
  cSurcharge_type;
  cAmount;
  cSurcharge_amount_type;
  rType = 'Rollaway Bed';
  rBedType;
  rQuantity;
  rSurcharge_type;
  rAmount;
  rSurcharge_amount_type;
  Persons:boolean = true;
  Cribs:boolean = true;
  Rollaway:boolean = true;
  dayBed:boolean = false;
  dType;
  dBedType;
  dQuantity;
  amenCol = ['#7ae997','#de888d','#6c6ce7','#da8639','#f1c1f8','#d4575a','#e7d67c','#4cdbbf','#81a2ff'];

  petsA:boolean = false;
  wheelc:boolean = false;
  smoke:boolean = false

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  user_room: Room[] = [];
  @ViewChild('photo_input', {static: false}) phIn: any;

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private helper: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    private confirm:ConfirmationAlertService,
    private Aroute: ActivatedRoute,
    private http: HttpClient
  ) {
    //this.building_detail.push({name:'', floor:'', colorFloor: this.getRandomColor(), colorroof: this.getRandomColor(), colorbuilding: this.getRandomColor()})

   }

  adults;
  infants;
  children;
  create_room_form: FormGroup;
  prevBtn:boolean = true;
  darkMode:boolean = false;

  ngOnInit() {
    this.building_detail = [];
    this.catchVar = history.state;
    this.matForm =  document.getElementById("matForm")
    this.base_url=this.api.base_url;
    this.base_url2=this.api.base_url2;
    this.get_amenities();
    this.get_roomtypes();
    this.get_bedtypes();
    this. makeNum();
    this.checkDarkMode();
  //   if(this.route.snapshot.paramMap.get('id')){
  //     this.get_room(this.route.snapshot.paramMap.get('id'));
  //     this.prevBtn = false;
  // }
    // this.create_room_form = this.fb.group({
    //   bed_type_id: ['',Validators.required],
    //   no_of_beds: ['',Validators.required],
    //   room_count: ['',Validators.required],
    //   room_size: ['',Validators.required],
    //   adults: ['',Validators.required],
    //   children: ['',Validators.required],
    //   infants: ['',Validators.required],
    //   room_type_id: null,
    //   property_id:'',
    //   smoking:['',Validators.required],
    //   description:['',Validators.required],
    //   most_amenties:'',
    //   add_most_amenite:'',
    //   images:'',
    //   room_name_images:'',
    //   custom_room_type:null,
    //   Room_no:null,
    //   building_no:null,
    //   floor_no:null,
    //   initial:null,
    //   building_name:'',
    //   fn:'',
    //   floors_data:[],
    //   room_type_name:'',
    //   bed_type_name:'',
    //   amenities_title:[],
    //   options:'',
    //   r_add:'',
    //   adults_age: ['', Validators.required],
    //   children_age: ['', Validators.required],
    //   infants_age: ['', Validators.required],
    // });
    this.create_room_form = this.fb.group({
      bed_type_id: ['',Validators.required],
      no_of_beds: ['',Validators.required],
      room_count: ['',Validators.required],
      room_size: ['',Validators.required],
      room_type_id: ['',Validators.required],
      property_id:['',Validators.required],
      description:['',Validators.required],
      most_amenties:['',Validators.required],
      images:'',
      room_name_images:['', this.route.snapshot.paramMap.get('id') ? null : Validators.required],
      floors_data:[],
      room_type_name:'',
      display_name:'',
      bed_type_name:'',
      amenities_title:[],
      adults_age: ['',Validators.required],
      children_age:['',Validators.required],
      infants_age: ['',Validators.required],
      room_type_class:['',Validators.required],
      room_view_id:['',Validators.required],
      room_size_type:['',Validators.required],
      bedsize:['',Validators.required],
      value_add_inclusion:['',Validators.required],
      wheelchairaccessibility: this.wheelc,
      pets: this.petsA,
      smoking: this.smoke,
      adults:['',Validators.required],
      infants:['',Validators.required],
      children:['',Validators.required],
      extrabedding: '',
      extraBed:null,
    });

    this.f['room_type_id'].valueChanges.subscribe((res) => {
      this.create_room_form.value.room_type_id = res == '' ? '' : res;
      this.on_sec_change();
  });
this.getproperty()
  }

  test(option){
    setTimeout(function(){
      this.matForm.classList.remove('mat-focused' )}, 100);
  }
  get f() { return this.create_room_form.controls; }
  get_amenities(){
    this.api.post('api/amenitie/index', {order: 'other', type: 'Room','parent_id':'Null'}, true).subscribe((res:any) => {
      this.amenities = res.body.data;
      this.amenities.forEach((amenity) => {
        amenity.show_add = false;
      });
    }, err => this.error_handling.handle_error(err.status));
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;

    }
  }

  chandrop(id) {
    let a = document.getElementById(id)
    a.classList.contains('rotate') ?  a.classList.remove('rotate'): a.classList.add('rotate')

  }

  getproperty()
  {
    let cr = true;
   if (!this.Aroute.snapshot.queryParams['add_new']) {
       // let id = JSON.parse(localStorage.getItem('user')).id;
       let current_property = localStorage.getItem('current_property');
    if(this.route.snapshot.paramMap.get('id')){
      cr = false;
    }
    let user = JSON.parse(localStorage.getItem('user'));
    let obj = {
      id:user.id,
      email:user.email,
      arr_room_empty:false
    }
    let a

       this.api.post('api/property/index', obj,true).subscribe((res:any) => {
          res.body.data.map((val)=>{
            if(val.id == current_property){
              a = val
            }
          })

          if(this.route.snapshot.paramMap.get('id')){
            this.get_room(this.route.snapshot.paramMap.get('id'));
            this.prevBtn = false;
        }
          else{
            a.buildings.map((val)=>{

              this.building_detail.push({
              created_at: val.created_at,
              floors: val.floors,
              id: val.id,
              intial: val.initial,
              name: val.name,
              property_id: val.property_id,
              updated_at:val.updated_at,
              buildingcolor: this.getRandomColor(),
              buildingFloor:this.getRandomColor(),
              buildingroom:this.getRandomColor(),
            })
            // if(res.body.data[0].building_section == "Disabled"){
            //   this.enabled(false)
            // }
            // else{
            //   this.enabled(true)
            // }
            })
          }
          if(cr == true){
            this.building_detail.map((valB)=>{
              valB.floors.map((valF)=>{
                valF.roomnums = []
              })
            })
            this.chkLastBarColor(this.building_detail.length);
           }
       }, err => this.error_handling.handle_error(err.status));

   }



  }

  on_sec_change(){
    let que = this.create_room_form.value.room_type_id;
    if (que == '') {
        this.open_custom = false;
    }
  }

  add_custom_roomtype(){
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/roomtype/store', {property_id:property_id,name: this.create_room_form.value.custom_room_type, description:this.create_room_form.value.custom_room_type}, true).subscribe((res:any) => {
    this.helper.alert_success("Room Type Added Successfully...");
    this.open_custom=true;
    this.get_roomtypes();
    }, err => this.error_handling.handle_error(err.status));
  }

  get_roomtypes(){
    var property_id = localStorage.getItem("current_property");
    // this.api.post('api/roomtype/index?per_page=50', {property_id:property_id},true).subscribe((res:any) => {
    //    this.roomtypes = res.body.data;
    //    this.roomtypes.push({color_code: null,
    //     createdAt: "2019-12-10 10:27:47",
    //     description: "Enter your own custom room type",
    //     id: '',
    //     name: "Enter your own custom room type",
    //     status: "Enabled",
    //     updatedAt: "2019-12-10 10:27:47"})
    // }, err => this.error_handling.handle_error(err.status));
    this.api.post('api/roomtype/index?per_page=50', {property_id:property_id},true).subscribe((res:any) => {
      this.roomtypes = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  get_bedtypes(){
    this.api.get('api/helper/bed_types', true).subscribe((res:any) => {
       this.bedtypes = res.body.data.filter((bt)=> bt.type != "extra");
       this.bedExtra = res.body.data.filter((bt)=> bt.type == "extra");
    }, err => this.error_handling.handle_error(err.status));


    this.api.get('api/helper/room_class', true).subscribe((res:any) => {
      this.roomclass = res.body.data;
    }, err => this.error_handling.handle_error(err.status));


    this.api.get('api/helper/room_view', true).subscribe((res:any) => {
      this.roomView = res.body.data;
    }, err => this.error_handling.handle_error(err.status));

    this.api.post('api/helper/value_add_inclusion',{type: 'Standalone'}, true).subscribe((res:any) => {
      this.addInclu = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  async onFileChange(event){
      if(event.target.files && event.target.files[0]){
          for(var i=0; i < event.target.files.length; i++){
            var r = null;
            var f = event.target.files[i];
            var data = new FormData();
            data.append('image', event.target.files[i], event.target.files[i].name);
            await this.api.post2('api/helper/upload_image', data, false).subscribe((img_res:any) => {
              this.room_name_images.push({image:img_res.body.data.image});
            }, err => this.error_handling.handle_error(err.status));

            var reader = new FileReader();
            reader.onload = (eve:any) => {
                this.photo_list.push({url: eve.target.result, file: f});
            }
            reader.readAsDataURL(event.target.files[i]);
          }
      }
      this.phIn.nativeElement.value = '';
  }

  remove_photo(index){
    if(this.room) {
      try {
        this.api.get('api/room_gallery/destory/'+this.photo_list[index].id,true).subscribe((res:any) => {
        }, err => this.error_handling.handle_error(err.status));
      } catch (error) {
      }
    }
    this.photo_list.splice(index, 1);
  }

  uncheck(id){
        try {
          var del = (<HTMLInputElement>document.getElementById(id)).value;
          this.api.get('api/room_amenitie/destory/'+del+'/'+this.room.id,true).subscribe((res:any) => {
          }, err => this.error_handling.handle_error(err.status));
        } catch (error) {
        }
        return  (<HTMLInputElement>document.getElementById(id)).checked = false;
  }

  calculate(type, id) {
    var current = Number((<HTMLInputElement>document.getElementById(id)).value);
    if(type=='-'){
      if(current > 0){
        this[id]=current - 1;
        return current = current - 1;
      }
    }
    else if(type=='+'){
      if(current >= 0){
        this[id]=current + 1;
        return  current = current + 1;
      }
    }
  }

  changeCrib(){
    if(this.extraBed){
      this.extraBed = false
    }
     else{
      this.extraBed = true
     }
  }

  onSubmit() {
    this.submitted = true;
    var most_arr =[];
    // var most_arr_title =[];
    var property_id = localStorage.getItem("current_property");
    var most_amenties = document.querySelectorAll("input[name='most_amenties[]'][type=checkbox]:checked");
    // var amenities_t = document.querySelectorAll("input[name='most_amenties[]'][type=checkbox]:checked");
    Object.keys(most_amenties).forEach(key => {most_arr.push({amenitie_id:most_amenties[key].value});});
    // Object.keys(amenities_t).forEach(key => {most_arr_title.push({amenities_title:amenities_t[key].value});});
    this.create_room_form.patchValue({property_id:property_id,most_amenties: most_arr,
      room_name_images:this.room_name_images,
      pets: this.petsA,
      smoking: this.smoke,
      wheelchairaccessibility: this.wheelc,
    });
    if (this.create_room_form.invalid) {
      return;
    }
this.roomtypes.map((val)=>{
  if(val.id == this.create_room_form.value.room_type_id){
    //  this.create_room_form.value.room_type_name = val.name;
    this.create_room_form.patchValue({
      room_type_name:val.name
     })
  }
})
this.bedtypes.map((val)=>{
  if(val.id == this.create_room_form.value.bed_type_id){
    //  this.create_room_form.value.bed_type_name = val.name;
     this.create_room_form.patchValue({
      bed_type_name:val.name
     })
  }
})
this.amenities.map((val)=>{
  val.children.map((val2)=>{
    this.create_room_form.value.most_amenties.map((val3)=>{
      if(val2.id == val3.amenitie_id){
        //this.create_room_form.value.amenities_title.push({name:val2.title})
        this.aaa.push({
          title: val2.title,
          parentId: val.id,
          parentTitle:val.title
        })

      }
    })
  })
})
this.create_room_form.patchValue({extraBed:this.extraBed})
if(this.extraBed == true){
  this.create_room_form.patchValue({
    extrabedding:[
      {
        type: this.pType,
        quantity: this.pQuantity,
        size: 'Age',
        surcharge_type:this.pSurcharge_type,
        surcharge_amount_type: this.pSurcharge_amount_type,
        surcharge_amount: this.pAmount
      },
      {
        type: this.cType,
        quantity: this.cQuantity,
        size: 'Crib',
        surcharge_type:this.cSurcharge_type,
        surcharge_amount_type: this.cSurcharge_amount_type,
        surcharge_amount: this.cAmount
      },
      {
        type: this.rType,
        quantity: this.rQuantity,
        bed_type: this.rBedType,
        size:"Full",
        surcharge_type:this.rSurcharge_type,
        surcharge_amount_type: this.rSurcharge_amount_type,
        surcharge_amount: this.rAmount
      },
    ]
  })
}
    // this.create_room_form.value.amenities_title = this.aaa;
    // this.create_room_form.value.floors_data = this.building_detail;
    this.create_room_form.patchValue({
      amenities_title:this.aaa,
      floors_data:this.building_detail
     })
    if(this.room) {
      let f = [];
      this.create_room_form.value.floors_data.map((val)=>{
      val.floors.map((val2)=>{
        f.push(
          {
            building_id: val.id,
            floor_id: val2.id,
            roomnums: val2.roomnums
          })
      })
      var filtered = f.filter(function (el) {
        return el != null;
      });
    })
    // this.c.floors_data = f;
    // this.create_room_form.value.wheelchairaccessibility = this.wheelc.toString();
    // this.create_room_form.value.pets = this.petsA.toString();
    // this.create_room_form.value.smoking = this.smoke.toString();
    let a = {
      adults: this.create_room_form.value.adults,
      bed_type_id: this.create_room_form.value.bed_type_id,
      bed_type_name: this.create_room_form.value.bed_type_name,
      children: this.create_room_form.value.children,
      description: this.create_room_form.value.description,
      floors_data: f,
      infants: this.create_room_form.value.infants,
      most_amenties: this.create_room_form.value.most_amenties,
      no_of_beds: this.create_room_form.value.no_of_beds,
      property_id: this.create_room_form.value.property_id,
      room_count: this.create_room_form.value.room_count,
      room_name_images:this.create_room_form.value.room_name_images,
      room_size: this.create_room_form.value.room_size,
      room_type_id: this.create_room_form.value.room_type_id,
      room_type_name: this.create_room_form.value.room_type_name,
      smoking: this.smoke.toString(),
      room_type_class:this.create_room_form.value.room_type_class,
      room_size_type: this.create_room_form.value.room_size_type,
      bedsize:  this.create_room_form.value.bedsize,
      room_view_id: this.create_room_form.value.room_view_id,
      adults_age: this.create_room_form.value.adults_age,
      children_age: this.create_room_form.value.children_age,
      infants_age: this.create_room_form.value.infants_age,
      display_name:this.create_room_form.value.display_name,
      pets: this.petsA.toString(),
      wheelchairaccessibility: this.wheelc.toString(),
      value_add_inclusions: this.create_room_form.value.value_add_inclusion.join(),
      extrabedding:this.create_room_form.value.extrabedding,
    }

      this.api.post('api/room/update/'+this.room.id, a,true).subscribe((res:any) => {
        this.helper.alert_success("Room Updated Successfully...");
      }, err => this.error_handling.handle_error(err.status));
      return;
    }
    else{
      this.router.navigate(['/app/back_office/preview_rooms'],  { state: this.create_room_form.value })
    }
  }

  add_amenitie(order, parent_id)
  {
    var type='Room';
    var status = 'Enabled';
    var title ='';
    if(order=='other') {
      title=this.create_room_form.value.add_most_amenite;
    }

    var data = {title:title,order:order,status:status,type:type,parent_id:parent_id};
    this.api.post('api/amenitie/store',data,true).subscribe((res:any) => {
      // this.helper.alert_success("Changes has been saved !");
    setTimeout(() => {
      this.get_amenities();
    }, 2000);
    }, err => this.error_handling.handle_error(err.status));
  }

  get_room(id){
      this.api.get('api/room/show/'+id, true).subscribe((res:any) => {
          this.room = res.body.data.room;
          this.buildings0 = res.body.data.buildings;
          this.building_detail = res.body.data.buildings;
          this.fDetails = true
          this.oldBuilding = res.body.data.buildings;
          this.setchecked(this.room.amenities);
          Object.keys(this.room.images).forEach(key => {this.photo_list.push({id:this.room.images[key].id,url:this.base_url2+'/app/images/'+this.room.images[key].image});});
          this.create_room_form.patchValue(this.room);

          this.wheelc = this.room.wheelchairaccessibility.toLowerCase() == 'true' ? true : false;
          this.create_room_form.patchValue({wheelchairaccessibility: this.wheelc});

          this.smoke = this.room.smoking.toLowerCase() == 'true' ? true : false;;
          this.create_room_form.patchValue({smoking: this.smoke});

          this.petsA = this.room.pets.toLowerCase() == 'true' ? true : false;;
          this.create_room_form.patchValue({pets: this.petsA});
          let avb = this.room.value_add_inclusions.split(',').map(function(item) {
            return parseInt(item, 10);
          });
          this.create_room_form.patchValue({value_add_inclusion:avb})
          if(this.room.extrabedding.length > 0){
            this.extraBed = true;
            let person = this.room.extrabedding.find((p)=> p.type == "Person")
            let crib = this.room.extrabedding.find((e)=> e.type == "Crib");
            let roll = this.room.extrabedding.find((r)=> r.type == "Rollaway Bed");
           // let day = this.room.extrabedding.find((d)=> d.type == "Day Bed");

           if(person){
            this.Persons = true;
            this.pType = person.type;
            this.pQuantity = person.quantity;
            this.pSurcharge_type = person.surcharge_type;
            this.pAmount = person.surcharge_amount;
            this.pSurcharge_amount_type = person.surcharge_amount_type;
          }

            if(crib){
              this.Cribs = true;
              this.cType = crib.type;
              this.cQuantity = crib.quantity;
              this.cSurcharge_type = crib.surcharge_type;
              this.cAmount = crib.surcharge_amount;
              this.cSurcharge_amount_type = crib.surcharge_amount_type;
            }

            if(roll){
              this.Rollaway = true;
              this.rType = roll.type;
              this.rBedType = roll.size;
              this.rQuantity =  roll.quantity;
              this.rSurcharge_type = roll.surcharge_type;
              this.rAmount = roll.surcharge_amount;
              this.rSurcharge_amount_type = roll.surcharge_amount_type;
            }

            // if(day){
            //   this.dayBed = true;
            //   this.dType = day.type;
            //   this.dBedType = day.bed_type;
            //   this.dQuantity = day.quantity;
            // }
          }


      }, err => this.error_handling.handle_error(err.status));

  }

  setchecked(data)
  {
      var checked_data = data;
      setTimeout(()=>{
        checked_data.forEach(element => {
          try {
            (<HTMLInputElement>document.getElementById('trash-'+element.amenitie_id)).checked = true;
          }
          catch(err) {
          }
      },2000)

      });
  }

  updateRoom(id){
    this.submitted = true;
    var most_arr =[];
    var property_id = localStorage.getItem("current_property");
    var most_amenties = document.querySelectorAll("input[name='most_amenties[]'][type=checkbox]:checked");
    Object.keys(most_amenties).forEach(key => {most_arr.push({amenitie_id:most_amenties[key].value});});
    this.create_room_form.patchValue({property_id:property_id,most_amenties: most_arr,
      room_name_images:this.room_name_images
    });
    if (this.create_room_form.invalid) {
      return;
    }
    this.api.post('api/room/update/'+id, this.create_room_form.value,true).subscribe((res:any) => {
      this.helper.alert_success("Room Updated Successfully...");
      this.create_room_form.reset();
    }, err => this.error_handling.handle_error(err.status));
  }

  bulding(){
    this.add_floor_card.length++;
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  add(i, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.user_room.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }

  remomve(user_room: Room): void {
    const index = this.user_room.indexOf(user_room);

    if (index >= 0) {
      this.user_room.splice(index, 1);
    }
  }

  enabled(bool){
    this.enable = bool;
   }

   onChange(e){
    let y = e.split(',');
    this.floorId = {
      floorid: y[0],
      buildid: y[1],
      b_name:  y[2],
      f_no:    y[3],
    };
   }

   onplus(id_) { // with type info
    let a = $('#'+id_).val();
    if(this.floorId == undefined){
       alert("Please select Floor number")
       return false
    }else{

      if(this.add_floor_card.length <= 0){
        this.add_floor_card.push(
          {
            b_id:this.floorId.buildid,
            b_na:this.floorId.b_name,
              f_d:[{
              room_numbers:a,
              id:this.floorId.floorid,
              floor_no:this.floorId.f_no
            }]
          }
        )

      }
      else{
        this.add_floor_card.map((val)=>{
          if(val.b_id == this.floorId.buildid){
              val.f_d.push({
                room_numbers:a,
                id:this.floorId.floorid,
                floor_no:this.floorId.f_no
              })
          }
          else{
            this.add_floor_card.push(
              {
                b_id:this.floorId.buildid,
                b_na:this.floorId.b_name,
                  f_d:[{
                  room_numbers:a,
                  id:this.floorId.floorid,
                  floor_no:this.floorId.f_no
                }]
              }
            )
          }
        })

      }



   // this.floorId = undefined
  }
    $('#'+id_).val('');
  }

  rmFloor(fi, bi){
    this.add_floor_card[bi].f_d.splice(fi,1);
  }

  deleteRoom(rname){
    let that = this;
    this.confirm.confirm(
      "Delete Room ("+ rname +")",
      'Are your sure you want to Delete this Room?',
      [],
      function(){
        // that.api.get('api/room/destory/'+id ,true).subscribe((res:any) => {
        //   that.helper.alert_success('Room Deleted Successfully....');
        //   // that.get_rooms();
        // }, err => that.error_handling.handle_error(err.status, err.message));
      }
  );

  }

  goToPreview(){
    this.router.navigate(['/app/back_office/preview_rooms'])
  }

  changeCaret1(y){
    // document.getElementById('caretOuter').className = "fa fa-caret-up down2";
    let a = document.getElementById(y);
    if(a.className == "fa fa-caret-down down2"){
      a.className = "fa fa-caret-up down2"
  }
  else{
    a.className = "fa fa-caret-down down2"
  }


  }

  changeCaret2(z){
    // document.getElementById('caretOuter').className = "fa fa-caret-up down2";
    let b = document.getElementById(z) ;
    if(b.className == "fa fa-caret-up down1"){
        b.className = "fa fa-caret-down down1"
    }
    else{
      b.className = "fa fa-caret-up down1"
    }

  }

  hideAdd(fi, bi){
    let n = bi+'add'+fi;
    let i = bi+'input'+fi;
    let a = document.getElementById(n);
    let b = document.getElementById(i);
    a.className = 'col-md-12 zero-padding add-room roomH'
    b.className = 'col-md-12 zero-padding mt-5 roomS'

    // $(a).hide();
    // $(b).show();
  }

  hideinput(fi,bi){
    let n = bi+'add'+fi;
    let i = bi+'input'+fi;
    let a = document.getElementById(n);
    let b = document.getElementById(i);
    a.className = 'col-md-12 zero-padding add-room roomS'
    b.className = 'col-md-12 zero-padding mt-5 roomH'
    // $(a).show();
    // $(b).hide();
  }

  addRoom(r_add, fi, bi){

    if(r_add == '' || r_add == undefined ){
      this.error_handling.handle_error('Please  enter room number')
      return false
    }
    this.building_detail[bi].floors[fi].roomnums.push({room_number: r_add})
    let n = bi+'add'+fi;
    let i = bi+'input'+fi;
    let a = document.getElementById(n);
    let b = document.getElementById(i);
    a.className = 'col-md-12 zero-padding add-room roomS'
    b.className = 'col-md-12 zero-padding mt-5 roomH'
    this.emptyInp(bi,fi)
  }
  roomRemove(ri, fi, bi){
    this.building_detail[bi].floors[fi].roomnums.splice(ri, 1);
  }

  emptyInp(bi,fi){
    let j = bi+'null'+fi
    let ab = document.getElementById(j);
    $(ab).val('')
  }

  smokey(){
    this.smoke = !this.smoke
  }

  petsAllowed(){
    this.petsA = !this.petsA
  }

  wheelchair(){
    this.wheelc = !this.wheelc
  }

  bedChange(e, bedtype){
    this.bedSize = e.bedsize.split(',');

  }

  makeNum(){
    this.personNum = [];
    this.cribsNum = [];
    this.RollWayNum = [];
    for (let i = 0; i < 21; i++) {
      this.personNum.push(i);
      this.cribsNum.push(i);
      this.RollWayNum.push(i)
    }
  }

  changeFn(con){
    this.costCrib = con
  }

  changeFn2(con){
    this.costCrib2 = con
  }

  changeFn3(con){
    this.costCrib3 = con
  }

  chngeDrop(id){
    let a = document.getElementById(id);
    a.classList.contains('rotimg') ? a.classList.remove('rotimg') : a.classList.add('rotimg')
  }

  selectExtraBed(){
    setTimeout(() => { this.extraBed = !this.extraBed;
    })
  }

  checkCribs(e){
    this.Cribs = !this.Cribs;
    if(this.Cribs){
      this.cType = 'Crib';
    }
  }

  checkRollaway($event){
    this.Rollaway = !this.Rollaway;
    if(this.Rollaway){
      this.rType = 'Rollaway Bed';
    }
  }

  checkdayBed(e){
    this.dayBed = !this.dayBed;
    if(this.dayBed){
      this.dType = 'Day Bed';
    }
  }

  val(val){
  }

  chkLastBarColor(con){
    let a = document.getElementById('amenityBar')
    if(con%2 == 0){
      a.classList.remove('bl2R');
      a.classList.add('bl2B')
    }else{
      a.classList.add('bl2R');
      a.classList.remove('bl2B')
    }

  }

  chkchkbox(id) {
   if(this.room){
    let Cid = <HTMLInputElement> document.getElementById(id);
    let rmmId = this.room.id;
    let chId = id.split('-')[1];
    setTimeout(()=>{
     if(!Cid.checked){
      this.api.get('api/room_amenitie/destory/'+chId+'/'+rmmId,true).subscribe((res:any) => {
      },err => this.error_handling.handle_error(err.status));
     }
    },1000)
   }
  }

  deleteRoomNo(id){
    this.api.get('api/room/delete_room_num/'+id,true).subscribe((res:any)=>{

    })
  }


}

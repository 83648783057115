import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {


  successMessage;
  pass;
  errorMessage;
  uname;
  cpass;
  email;

  current_date;

  constructor(
    private router : Router,
    private route : ActivatedRoute,
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if(params.forgot){
        this.successMessage = params.forgot;
      }
    });

    
    this.current_date = new Date().getFullYear()

  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private fooSubject = new Subject<any>();
  private logoSubject = new Subject<any>();
  private userAvatar = new Subject<any>();
  constructor() { }

  // darkmode
  publishDarkMode(data: any) {
    this.fooSubject.next(data);
  }
  receiveDarkMode(): Subject<any> {
      return this.fooSubject;
  }

  // hotel logo
  publishLogo(data:any){
    this.logoSubject.next(data);
  }
  receiveLogo(): Subject<any>{
    return this.logoSubject;
  }

  // user avatar
  publishUserImg(data:any){
    this.userAvatar.next(data)
  }
  recieveUserImg(): Subject<any>{
    return this.userAvatar
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../services/auth.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { ConfirmationAlertService } from "../../common/confirmation-alert/confirmation-alert.service";
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm;
  email;
  pass;
  errorMessage;
  successMessage;
  remember;
  current_date;
  darkMode:boolean = false;
  constructor(
    private Api : ApiService,
    private router : Router,
    private route : ActivatedRoute,
    private auth: AuthService,
    private error_handling: ErrorHandlingService,
    private confirmation: ConfirmationAlertService,
    public events: EventsService
  ) { }

  ngOnInit() {
 
    if(localStorage.getItem('pms_auth_token')){ 
    
      return this.router.navigateByUrl("app/dashboard");
    }

    this.route.queryParams.subscribe(params => {
      if(params.forgot){
        this.successMessage = params.forgot;
      }
    });

    this.current_date = new Date().getFullYear()

  }
  
  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  login(form: NgForm){
 
    if(form.valid){
   
      this.auth.authenticate(form.value.email, form.value.pass).subscribe((res: any) => {
     
        if (res.status == 200) {
          localStorage.setItem('pms_auth_token', res.body.access_token);
          localStorage.setItem('pms_refresh_token', res.body.refresh_token);
                 
          this.Api.get('api/user/me', true).subscribe((res:any) => {
     
          
            let user = res.body.data;
            localStorage.setItem('user', JSON.stringify(user));
            
            this.events.publishUserImg(user.profile_picture);
            if(user.mode == 'dark'){ this.darkMode = true; this.checkDarkMode(); this.events.publishDarkMode({darkMode: true});}
            else{this.darkMode = false; this.checkDarkMode(); this.events.publishDarkMode({darkMode: false});}
            if (user.default_property != null) {
              localStorage.setItem('current_property', user.default_property);
            }
            else{
               
              return this.router.navigateByUrl("app/back_office/hotel_setup");              
            }
            this.Api.get('api/property/show/'+user.default_property,true).subscribe((res2:any)=>{
              localStorage.setItem('eta', res2.body.data.eta);
              localStorage.setItem('etd', res2.body.data.etd);
              localStorage.setItem('currency', res2.body.data.currency);
              localStorage.setItem('property', JSON.stringify(res2.body.data));
              localStorage.setItem('logo',res2.body.data.logo);
              this.events.publishLogo(res2.body.data.logo);
            })
            return this.router.navigateByUrl("app/dashboard");
          }, err => this.error_handling.handle_error(err.status));

      
          // 
       
        }else{
          this.errorMessage = res.body.message;    
        }
      }, err => {
        if(err.status == 401){
          this.errorMessage = "Email or Password is incorrect";
        }else{
          this.errorMessage = err.message;
        }
      });
    }else{
      this.errorMessage = "Email or Password is Invalid";
    }
  }

  confirmBox(){
    this.confirmation.confirm(
        "Unlock User (Jano)", 
        'Are your sure you want to do this action?',
        ['is this you', 'here are your godies', 'bye bye'],
        function(){
        }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators,ValidationErrors  } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-setup-rate',
  templateUrl: './setup-rate.component.html',
  styleUrls: ['./setup-rate.component.css']
})
export class SetupRateComponent implements OnInit {


  daily_rate: string;

  select_rates=[
    {id:'1','label':'Rate Package - 1'},
    {id:'2','label':'Rate Package - 2'},
    {id:'3','label':'Rate Package - 3'},
    {id:'4','label':'Standard Rate Package'},
  ];

  manage_daily_rate=[
    {id:'1','label':'%'},
    {id:'2','label':'$'},
    {id:'3','label':'$ + %'},
    {id:'4','label':'% + $'},
  ];

  submitted=false;
  conversion;
  base_url;
  manage_dollar_error=false;
  manage_percentage_error=false;
  packages;
  manage_daily_rates;
  package_devried = 'No';
  manage_dollar;
  manage_percentage;
  room_rate; 

  package = [];
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private service: ManageRatesPackagesService,
    private router: Router
  ) { }

  setup_rate_form : FormGroup;
  check_form: FormGroup

  ngOnInit() {
    this.get_packages();
    this.setup_rate_form = this.fb.group({
      room_rate: [''  ,Validators.required],
      manage_daily_rates: ['',Validators.required],
      manage_dollar: '',
      manage_percentage:'',
      property_id:['', Validators.required],
      package_devried: 'No'
      // rate_plan_id:'',
    })
    this.base_url=this.api.base_url;

    this.check_form = this.fb.group({
      rate_plan_id: [null,Validators.required],
      manage_daily_rates: [null,Validators.required],
      property_id:['', Validators.required],
      manage_dollar:'',
      manage_percentage:'',    
      room_rate:'',
      package_devried: 'No'  
    });

    this.f['manage_daily_rates'].valueChanges.subscribe((res = []) => {
      // this.setup_rate_form.controls['new_password'].setValidators([Validators.required]);
      // this.setup_rate_form.controls['new_password'].updateValueAndValidity();
      if(res){
        if(res.label=='%')
        {
          this.setup_rate_form.controls['manage_percentage'].setValidators([Validators.required]);
          this.setup_rate_form.controls['manage_percentage'].updateValueAndValidity();
          this.setup_rate_form.controls['manage_dollar'].setValidators(null);
          this.setup_rate_form.controls['manage_dollar'].updateValueAndValidity();
          this.manage_percentage_error=true;
          this.manage_dollar_error=false;
        }
        if(res.label=='$')
        {
          this.setup_rate_form.controls['manage_dollar'].setValidators([Validators.required]);
          this.setup_rate_form.controls['manage_dollar'].updateValueAndValidity();
          this.manage_dollar_error=true;
          this.setup_rate_form.controls['manage_percentage'].setValidators(null);
          this.setup_rate_form.controls['manage_percentage'].updateValueAndValidity();
          this.manage_percentage_error=false;
        }
        if(res.label=='$ + %')
        {
          this.setup_rate_form.controls['manage_dollar'].setValidators([Validators.required]);
          this.setup_rate_form.controls['manage_dollar'].updateValueAndValidity();
          this.setup_rate_form.controls['manage_percentage'].setValidators([Validators.required]);
          this.setup_rate_form.controls['manage_percentage'].updateValueAndValidity();
          this.manage_dollar_error=true;
          this.manage_percentage_error=true;
        }
        if(res.label=='% + $')
        {
          this.setup_rate_form.controls['manage_percentage'].setValidators([Validators.required]);
          this.setup_rate_form.controls['manage_percentage'].updateValueAndValidity();
          this.setup_rate_form.controls['manage_dollar'].setValidators([Validators.required]);
          this.setup_rate_form.controls['manage_dollar'].updateValueAndValidity();
          this.manage_percentage_error=true;
          this.manage_dollar_error=true;
        }
      }
    });
    this.get_package();
  }

  get f() { return this.setup_rate_form.controls; }
  get g() { return this.check_form.controls; }

  resetform(){
    this.setup_rate_form.reset();
  }

  calculate(type, id) {
    var current = Number((<HTMLInputElement>document.getElementById(id)).value);
    if(type=='-'){
      if(current > 0){
        this[id]=current - 1;
        return current = current - 1;
      }
    }
    else if(type=='+'){
      if(current >= 0){
        this[id]=current + 1;

        return  current = current + 1;
      }
    }
  }
  // multiple_conversion(type, id) {
  //   var current = Number((<HTMLInputElement>document.getElementById(id)).value);
  //   if(type=='-'){
  //     if(current > 0){
  //       this[id]=current - 1;
  //       return current = current - 1;
  //     }
  //   }
  //   else if(type=='+'){
  //     if(current >= 0){
  //       this[id]=current + 1;

  //       return  current = current + 1;
  //     }
  //   }
  // }

  onSubmit(){
    this.submitted = true;

    if (this.setup_rate_form.invalid) {
      Object.keys(this.setup_rate_form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.setup_rate_form.get(key).errors;
        if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if(key==='manage_dollar'){
                  this.helper.alert_error("Dollar Amount is required");
                  return;
                }
                if(key==='manage_percentage'){
                  this.helper.alert_error("Percentage is required");
                  return;
                }
              });
        }
      });
      return;
    }

    // let id = 10;
    // this.setup_rate_form.get('property_id').setValue(10);

    // var dt =this.setup_rate_form.value.manage_daily_rates;
    this.setup_rate_form.patchValue({property_id: localStorage.getItem('current_property'), package_devried: this.package_devried});

    this.api.post('api/package/update/'+this.service.package_creating, this.setup_rate_form.value,true).subscribe((res:any) => {
      this.service.get_packages();
      this.helper.alert_success("Rates are now setup");
      // this.setup_rate_form.reset()
      this.submitted = false;
      this.service.open_tab('setup_restrictions_open');
    }, err => this.error_handling.handle_error(err.status));
    
  }


  onSubmit_check(){
    this.submitted = true;
    var property_id = localStorage.getItem("current_property");
    this.check_form.patchValue({property_id:property_id, package_devried: this.package_devried});
    if (this.check_form.invalid) {
      Object.keys(this.check_form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.check_form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            if(key==='manage_dollar'){
              this.helper.alert_error("Dollar Amount is required");
              return;
            }
            if(key==='manage_percentage'){
              this.helper.alert_error("Percentage is required");
              return;
            }
          });
        }
      });
      return;
    }
    this.api.post('api/package/update/'+this.service.package_creating, this.check_form.value,true).subscribe((res:any) => {
    this.helper.alert_success("Rates are now setup");
    // this.setup_rate_form.reset()
    this.submitted = false;
    }, err => this.error_handling.handle_error(err.status) );
    
  }

  // onChange(newObj){
  //   if(newObj){
  //     this.daily_rate = newObj;
  //   }
  // }
  
  get_packages(){
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/package/index',{property_id:property_id},true).subscribe((res:any) => {
      this.packages = res.body.data;
      this.getHi()
    }, err => this.error_handling.handle_error(err.status, err.message));
  }
  
  package_select($event) 
  {
    this.check_form.patchValue({
      manage_daily_rates: $event.manage_daily_rates,
      manage_dollar:$event.manage_dollar,
      manage_percentage:$event.manage_percentage,
      room_rate:$event.room_rate
    });
    // let element = document.getElementById("checkChange");
    // element.dispatchEvent(new Event("change"));
    // this.manage_daily_rates= $event.manage_daily_rates;
    this.daily_rate = $event.manage_daily_rates;
    this.manage_percentage = $event.manage_percentage;
    this.manage_dollar = $event.manage_dollar;
    this.room_rate = $event.room_rate;

    this.getHi()
  }

  get_package(){
    if(this.service.package_creating !==undefined){
        this.api.get('api/package/show/'+this.service.package_creating,true).subscribe((res:any) => {
          this.check_form.patchValue({
            manage_daily_rates: res.body.data.manage_daily_rates,
            manage_dollar: res.body.data.manage_dollar,
            manage_percentage: res.body.data.manage_percentage,
            room_rate:res.body.data.room_rate,
            rate_plan_id: res.body.data.rate_plan_id, 
            property_id: res.body.data.property_id,
            package_devried: 'Yes'
          });
          this.setup_rate_form.patchValue({
            room_rate: res.body.data.room_rate,
            manage_daily_rates: res.body.data.manage_daily_rates,
            manage_dollar: res.body.data.manage_dollar,
            manage_percentage: res.body.data.manage_percentage,
            property_id: res.body.data.property_id,
            // rate_plan_id:res.body.data.rate_plan_id, 
            package_devried: 'No'
          });
          this.package = res.body.data;
          this.daily_rate = res.body.data.manage_daily_rates;
          this.package_devried = res.body.data.package_devried;
          this.manage_dollar = res.body.data.manage_dollar;
          this.manage_percentage = res.body.data.manage_percentage;
          this.room_rate = res.body.data.room_rate;
          this.getHi()
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }


  getHi(){

    setTimeout(()=>{
      let a = document.getElementById("createrateDiv")

      if(a.offsetHeight < 700){
        a.style.width = '100.7%'
      }
      else{
        a.style.width = '100%'
      }

    },100)

  }



}

import { Component, OnInit  } from '@angular/core';
import { LayoutService } from "../layout.service";
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";
import {NgZone, Renderer, ElementRef, ViewChild} from '@angular/core'
import { EventsService } from '../../services/events.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {
  
  darkMode:boolean = false;


  constructor(
    private layout_service: LayoutService,
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer,
    public events: EventsService,
    private spinner: NgxSpinnerService
  ) {
    
    router.events.subscribe((event: RouterEvent): void => {      
      if (event instanceof NavigationStart) {
        
        layout_service.spinner = true;
        this.spinner.show('sp6');
      }
      if (event instanceof NavigationEnd) {
        
        layout_service.spinner = false;
        this.spinner.hide();
      }
  
      // Set layout_service.spinner state to false in both of the below events to hide the spinner in case a request fails
      if (event instanceof NavigationCancel) {
        layout_service.spinner = false;
        this.spinner.hide();
      }
      if (event instanceof NavigationError) {
        layout_service.spinner = false;
        this.spinner.hide();
      }
    });
    this.events.receiveDarkMode().subscribe((res:any)=>{
      this.darkMode = res.darkMode;
    })
  }
  
  ngOnInit() { 
    this.checkDarkMode();
  }  


  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
  
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }
  


}




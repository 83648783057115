import { AbstractControl, ValidationErrors } from "@angular/forms";

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  let percentage = 0;
  let msg = '';
  let color = '';

  if (!value) {
    return null
  }

  let upperCaseCharacters = /[A-Z]+/g;
  let hasUpperCase = upperCaseCharacters.test(value);
  
  let lowerCaseCharacters = /[a-z]+/g;
  let hasLowerCase = lowerCaseCharacters.test(value);
  
  let numberCharacters = /[0-9]+/g;
  let hasNumber = numberCharacters.test(value);
  
  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  let hasSpecial = specialCharacters.test(value);
  
  if (hasSpecial === false) {
    msg = 'Text has to contine Special Character';
  }else{
    percentage += 25;
  }

  if (hasNumber === false) {
    msg = 'Text has to contine Number Characters';
  }else{
    percentage += 25;
  }

  if (hasLowerCase === false) {
    msg = 'Text has to contine Lower case Characters';
  }else{
    percentage += 25;
  }

  if (hasUpperCase === false) {
    msg = 'Text has to contine Upper case characters';
  }else{
    percentage += 25;
  }

  if(percentage == 0 || percentage == 25){
    status = 'Poor';
    color = 'red';
  }

  if(percentage == 50){
    status = 'Fair';
    color = 'orange';
  }

  if(percentage == 75){
    status = 'Good';
    color = 'yellow';
  }

  if(percentage == 100){
    status = 'Excellent';
    color = 'green';
  }

  if (msg != '') {
      return { passwordStrength: msg, percent: percentage, status:  status, color: color};
  } else {
      return null;
  }
}


import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  pass;
  conf_pass;
  errorMessage;
  successMessage;
  auth_token; 

  constructor(
    private Api : ApiService,
    private router : Router,
    private route : ActivatedRoute,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.auth_token = this.route.snapshot.paramMap.get("token");
  }

  reset(form: NgForm){
    if(form.valid){
      this.auth.reset_password(form.value.pass, form.value.email, this.auth_token).subscribe((res: any) => {
        if (res.status == 200) {
          return this.router.navigate(["/"], {queryParams: {message: "Your Password Reset Successfully."}}); 
        }else{
          this.errorMessage = res.body.message;    
        }
      }, err => {
        if(err.status == 401){
          this.errorMessage = err.message; 
        }
      });
    }else{
      this.errorMessage = "Email or Password is Invalid";
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ConfirmationAlertService } from "./confirmation-alert.service";
@Component({
  selector: 'app-confirmation-alert',
  templateUrl: './confirmation-alert.component.html',
  styleUrls: ['./confirmation-alert.component.css']
})
export class ConfirmationAlertComponent implements OnInit {
  darkMode:boolean = false;
  message:any;
  constructor(
      private alert: ConfirmationAlertService
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.alert.getMessage().subscribe(message => {
        this.message = message;
    });
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

}

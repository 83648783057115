import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobaldataService {

  constructor() { }

  public static savedSeason:any = undefined;
  public static editSeason:any = undefined;
  public static holidays:any;
  public static previewPercent:any = undefined;
 
}
import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators ,ValidationErrors} from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.css']
})
export class CreateUsersComponent implements OnInit {
  
  user;
  roles
  id_types=[{id:'2','label':'National ID'},{id:'3','label':'Passport'},{id:'4','label':'Driving Licence'}];
  shifts=[{id:'2','label':'None'},{id:'3','label':'Morning'},{id:'4','label':'Evening'},{id:'5','label':'Night'}];
  permis = [{name:'Inventory',id:1},{name:'Reservations',id:2},{name:'Payments',id:3},{name:'Rates & Packages',id:4},{name:'Manage OTAS',id:5},{name:'Reports',id:6},{name:'Billing',id:7}]
  create_user_form: FormGroup;
  submitted=false;
  role;
  shift;
  p;
  check = false;
  id_type;
  permises = [];
  per;
  userImg:any;
  darkMode:boolean = false;
  customRole:boolean = false
  oriper;
  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private helper: HelperService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.getRoles();
    this.getPermissions();
    if(this.route.snapshot.paramMap.get('id')){
        this.get_user(this.route.snapshot.paramMap.get('id'));
    }

    this.create_user_form = this.fb.group({  
      first_name: ['',Validators.required],
      last_name: ['',Validators.required],
      phone: ['',Validators.pattern("[0-9]{0,10}")],
      email: ['',Validators.required],
      role: [null,Validators.required],
      admin_id:'',
      permissions:'',
      email_notifications:'',
      address:'',
      shift:['',Validators.required],
      id_type:'',
      default_property: localStorage.getItem("current_property"),
      id_number:'',
      shift_days:['',Validators.required],
      profile_picture:''
    });
    this.get_img()
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  getRoles(){
    this.api.get('api/helper/app_roles', true).subscribe((res:any)=>{
      this.roles = res.body.data;
      this.roles.push({
        id: 'custom',
        label: "Custom Role",
        name: "Custom Role"})
    })
  }

  getPermissions(){
    this.api.get('api/helper/app_permissions', true).subscribe((res:any)=>{
      this.permis = res.body.data;
      console.log(this.permis);
    })
  }

  search(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
          return myArray[i];
      }
    }
  }

  get f() { return this.create_user_form.controls; }

  getPermissionArray(array2){
    console.log(array2);
     this.check = true;
    this.p = array2
      }

  onSubmit(){
    
    var arr= [];
    var permissions = this.per;//document.querySelectorAll("input[name='permissions[]'][type=checkbox]:checked"); 
    var perm =[];
    var a:any;
    if(!1==this.check){for(let a=0;a<Object.keys(this.per).length;a++)arr.push(this.per[a].app_permission_id);}else arr=this.p;   
    var prr= [];
    var shift_days = document.querySelectorAll("input[name='shift_days[]'][type=checkbox]:checked");
    Object.keys(shift_days).forEach(key => {prr.push(shift_days[key].value);});    
    let id = JSON.parse(localStorage.getItem('user')).id;
    this.create_user_form.patchValue({admin_id: id,permissions:arr,shift_days:prr.join()});
    this.create_user_form.value.profile_picture = this.userImg;
    // document.querySelectorAll("input[name='permissions[]'][type=checkbox]:checked")
    this.create_user_form.value.permissions = arr//this.permises ;
    if(this.user){ 
      console.log(this.create_user_form.value);

      this.api.post('api/user/update/'+this.user.id, this.create_user_form.value, true).subscribe((res:any) => {
        this.helper.alert_success('User Updated Successfully...');
    }, err => this.error_handling.handle_error(err.status));
      return;
    }
    this.submitted=true;
    if (this.create_user_form.invalid) {
      return;
    }
    this.api.post('api/user/store', this.create_user_form.value, true).subscribe((res:any) => {
      this.helper.alert_success('User Created Successfully...');
      this.router.navigate(['/app/back_office/manage_users']);
    }, err => this.error_handling.handle_error(err.status));
    
  }

  get_user(id){
    this.api.get('api/user/show/'+id, true).subscribe((res:any) => {
      this.user = res.body.data;
      this.create_user_form.patchValue(this.user);
      this.create_user_form.patchValue({permissions: this.user.permissionss});
      this.role=this.user.role;
      this.per = res.body.data.permissions
      // this.getPermissionArray(res.body.data.permissions)
      // Object.keys(res.body.data.permissions).forEach(elm => {
      //   try {
      //     (<HTMLInputElement>document.getElementById(res.body.data.permissions[elm].toLowerCase())).checked = true; 
      //   } catch (error) {  
      //   }
      // });
      let sa = res.body.data.shift_days.split(",")
      Object.keys(res.body.data.shift_days).forEach(elm => {
        try {
          (<HTMLInputElement>document.getElementById(sa[elm].toLowerCase())).checked = true; 
        } catch (error) {  
        }
      });
    }, err => this.error_handling.handle_error(err.status));
  }

  async onFileChange(event){
    if(event.target.files && event.target.files[0]){
      for(var i=0; i < event.target.files.length; i++){
        var r = null;
        var f = event.target.files[i];
        var data = new FormData();
        data.append('image', event.target.files[i], event.target.files[i].name);
          await this.api.post2('api/helper/upload_image', data, false).subscribe((img_res:any) => {
          this.userImg = img_res.body.data.image;
        }, err => this.error_handling.handle_error(err.status));
      }
    }   
  }

  get_img(){
    const realFileBtn = (<HTMLInputElement>document.getElementById('real-file')) ;
    const customBtn = (<HTMLInputElement>document.getElementById('custom-button')) ;
    const customText = (<HTMLInputElement>document.getElementById('custom-text'));
    customBtn.addEventListener("click", function(){
        realFileBtn.click()
    })
    realFileBtn.addEventListener("change", function(){
      if(realFileBtn.value){
          customText.innerHTML = realFileBtn.value.match(/[\/\\]([\w\d\s\.\-\(\)]+)$/)[1];
      }
      else{
          customText.innerHTML = "No File Chossen"
      }
    })
  }

  customRoles(role){
    if(role == 'Custom Role'){
      this.customRole = true
    }
  }

  addCRoles(role){
    this.api.post('api/helper/app_roles_store',{ user_id:JSON.parse(localStorage.getItem('user')).id, name:role},true).subscribe((res:any)=>{
      this.customRole = false;
      this.role = null
      this.getRoles();
      this.helper.alert_success('Role added successfully');
    }, err => this.error_handling.handle_error(err.status));
  }

}

import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { Router } from "@angular/router";
declare var $: any;
@Component({
  selector: 'app-link-ota',
  templateUrl: './link-ota.component.html',
  styleUrls: ['./link-ota.component.css']
})
export class LinkOTAComponent implements OnInit {
  channels;
  create_package_form: FormGroup;
  submitted = false;
  base_url;
  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private fb: FormBuilder,
    private service: ManageRatesPackagesService,
    private router: Router
  ) {
    this.base_url = this.api.base_url;
  }

  ngOnInit() {
    this.get_channels();
    this.create_package_form = this.fb.group({
      // package_name: ['',Validators.required],
      // date_from: ['',Validators.required],
      // room_id: ['',Validators.pattern("[0-9]{0,10}")],
      // description: ['',Validators.required],
      // breakfast_include:['',Validators.required],
    });
    if (this.service.package_creating !== undefined) {
      this.setChecked(this.service.package_creating);
    }

  }

  get f() { return this.create_package_form.controls; }

  get_channels() {
    this.api.post('api/channels/index', { status: 'Enabled' }, true).subscribe((res: any) => {
      // this.channels = res.body.data.filter(function (e) { return e.type == 'External' });
      this.channels = res.body.data;
      this.getHi()
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  onSubmit() {
    var arr = [];
    var pk_id = this.service.package_creating;
    $('input.channels_checkbox:checkbox:checked').each(function () {
      // this.service.package_creating=1;
      arr.push({ 'channel_id': $(this).val(), 'package_id': pk_id });
    });
    if (arr.length == 0) {
      this.helper.alert_error('Select at least one OTA!');
      return;
    }
    this.api.post('api/ota_package/store', arr, true).subscribe((res: any) => {
      this.helper.alert_success('OTAs has been Linked successfully !');
      this.service.open_tab('preview_package_open');
      this.service.get_packages();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }
  setChecked(id) {
    var data = { package_id: id };
    this.api.post('api/ota_package/index', data, true).subscribe((res: any) => {
      res.body.data.forEach(element => {
        $("#check-" + element.channel_id).attr('checked', 'true');
      });
      // this.helper.alert_success('OTAs has been Linked successfully !');
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  getHi(){

    setTimeout(()=>{
      let a = document.getElementById("otaDiv")

      if(a.offsetHeight < 700){
        a.style.width = '100.8%'
      }
      else{
        a.style.width = '100%'
      }

    },100)

  }
}

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from "@angular/forms";
import { HelperService } from "../../services/helper.service";
import { Router } from "@angular/router";
import { ConfirmationAlertService } from "../../common/confirmation-alert/confirmation-alert.service";
import { MatSlideToggleChange, MatSlideToggle, MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
declare var $: any;

@Component({
  selector: 'app-manage-otas',
  templateUrl: './manage-otas.component.html',
  styleUrls: ['./manage-otas.component.css'],
  providers: [
    {provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS , useValue: {disableToggleValue: false, disableDragValue: true}},
  ]
})


export class ManageOtasComponent implements OnInit {
  // @Output()
  // dragChange: EventEmitter<void>
  // @Input()
  // checked: boolean;
  
  @Output()
  change: EventEmitter<MatSlideToggleChange>
  internal_channels;
  external_channels;
  awaiting_channels;
  id;
  settings;
  currency;
  submitted = false;
  class: boolean = true;
  opac: number = 2;
  darkMode:boolean = false

  rate;
  username;
  hotel_code;
  password;
  channel_id;
  all_data;
  updateData;
  default_currency;
  currency_from;
  currency_to;
  checked: boolean;
  base_url;
  settings_form: FormGroup;
  settings_update: FormGroup;
  add_setup_ota: FormGroup
  disabled = true;
  user_name;
  hotelcode;
  pass_word;
  name;
  website;
  commission;
  type;
  

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private fb: FormBuilder,
    private router: Router,
    private confirm: ConfirmationAlertService
  ) {
    this.base_url = this.api.base_url;
  }

  ngOnInit() {
    
    this.settings_form = this.fb.group({
      'username': ['', Validators.required],
      'hotel_code': ['', Validators.required],
      'password': ['', Validators.required],
      'rate': '',
      'property_id': '',
      'user_id': '',
      'channel_id': ['', Validators.required],
    });
    this.settings_update = this.fb.group({
      'username': ['', Validators.required],
      'hotel_code': ['', Validators.required],
      'password': ['', Validators.required],
      'rate': '',
      'property_id': '',
      'user_id': '',
      'channel_id': ['', Validators.required],
      'currency_from': ['', Validators.required],
      'default_currency': ['', Validators.required],
      'currency_to': ['', Validators.required],
      'status': '',
    });
    this.add_setup_ota = this.fb.group({
      'name': ['' , Validators.required],
      'website': ['' , Validators.required],
      'username': ['', Validators.required],
      'hotel_code': ['', Validators.required],
      'password': ['', Validators.required],
      'rate': '',
      'property_id': '',
      'user_id': '',
      'type': '',
      'commission': '',
    })
    this.get_channels();
    this.get_currency();
    $(document).ready(() => {
      $('.modal').on('hidden.bs.modal', () => {
        this.add_setup_ota.reset()
      });
    });
    // drag function code--------------------------------
    $(function () {
      setTimeout(function(){
          $("#drag-box1-ota").dragsort({
              dragSelector: "div",
              dragBetween: true,
              dragEnd: () => {
                  var data = $("#drag-box1-ota #drag-item1-ota").map(function () {
                      return $(this).children().html();
                  }).get();
                  // $("input[name=list1SortOrder]").val(data.join("|"));
              },
              placeHolderTemplate: "<div class='placeHolder' style='float: unset; width: 33%!important; padding-bottom:10px'>" +
              "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
              "</div>",
              cursor: "move"
          });
      }, 1000)
  });
  // full bar drag-------------------------------------------

        $(function () {
          setTimeout(function(){
              $("#drag-box-bar").dragsort({
                  dragSelector: "ul",
                  dragBetween: true,
                  dragEnd: () => {
                      var data = $("#drag-box-bar .drag-item").map(function () {
                          return $(this).children().html();
                      }).get();
                      // $("input[name=list1SortOrder]").val(data.join("|"));
                  },
                  placeHolderTemplate: "<li class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
                  "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
                  "</li>",
                  cursor: "move"
              });
          }, 1000)
      });


    // ---------------------------------------------------
        $(function () {
          setTimeout(function(){
              $("#drag-box2-ota").dragsort({
                  dragSelector: "div",
                  dragBetween: true,
                  dragEnd: () => {
                      var data = $("#drag-box2-ota #drag-item2-ota").map(function () {
                          return $(this).children().html();
                      }).get();
                      // $("input[name=list1SortOrder]").val(data.join("|"));
                  },
                  placeHolderTemplate: "<div class='placeHolder' style='float: unset; width: 33%!important; padding-bottom:10px'>" +
                  "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
                  "</div>",
                  cursor: "move"
              });
          }, 1000)
      });
    // -------------------------------------
    $(function () {
      setTimeout(function(){
          $("#drag-box-ota").dragsort({
              dragSelector: "div",
              dragBetween: true,
              dragEnd: () => {
                  var data = $("#drag-box-ota #drag-item-ota").map(function () {
                      return $(this).children().html();
                  }).get();
                  // $("input[name=list1SortOrder]").val(data.join("|"));
              },
              placeHolderTemplate: "<div class='placeHolder' style='float: unset; width: 33%!important; padding-bottom:10px'>" +
              "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
              "</div>",
              cursor: "move"
          });
      }, 1000)
  });
  // drag function end---------------------------------
  }
  enable = new FormControl();
  onDragChange(id) {
    $("#ck_" + id).click();
  }
  get f() { return this.settings_form.controls; }
  get s() { return this.add_setup_ota.controls; }
  get_channels() {
    var property_id = localStorage.getItem("current_property");
    // this.api.post('api/channels/index?per_page=50',{type: 'Internal'} ,true).subscribe((res:any) => {
    //   this.internal_channels = res.body.data;
    // }, err => this.error_handling.handle_error(err.status, err.message));
    this.api.post('api/channels/index?per_page=50', { property_id: property_id }, true).subscribe((res: any) => {
      this.all_data = res.body.data;
       


      //External
      this.external_channels = res.body.data.filter(obj => {
        return obj.type === 'External' && obj.connected === true
      });
      this.external_channels.reverse(); 


      //Internal
      this.internal_channels = res.body.data.filter(obj => {
        return (obj.type === 'Internal' || obj.type === null) && obj.connected === true
      });
      this.internal_channels.reverse();

      //Awaiting
      this.awaiting_channels = res.body.data.filter(obj => {
        return obj.connected === false
      });
    }, err => this.error_handling.handle_error(err.status, err.message));




    // this.api.post('api/channels/index?per_page=50',{type: 'External'} ,true).subscribe((res:any) => {
    //   this.external_channels = res.body.data;
    // }, err => this.error_handling.handle_error(err.status, err.message));

    // this.api.post('api/channels/index?per_page=50',{type: 'Awaiting'} ,true).subscribe((res:any) => {
    //   this.awaiting_channels = res.body.data;
    // }, err => this.error_handling.handle_error(err.status, err.message));
  }

  get_currency() {
    this.api.get('api/helper/currency', true).subscribe((res: any) => {
      this.currency = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }
  // (dragChange)="onDragChange(external.id)"
  onChange(ob: MatSlideToggleChange) {
    // let matSlideToggle: MatSlideToggle = ob.source;	
  }

  div_clicked(id){
    this.settings = this.all_data.find(obj => {
      return obj.id === id
    });
  }
  
  toggle_change(e, val, id) {
    
    // e.source.checked = val;
    // e.checked = val;

    this.settings = this.all_data.find(obj => {
      return obj.id === id
    });

    this.channel_id = id;
    if (e.checked == false && this.settings.property_channel_id != false) {
      this.ota_setting_modal(this.settings.property_channel_id, e.checked);
    }
    else if (val == false) {
      $('#settings').modal('show');
    }
    else if (val == true) { 
      $(document).ready(function () {
        $("#confirm_modal").modal('show');
      });

      // $('#confirmationModal').modal('show');
      let that = this;
      this.confirm.confirm(
        "Disable Channel (" + this.settings.name + ")",
        'Are your sure you want to DISABLE this CHANNEL?',
        ['This action can be undone', 'You can ENABLE your channel at anytime'],
        function () {
          that.api.post('api/properties_channel/update/' + that.settings.property_channel_id, { 'disabled_by': 'User', 'status': 'Disabled' }, true).subscribe((res: any) => {
            that.helper.alert_success('Channel has been Disabled successfully !');
          }, err => this.error_handling.handle_error(err.status, err.message));
        },
        '',
        ''
      );
      // this.api.post('api/channels/index', {id: id}, true).subscribe((res:any) => {
      //   this.settings = res.body.data[0];
      //   this.channel_id = res.body.data[0].id;;
      //   $('#settings').modal('show');
      // }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }
  toggle_change_2(e, val, id) {
    
    // e.source.checked = val;
    // e.checked = val;

    this.settings = this.all_data.find(obj => {
      return obj.id === id
    });

    this.channel_id = id;
    if (e.checked == false && this.settings.property_channel_id != false) {
      this.ota_setting_modal(this.settings.property_channel_id, e.checked);
    }
    // else if (val == false) {
    //   $('#ota_settings').modal('show');
    // }
    else if (val == false) {
      $('#settings').modal('show'); 
    }
    else if (val == true) { 
      $(document).ready(function () {
        $("#confirm_modal").modal('show');
      });

      // $('#confirmationModal').modal('show');
      let that = this;
      this.confirm.confirm(
        "Disable Channel (" + this.settings.name + ")",
        'Are your sure you want to DISABLE this CHANNEL?',
        ['This action can be undone', 'You can ENABLE your channel at anytime'],
        function () {
          that.api.post('api/properties_channel/update/' + that.settings.property_channel_id, { 'disabled_by': 'User', 'status': 'Disabled' }, true).subscribe((res: any) => {
            that.helper.alert_success('Channel has been Disabled successfully !');
          }, err => this.error_handling.handle_error(err.status, err.message));
        },
        '',
        ''
      );
      // this.api.post('api/channels/index', {id: id}, true).subscribe((res:any) => {
      //   this.settings = res.body.data[0];
      //   this.channel_id = res.body.data[0].id;
      //   $('#settings').modal('show');
      // }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }

  mat_change(e, val, id) {

    e.source.checked = val == 'Active' ? true : false;
    e.checked = val;

    this.settings = this.all_data.find(obj => {
      return obj.id === id
    });

    this.channel_id = id;
     if (val == 'Active') { 
      $(document).ready(function () {
        $("#confirmationModal").modal('show');
      });

      // $('#confirmationModal').modal('show');
      let that = this;
      this.confirm.confirm(
        "Disable (" + this.settings.name + ")",
        'Are your sure you want to DISABLE this CHANNEL?',
        ['This action can be undone', 'You can ENABLE your channel at anytime'],
        () => {
          that.api.post('api/properties_channel/update/' + that.settings.property_channel_id, { 'status': 'Disabled' }, true).subscribe((res: any) => {
            that.helper.alert_success('Channel has been Disabled successfully !');
            this.get_channels();
          }, err => this.error_handling.handle_error(err.status, err.message));
        },
        '',
        " :" + this.settings.createdAt + " "
      );
    }

     if (val == 'Disabled') { 
      $(document).ready(function () {
        $("#confirmationModal").modal('show');
      });

      // $('#confirmationModal').modal('show');
      let that = this;
      this.confirm.confirm(
        "Enable (" + this.settings.name + ")",
        'Are your sure you want to ENABLE this CHANNEL?',
        ['This action can be undone', 'You can DISABLE your channel at anytime'],
        () => {
          that.api.post('api/properties_channel/update/' + that.settings.property_channel_id, { 'status': 'Active' }, true).subscribe((res: any) => {
            that.helper.alert_success('Channel has been Enabled successfully !');
            this.get_channels();
          }, err => this.error_handling.handle_error(err.status, err.message));
        },
        '',
        " :" + this.settings.createdAt + " "
      );
    }

    
  }



  ota_setting_modal(id, connected) {
    // if(connected === false){
    //   $('#ota_settings').modal('hide');
    //    return;
    // }
    if (id !== false) {
      this.api.get('api/properties_channel/show/' + id, true).subscribe((res: any) => {
        this.updateData = res.body.data;
        this.settings_update.patchValue({
          property_id: this.updateData.property_id,
          user_id: this.updateData.user_id,
          rate: this.updateData.rate,
          username: this.updateData.username,
          hotel_code: this.updateData.hotel_code,
          password: this.updateData.password,
          channel_id: this.updateData.channel_id,
          status: this.updateData.status,
        });
        this.default_currency = this.updateData.default_currency;
        this.currency_from = this.updateData.currency_from;
        this.currency_to = this.updateData.currency_to;

        // setTimeout(() => {
        //   var a = $(".channel_status_"+id);
        // }, 1000);

        // setTimeout(() => {
        //   var a = $(".channel_status_"+id);
        // if(a[0].value=="true" || a[0].value=="on" ){
        //   a[0].checked=true;
        //   a[1].checked=false;

        // }
        // else if(a[1].value=="true" || a[1].value=="on"){
        //   a[1].checked=true;
        //   a[0].checked=false;
        // }      
        // }, 1000);
      }, err => this.error_handling.handle_error(err.status));

      $('#ota_settings').modal('show');
    }
  }




  updateSettings() {
    var data = JSON.parse(JSON.stringify(this.settings_update.value));
    var a = $(".channel_status_" + this.updateData.id);
    var status;
    if (a[0].value == "true" || a[0].value == "on") {
      if (this.updateData.status == 'Active') {
        status = 'Active';
      }
      else {
        status = 'Awaiting';
      }
    }
    else if (a[1].value == "true" || a[1].value == "on") {
      status = 'Disabled';
    }
    data['status'] = status;
    //var data =Object.keys(this.settings_update.value).forEach(key => this.settings_update.value[key] === undefined && delete this.settings_update.value[key])
    this.api.post('api/properties_channel/update/' + this.updateData.id, data, true).subscribe((res: any) => {
      this.helper.alert_success('Changes has been saved successfully !');
      this.get_channels();
    }, err => this.error_handling.handle_error(err.status));
  }



  onSubmit() {
    var property_id = localStorage.getItem("current_property");
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    this.settings_form.patchValue({
      property_id: property_id,
      user_id: user_id,
      rate: this.rate,
      username: this.username,
      hotel_code: this.hotel_code,
      password: this.password,
      channel_id:this.channel_id,
    });
    this.submitted = true;
     if (this.settings_form.invalid) {
          Object.keys(this.settings_form.controls).forEach(key => {
       const controlErrors: ValidationErrors = this.settings_form.get(key).errors;
       if (controlErrors != null) {
             Object.keys(controlErrors).forEach(keyError => {
             });
           }
         });
       return;
     }
     this.api.post('api/properties_channel/store', this.settings_form.value, true).subscribe((res: any) => {
       this.helper.alert_success('Channel butler has completed setup !');
       this.get_channels();
       $('#settings').modal('hide');
     }, err => this.error_handling.handle_error(err.status));
  }


  onSetup() {

    if (
      this.add_setup_ota.value.username !== null && this.add_setup_ota.value.username !== "" &&
      this.add_setup_ota.value.username !== undefined && 
      
      this.add_setup_ota.value.password !== null && this.add_setup_ota.value.password !== "" &&
      this.add_setup_ota.value.password !== undefined && 

      this.add_setup_ota.value.hotel_code !== null &&
      this.add_setup_ota.value.hotel_code !== undefined && this.add_setup_ota.value.hotel_code !== "" &&

      this.add_setup_ota.value.name !== undefined && this.add_setup_ota.value.name !== null &&
      this.add_setup_ota.value.name !== undefined &&
      
      this.add_setup_ota.value.website !== null &&
      this.add_setup_ota.value.website !== undefined && this.add_setup_ota.value.website !== "") {

    var property_id = localStorage.getItem("current_property");
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    this.add_setup_ota.patchValue({
      property_id: property_id,
      user_id: user_id,
      type: "Custom",
      commission: 0
    });
    this.submitted = true;
      // if (this.add_setup_ota.invalid) {
      //      Object.keys(this.add_setup_ota.controls).forEach(key => {
      //   const controlErrors: ValidationErrors = this.add_setup_ota.get(key).errors;
      //   if (controlErrors != null) {
      //         Object.keys(controlErrors).forEach(keyError => {
      //         });
      //       }
      //     });
      //   return;
      // }
      this.api.post('api/channels/store', this.add_setup_ota.value, true).subscribe((res: any) => {
        this.helper.alert_success('Successfully Added OTA!');
      this.get_channels();
      $('#add_setup_ota').modal('hide');
      this.add_setup_ota.reset();
      }, err => this.error_handling.handle_error(err.status));

  } else {
    this.helper.alert_error('Please fill in the required fields to connect!');
  }

      
    
  }


  activeMultiplier() {
    if (this.settings_form.value.username !== null && this.settings_form.value.username !== "" &&
      this.settings_form.value.username !== undefined && this.settings_form.value.password !== null && this.settings_form.value.password !== "" &&
      this.settings_form.value.password !== undefined && this.settings_form.value.hotel_code !== null &&
      this.settings_form.value.hotel_code !== undefined && this.settings_form.value.hotel_code !== "") {
      $("#multiplierli").click();
    }
    else {
      this.helper.alert_error('Please fill in the required fields to proceed to next step !');
    }

    // this.onSubmit();
  }
  activePreview() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#previewli").click();
  }
  activeChannel() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#setupchannelli").click();
  }




  activePreviewSetup() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#preview_li").click();
  }
  activeChannelSetup() {
    this.add_setup_ota.patchValue({
      channel_id: this.channel_id,
    });
    $("#setupchannel_li").click();
  }

  activeSetupMultiplier() {
    if (
      this.add_setup_ota.value.username !== null && this.add_setup_ota.value.username !== "" &&
      this.add_setup_ota.value.username !== undefined && 
      
      this.add_setup_ota.value.password !== null && this.add_setup_ota.value.password !== "" &&
      this.add_setup_ota.value.password !== undefined && 

      this.add_setup_ota.value.hotel_code !== null &&
      this.add_setup_ota.value.hotel_code !== undefined && this.add_setup_ota.value.hotel_code !== "" &&

      this.add_setup_ota.value.name !== undefined && this.add_setup_ota.value.name !== null &&
      this.add_setup_ota.value.name !== undefined &&
      
      this.add_setup_ota.value.website !== null &&
      this.add_setup_ota.value.website !== undefined && this.add_setup_ota.value.website !== "") {
      $("#multiplier_li").click();
    }
    else {
      this.helper.alert_error('Please fill in the required fields to proceed to next step !');
    }

     
  }


  imgr(name){
    let a = name.split('.');
    return a[0];
  }
   
}

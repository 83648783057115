import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators,ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { Router } from "@angular/router";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";

declare var $:any;
@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
  users;
  base_url;
  base_url2;
  darkMode:boolean = false;
  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private fb: FormBuilder,
    private router: Router,
    private confirm:ConfirmationAlertService
  ) { 
    this.base_url = this.api.base_url;
    this.base_url2 = this.api.base_url2;
  }

  ngOnInit() {    
      this.get_users();
      this.checkDarkMode();
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
     
    }
  }



  colors = ['#3F51B5', '#9C27B0', '#00ff1f', '#E91E63 ', '#ffb061'];

  get_users(){
    let id = JSON.parse(localStorage.getItem('user')).id;
    
    this.api.post('api/user/index',{admin_id:id} ,true).subscribe((res:any) => {
      this.users = res.body.data;
      this.getHi()
    }, err => this.error_handling.handle_error(err.status, err.message));
  }
  change_status(id) {
    var getuser = this.users.find(x=>x.id===id);
    if(getuser.status=='pending'){
      return;
    }
    var status = getuser.status=='block' ? 'active': getuser.status=='active' ? 'block' : '';
    getuser.status=status;
    this.api.post('api/user/update/'+id,getuser ,true).subscribe((res:any) => {
      if(status==='active'){
        this.helper.alert_success('Activated');
      }
      else if(status==='block'){
        this.helper.alert_success('Locked');
      }
    }, err => this.error_handling.handle_error(err.status, err.message));
    
  }
  delete(id,name) 
  {
    let that = this;
    var user_data = this.users.find(x=>x.id===id);
    this.confirm.confirm(
        "Delete User ("+ name +")", 
        'Are your sure you want to DELETE this User?',
        // 'Created On:'+user_data.createdAt,
        ['This action can not be undone','Deleting this will completely delete this User & Its Details.'],
        function(){
          that.api.get('api/user/destory/'+id ,true).subscribe((res:any) => {
            that.helper.alert_success('User Deleted Successfully....');
            that.get_users();
          }, err => that.error_handling.handle_error(err.status, err.message));
        },
        user_data.permissions,
        user_data.createdAt
    );
  }

  jump(){
    $("#create_users_li a").click();
  }

  edit(id, name){
    this.router.navigate(['/app/back_office/create_users/'+id]);
  }

  getHi(){

    setTimeout(()=>{
    var a = document.getElementById("manageU")
    if(a.offsetHeight > 700){
      a.style.marginRight = '3px'
    }
    else{
      a.style.marginRight = '0px'
    }
    },)
  
  }
}

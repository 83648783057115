import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-setup-restriction',
  templateUrl: './setup-restriction.component.html',
  styleUrls: ['./setup-restriction.component.css']
})
export class SetupRestrictionComponent implements OnInit {
  submitted=false;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private service: ManageRatesPackagesService,
    private router: Router
  ) { }
  
  channels;
  show_channels = [];
  cancelation_channels = [];
  setup_restrictions: FormGroup;
    
  ngOnInit() {
    this.get_channels();
    this.setup_restrictions = this.fb.group({
      min_stay: ['', Validators.required],
      max_stay: ['', Validators.required],
      minimum_age: ['', Validators.required],
      pets: [null, Validators.required],
      pets_extra_amount: ['', Validators.required],
      cancellation_days: ['', Validators.required],
      cancellaction_type: [null, Validators.required],
      cancellation_value: ['', Validators.required],
      cancellation_channels: ['', Validators.required]
    });
  }

  onSubmit(){
    this.setup_restrictions.patchValue({
      cancelation_channels: this.cancelation_channels.toString()
    });
    this.api.post('api/package/update/'+this.service.package_creating, this.setup_restrictions.value,true).subscribe((res:any) => {
      this.helper.alert_success("Restriction has been updated !");
      // this.setup_restrictions.reset()
      this.submitted = false;
      this.service.open_tab('link_to_ota_open');
      this.service.get_packages();
    }, err => this.error_handling.handle_error(err.status));
  }

  increment(inp_name){
    let val = this.setup_restrictions.get(inp_name).value;
    val = val == "" ? 0 : parseInt(val);
    this.setup_restrictions.get(inp_name).setValue((val) + 1);
  }

  decrement(inp_name){
    let val = this.setup_restrictions.get(inp_name).value;
    val = val == "" ? 0 : parseInt(val);
    if(val >= 1){
      this.setup_restrictions.get(inp_name).setValue((val) - 1);
    }
  }

  get_channels(){
    this.api.post('api/channels/index?per_page=50',{} ,true).subscribe((res:any) => {
      // this.channels = res.body.data.filter(function(e){
      //   return e.type == 'External';
      // });
      this.channels = res.body.data;
      this.get_package();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  setChannel(value, event){
    if (event.target.checked) {
      this.cancelation_channels.push(value);
    }else{
      this.cancelation_channels = this.cancelation_channels.filter(e => {
        return e !== value;
      });
    }
  }

  get_package(){
    if(this.service.package_creating !==undefined){
      this.api.get('api/package/show/'+this.service.package_creating,true).subscribe((res:any) => {
          this.setup_restrictions = this.fb.group({
            min_stay: res.body.data.min_stay,
            max_stay:  res.body.data.max_stay,
            minimum_age:  res.body.data.minimum_age,
            pets:  res.body.data.pets,
            pets_extra_amount:  res.body.data.pets_extra_amount,
            cancellation_days: res.body.data.cancellation_days,
            cancellaction_type:  res.body.data.cancellaction_type,
            cancelation_channels: res.body.data.cancelation_channels,
            cancellation_value: res.body.data.cancellation_value,
          });
          this.show_channels = res.body.data.cancel_channels;
          this.show_channels.forEach((res) => {
            this.channels.forEach(element => {
              if(element.id == res.id){
                element.is_checked = true;
              }
            });
            this.cancelation_channels.push(res.id);
          }); 
          this.getHi()
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }


  getHi(){
    setTimeout(()=>{
      var a = document.getElementById("setupRes")
    if(a.offsetHeight < 700){
      a.style.paddingRight = '5px'
    }
    else{
      a.style.paddingRight = '0px'
    }
    },)
  }


}
import { Component, OnInit, HostBinding } from '@angular/core';
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators,ValidationErrors  } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
declare var $: any;
@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css'],
})
export class EmailTemplateComponent implements OnInit {
  @HostBinding('class') colorClass = `${JSON.parse(localStorage.getItem('user')).mode}Mode`;
  email_template: FormGroup;
  notes;
  darkMode:boolean = false;
  submitted:boolean = false;
  edit:boolean = false;
  tempBorder = ['#910601' ,'#ec1361' ,'#1fde39','#0019ff','#8202af','#989cff','#48fff3',  '#ffaa00'];
  listitem;  
  sConfig = {
    placeholder: '',
    tabsize: 2,
    height: 295,
     codemirror: { 
      theme: 'cosmo'
    },
    theme: 'cosmo',
    uploadImagePath: '/api/upload',
    toolbar: [
        ['style', ['bold', 'italic', 'underline']],     
        ['fontsize', ['fontname', 'fontsize']],
        ['para', ['paragraph']],
        ['insert', ['picture', 'link', 'video', 'hr']],
        // ['misc', ['codeview']],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };
  groups = [];
  ide;
  darkModeClass;
  constructor(
    private confirm:ConfirmationAlertService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
    private fb: FormBuilder,
    private helper: HelperService,
  ) { 
  }

  shdule_arr = [
    {booking: 'Booking'},
    {booking: 'Before arrival'},
    {booking: 'In house'},
    {booking: 'Check out'}
  ];

  ngOnInit() {
    this.checkDarkMode();
    this.showTemplate();
    this.getGroups();
    this.createEmailTemplate();
    
    $( "#myModal-4").draggable();
    $( "#myModal-4").draggable('disable');

    $(function() {
      $(".modal").mouseup(function(){
         $(".dargmd").css({"cursor":"grab"})
       });
       $(".modal").mousedown(function(){
         $(".dargmd").css({"cursor":"grabbing"})
       });
    });     
  }

  get f() { return this.email_template.controls; }

  createEmailTemplate(){
    this.email_template = this.fb.group({
      template_type:'External',
      property_id: localStorage.getItem("current_property"),
      user_id: JSON.parse(localStorage.getItem('user')).id,
      email_group_id: [null, Validators.required],
      title: ['', Validators.required],
      heading: 'heding',
      html:['', Validators.required],
      schedule: [null, Validators.required],
      send_type_day: [null, Validators.required],
      number_of_days: ['', Validators.required],
      send_type:''
    });
  }

  getGroups(){
    this.api.post('api/email_template/get_groups',{property_id: localStorage.getItem('current_property')}, true).subscribe((res:any)=>{
      this.groups = res.body.data;
    })
  }

  delete(id,name){
    let that = this;
    this.confirm.confirm(
      "Delete Template ("+ name +")", 
      'Are your sure you want to Delete this Template?',
      ['This action can not be undone','Deleting this will completely delete this Template & Its Details.'],
      function(){
        that.api.get('api/email_template/destory/'+id ,true).subscribe((res:any) => {
          that.helper.alert_success('Template Deleted Successfully....');
          that.showTemplate();
        }, err => that.error_handling.handle_error(err.status, err.message));
      }
  );
   
  }

  dodrag(id){
    $( "#"+id ).draggable('enable');
  }

  dontdrag(id){
    $("#"+id).draggable('disable');
  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  getId(id){
    this.ide = id
  }


  getTemplate(data){
    if(data == 'new'){
      this.email_template.patchValue({html: this.notes});
      this.submitted = true;
    if(this.email_template.invalid){
      return;
    }
    this.api.post('api/email_template/store',this.email_template.value,true).subscribe((res: any) => {
      this.showTemplate(); 
      this.onClose()
      this.helper.alert_success('Template created successfuly.')
    }, err => this.error_handling.handle_error(err.status, err.message));
    }
    else if(data == 'editForm'){
     this.email_template.patchValue({html: this.notes});
      this.api.post('api/email_template/update/'+this.ide,this.email_template.value,true).subscribe((res:any)=>{
        this.showTemplate();
        this.submitted = false;
        this.helper.alert_success('Template updated successfuly.')
      },err => this.error_handling.handle_error(err.status, err.message));
    }
    else{
      this.edit = true
      this.email_template.patchValue(data);
      this.notes = data.html;
    }
    $('#myModal-4').modal('hide');
  }

  showTemplate(){
    let property_id = localStorage.getItem("current_property");
		this.api.post('api/email_template/index', {property_id: property_id}, true).subscribe((res: any) => {     
      this.listitem = res.body.data;     
    }, err => this.error_handling.handle_error(err.status));
  }

  editTemplate(temp){
   
    this.email_template.patchValue(temp);
    this.notes = temp.html;
    
  }

  onClose(){
    this.email_template.reset()
    this.submitted = false
    this.createEmailTemplate()
    this.notes = ''
  }

}

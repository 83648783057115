import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
import { SearchPipe } from "../../../common/searchpipe";
import { RecursiveSearchPipe } from "../../../common/recursivepipe";
import { ManageRatesPackagesComponent } from "../manage-rates-packages.component";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
// import {MatDatepickerModule} from '@angular/material/datepicker';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-setup-package',
  templateUrl: './setup-package.component.html',
  styleUrls: ['./setup-package.component.css'],
  providers: [ManageRatesPackagesComponent],
})
export class SetupPackageComponent implements OnInit {
  create_package_form: FormGroup;
  submitted = false;
  open_custom = false;
  base_url;
  rooms;
  roomtypes;
  minDate = new Date(2019, 0, 1); //minDate is 1st Jan 2019
  minDate2 = new Date(2019, 0, 1);
  maxDate = new Date(2020, 0, 1); //maxDate is 1st Jan 2020
  selectedRoom;
  testvar;
  edit = false;

  date;
  date_to;
  date_from;

  upsells=[];
  upsel_item;
  upsel_value;
  colors = ['#4db7d8', '#059800', '#6b4020', '#c7a563', '#2c2d99', '#73f199', '#ff51f3', '#fd7600', '#5624ff'];
  colors2 = ['#000000', '#a4647b', '#ff6f00', '#b3bf00', '#9a00ff', '#5195b4', '#910b00', '#ff45ec', '#00b59c'];
  upsell_items = [
    {name: 'Parking', value: 'parking'},
    {name: 'Pool', value: 'pool'},
    {name: 'Spa', value: 'spa'},
    {name: 'Room Upgrade', value: 'room_upgrade'},
    {name: 'Wifi', value: 'wifi'},
    {name: 'Conference Facilities', value: 'conference_facilities'},
    {name: 'Sofa Bed', value: 'sofa_bed'},
    {name: 'Day Bed', value: 'day_bed'},
    {name: 'Futon', value: 'futon'},
    {name: 'Rollaway', value: 'rollaway'},
    {name: 'Cribs/Infant Bed', value: 'cribs_infant_bed'},
    {name: 'Business Center', value: 'business_center'},
    {name: "Enter your own custom upsell",value: "open_custom"}
  ];

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private fb: FormBuilder,
    private router: Router,
    private confirm: ConfirmationAlertService,
    private searchpipe: SearchPipe,
    private nestedSearch: RecursiveSearchPipe,
    private manageRates: ManageRatesPackagesComponent,
    private service: ManageRatesPackagesService,
    private Aroute: ActivatedRoute
  ) {
    this.Aroute.params.subscribe(val => {
      this.get_package();
    });
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.base_url = this.api.base_url;
  }

  ngOnInit() {
    this.get_rooms();
    this.create_package_form = this.fb.group({
      property_id: '',
      package_name: ['', Validators.required],
      date: ['', Validators.required],
      date_from: ['', Validators.required],
      date_to: ['', Validators.required],
      room_type_id: [null, Validators.pattern("[0-9]{0,10}")],
      description: ['', Validators.required],
      breakfast_include: ['', Validators.required],
      room_id: '',
      upsel_item: null,
      upsel_value: '',
      upsell: '',
      custom_room_type:'',
    });
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();
    this.minDate = new Date(today.toLocaleDateString("en-US", options));
    this.minDate2 = new Date(today.toLocaleDateString("en-US", options));
    this.minDate2.setDate(this.minDate2.getDate() + 1);
    this.get_package();
    this.f['upsel_item'].valueChanges.subscribe((res) => {
      if(res !=undefined && res !='' && res=='open_custom'){
        // this.create_package_form.value.upsel_item = res == 'open_custom' ? 'open_custom' : res;
        this.on_sec_change();    
      }
  });
  }
  get f() { return this.create_package_form.controls; }
  on_sec_change(){
    setTimeout(() => {
      let que = this.create_package_form.value.upsel_item;
      if(que == 'open_custom') {
     
        this.open_custom = true;
        this.create_package_form.value.upsel_item =null;
        this.upsel_item=null;
        setTimeout(() => {
          $("#sec_custom_room_type").val(null);
        }, 100);
        
        // let inputValue = (document.getElementById("sec_custom_room_type") as HTMLInputElement).value='';
        
        // (document.getElementsByTagName('sec_custom_room_type' )  as HTMLInputElement).value='';
    }
    }, 500);
  }
  resetOptions() 
  {
    this.create_package_form.value.upsel_item=null;
    this.open_custom = false;
    this.upsel_item=null;
    // this.upsell_items = this.upsell_items;
  }
  onSubmit() {
    this.submitted = true;
    Object.keys(this.create_package_form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.create_package_form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });
    if (this.create_package_form.invalid) {
      return;
    }
    var property_id = localStorage.getItem("current_property");
    this.create_package_form.patchValue({ property_id: property_id, room_id: this.selectedRoom.id, upsell: JSON.stringify(this.upsells)});
    if (this.edit == true) {
      this.updatePackage(this.service.package_creating);
      return;
    }
    this.api.post('api/package/store', this.create_package_form.value, true).subscribe((res: any) => {
      this.service.package_creating = res.body.data.id;
      this.service.get_packages();
      this.submitted = false;
      this.selectedRoom = '';
      this.create_package_form.reset();
      this.helper.alert_success('Package has been created successfully !');
      this.service.open_tab('setup_rate_open');
    }, err => this.error_handling.handle_error(err.status));

  }
  get_rooms() {
    this.api.post('api/roomtype/index?per_page=50', {}, true).subscribe((res: any) => {
      this.roomtypes = res.body.data;
      this.getHi()
    }, err => this.error_handling.handle_error(err.status));
  }

  getSelectedRoom(id) {
    this.api.get('api/room/room_by_roomtype/' + id.id, true).subscribe((res: any) => {
      this.selectedRoom = res.body.data;
      this.getHi()
    }, err => this.error_handling.handle_error(err.status));
  }

  get_package() {
    if (this.service.package_creating !== undefined && this.service.package_creating !== '') {
      this.api.get('api/package/show/' + this.service.package_creating, true).subscribe((res: any) => {
        this.create_package_form.patchValue({
          property_id: res.body.data.property_id,
          package_name: res.body.data.package_name,
          date_from: res.body.data.date_from,
          date_to: res.body.data.date_to,
          room_type_id: res.body.data.room_type_id,
          description: res.body.data.description,
          breakfast_include: res.body.data.breakfast_include,
          room_id: res.body.data.room_id,
        });
        this.minDate = res.body.data.date_from;
        this.maxDate = res.body.data.date_from;
        this.edit = true;
        this.date = {startDate: moment(res.body.data.date_from), endDate: moment(res.body.data.date_to) };
        // this.date = {startDate: moment().subtract(3, 'days'), endDate: moment().add(3, 'days')};
        this.date_from = res.body.data.date_from;
        this.date_to = res.body.data.date_to;
        this.upsells = JSON.parse(res.body.data.upsell) || [];
        this.upsells.forEach(sel => {
          this.upsell_items = this.upsell_items.filter(el => { 
            return sel.name != el.value; 
          });
        });
        this.api.get('api/room/room_by_roomtype/' + res.body.data.room_type_id, true).subscribe((res: any) => {
          this.selectedRoom = res.body.data;
          this.getHi()
        }, err => this.error_handling.handle_error(err.status));
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }
  updatePackage(id) {
    this.api.post('api/package/update/' + id, this.create_package_form.value, true).subscribe((res: any) => {
      this.service.get_packages();
      this.getHi()
      this.helper.alert_success('Package has been updated successfully !');
    }, err => this.error_handling.handle_error(err.status));
  }
  
  onClose() {
    this.service.package_creating = '';
    this.selectedRoom = '';
    this.create_package_form.reset();
  }

  add_upsell(item, val){
    if(item != '' && val != ''){
      this.upsel_item = null;
      this.upsel_value = '';
      this.upsells.push({name: item, value: val});
      this.upsell_items = this.upsell_items.filter(el => { 
        return item != el.value; 
      });
      // this.upsells.push({name: 'tte', value: 'test'});
    }
  }

  remove_upsell(i){
    this.upsells = this.upsells.filter(e => { return e.name != i });
  }

  choose_date(e){
    if(e.startDate != null && e.endDate != null){
      this.date_to = moment(e.startDate._d).format('DD/MM/YYYY');
      this.date_from = moment(e.endDate._d).format('DD/MM/YYYY');
    }
  }
  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getHi(){

    setTimeout(()=>{
      let a = document.getElementById("createPackageDiv")

      if(a.offsetHeight < 700){
        a.style.width = '100.4%'
      }
      else{
        a.style.width = '100%'
      }

    },100)

  }


}
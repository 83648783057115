import { EventsService } from "src/app/services/events.service";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ConfirmationAlertService } from "../../common/confirmation-alert/confirmation-alert.service";
import * as jsPDF from "jspdf";
// import html2canvas from 'html2canvas';
import * as html2canvas from "html2canvas";
import * as moment from "moment";
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormArray,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { HelperService } from "../../services/helper.service";
import { ToastrService } from "ngx-toastr";
// import { ModalComponent } from 'your-path-to-modal-component';
// import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

import { iif } from "rxjs";
declare var $: any;
@Component({
  selector: "app-arrivals",
  templateUrl: "./arrivals.component.html",
  styleUrls: ["./arrivals.component.css"],
})
export class ArrivalsComponent implements OnInit {
  @ViewChild("screen", { static: false }) screen: ElementRef;
  @ViewChild("canvas", { static: false }) canvas: ElementRef;
  @ViewChild("downloadLink", { static: false }) downloadLink: ElementRef;

  constructor(
    private confirm: ConfirmationAlertService,
    private api: ApiService,
    private toastr: ToastrService,
    private helper: HelperService,
    public events: EventsService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder
  ) {
    this.events.receiveDarkMode().subscribe((res: any) => {
      this.darkMode = res.darkMode;
    });

    this.events.receiveLogo().subscribe((res: any) => {
      this.logo = res;
    });
    this.base_url2 = this.api.base_url2;

    this.base_url2 = this.api.base_url2;
  }

  i;
  // today_bookings;
  my_statusb;
  base_url2;
  bnb = 0;
  TaxInclusivee;
  todayDate;
  current_season_id;
  room_name;
  urrent_season;
  current_arrival;
  my_object;
  my_channel;
  paynow_invoice: any;
  user = JSON.parse(localStorage.getItem("user"));
  Discounts = "false";
  paynow_dis = 0;
  my_status;
  extra_person_fee;
  TaxInclusive;
  invoice;
  selected_range;
  filter;
  taxper;
  param;
  date;
  roomtypes;
  extra_price = [];
  subtotal: any = 0;
  rooms;
  selected0;
  ex = 0;
  ext = false;
  package_name;
  price_json;
  accessible;
  room_no_id;
  pets;
  Tax;
  room_no_id_data;
  room_no_id_dataa;
  room_num;
  room_id;
  room_nm;
  states;
  rrn;
  elmnt;
  room_number;
  all;
  guest_phone;
  id_type;
  id_value;
  date_depart;
  aimin;
  que;
  d;
  e;
  selectedMembers;
  selectedMembers2;
  sortedActivities1;
  sortedActivities2;
  lastP;
  z;
  allPcount;
  Complimentry;
  Paid;
  arrivalspagi;
  innerWidth;
  lastpage;
  lastpageNm;
  resWidth;
  allPackages;
  panin;
  resI;
  allPackages2;
  totalNights;
  DateDeparture;
  ten = 10;
  ifor = 0;
  selectedIndex = 0;
  s_f = [];
  allPromo = [];
  channels = [];
  arrivals = [];
  lastweek = [];
  lastweek2 = [];
  arrivals2 = [];
  pagi = [];
  pricesArray = [];
  inventory_calendar = [];
  weather_update = [];
  pro = [];
  my_object_form: FormGroup;
  edit_form: FormGroup;
  reservation_form: FormGroup;
  checked_out_guestform: FormGroup;
  config: any;
  query: any;
  allChannels: any;
  promoDiscount = null;
  disabled: any = true;
  chkchk: boolean = false;
  chkchk2: boolean = false;
  c_con: boolean = false;
  darkMode: boolean = false;
  showsmoke: boolean = true;
  showwheel: boolean = true;
  showpets: boolean = true;
  roomFilter: boolean = false;
  viewDate: boolean = false;
  exte: boolean = true;
  applyChkOutDate: boolean = false;
  segmonLeft: boolean = true;
  segmonoLeft: boolean = true;
  logo;
  restriction: boolean = true;

  toolChkData;
  comaArrayRate = [];
  seperA;

  expenceTotalPrice = [];

  profile_picture = JSON.parse(localStorage.getItem("user")).profile_picture;

  WHcolors = [
    "#ffc107",
    "#ec1361",
    "#09fb3d",
    "#ff51f3",
    "#48fff3",
    "#9a00ff",
    "#ff6f00",
  ];
  current_property = localStorage.getItem("current_property");
  rtype0 = [
    {
      room_name: "Double Deluxe",
    },
    {
      room_name: "King Size",
    },
    {
      room_name: "Quade Ultra",
    },
  ];
  status = [
    {
      name: "Paid",
      s_value: "paid",
      id: 1,
    },
    // {
    // 	name:'Unpaid',
    // 	s_value: 'unpaid',
    // 	id: 2,
    // },
    {
      name: "Paynow",
      s_value: "paynow",
      id: 4,
    },
    {
      name: "All",
      s_value: "All",
      id: 101,
    },
  ];
  bstatus = [
    {
      name: "Hold",
      label: "hold",
    },
    {
      name: "Cancelled",
      label: "cancelled",
    },
    {
      name: "Confirm",
      label: "confirm",
    },
    {
      name: "Modified",
      label: "modified",
    },
  ];
  checked_in;
  today_arrival;
  emailSend: boolean = true;
  invoiceSend: boolean = true;
  whatsAppSend: boolean = true;
  todaysDate = new Date();
  bookingId = [];
  checkoutdata: any;
  paynowPopup: any;
  paymentPaid: boolean = false;
  filterBy = null;
  minDate: moment.Moment = moment().subtract(0, "days");
  maxDate: moment.Moment = moment().add(2, "month");
  submittedCheckOut: boolean = false;
  allExpenses: any;
  expensePrice;
  expense_person;
  expense_person_price;
  expenseVal;
  bookingStatusMod: boolean = false;
  observeRoomChanges: any;
  perDayrates: any;
  showCancellOnPay: boolean = true;
  submittedUpodated: boolean = false;
  randomColors = [];

  ngOnInit() {
    this.EditReservForm("");
    this.checkDarkMode();
    this.getExpenses();
    this.permissions();
    console.log("arr");
    $(window).on("scroll", function () {
      var $w = $(window);
      $(".position-fixed-x").css("left", $w.scrollLeft());
    });
    // drag function code--------------------
    $(function () {
      $("#drag-list").dragsort({
        placeHolderTemplate:
          "<div class='placeHolder' style=''>" +
          "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 70px;margin-bottom:5px;'></div>" +
          "</div>",
        cursor: "move",
      });
      $(".card").mouseup(function () {
        $(".border-div").css({ cursor: "grab" });
      });
      $(".card").mousedown(function () {
        $(".border-div").css({ cursor: "grabbing" });
      });
    });
    // drag function end-------------------
    $(function () {
      $("#list1").sortable({});
    });
    $(function () {
      $("#list2").sortable({});
    });
    $(function () {
      $("#list3").sortable({});
    });
    $(function () {
      $("#list4").sortable({});
    });
    $(function () {
      $("[data-toggle='tooltip']").tooltip();
    });
    // $("#myModal").mouseup(function(){
    //   $("#grab-4").css({"cursor":"grab"})
    // });
    // $("#myModal").mousedown(function(){
    //   $("#grab-4").css({"cursor":"grabbing"})
    // });
    this.get_arrivals();
    this.get_channels();
    this.rest0();
    this.get_rooms();
    this.getseason();
    this.getWeather();

    $(function () {
      $(".modal").mouseup(function () {
        $(".bluheader").css({ cursor: "grab" });
      });
      $(".modal").mousedown(function () {
        $(".bluheader").css({ cursor: "grabbing" });
      });

      $(".modal").mousedown(function () {
        $(".dargmd").css({ cursor: "grabbing" });
      });

      $("#payNow").draggable();
      $("#payNow").draggable("disable");

      $("#payNowGuest").draggable();
      $("#payNowGuest").draggable("disable");

      $("#summary").draggable();
      $("#summary").draggable("disable");

      $("#myModal").draggable();
      $("#myModal").draggable("disable");
    });
    this.innerWidth = window.innerWidth;
    this.resWidth = window.innerWidth;
  }

  get_arrivals() {
    this.pagi = [];
    var property_id = localStorage.getItem("current_property");
    this.api
      .post("api/booking/arrivals", { property_id: property_id }, true)
      .subscribe(
        (res: any) => {
          this.arrivals = res.body.data.all.data;
          this.arrivalspagi = res.body.data.all.total;
          this.lastP = res.body.data.all.last_page;
          for (let i = 1; i <= res.body.data.all.last_page; i++) {
            this.pagi.push({
              page_no: i,
              url: "http://cm.com/pmsbackend/public/api/booking/arrivals?page=",
            });
          }
          this.lastpage = this.pagi[this.pagi.length - 1];
          this.lastpageNm = this.lastpage.page_no;
          this.panin = this.pagi.length;
          this.arrivals2 = res.body.data.all.data;
          this.lastweek = res.body.data.departure.data;
          this.lastweek2 = res.body.data.departure.data;
          this.checked_in = res.body.data.check_ins.data;
          this.today_arrival = res.body.data.today_bookings.data;
          for (let i = 0; i <= 50; i++) {
            this.randomColors.push(
              `#${Math.floor(Math.random() * 16777215).toString(16)}`
            );
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  chkbox() {
    if (this.c_con == false) {
      this.c_con = true;
    } else {
      this.c_con = false;
    }
  }

  dodrag(id) {
    $("#" + id).draggable("enable");
  }

  dontdrag(id) {
    $("#" + id).draggable("disable");
  }

  dodragg(id) {
    $("#myModal").draggable("enable");
  }

  stopDragg(id) {
    $("#myModal").draggable("disable");
  }

  get_channels() {
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/channels/index?per_page=50",
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.channels = res.body.data;
          this.channels.push({
            commission: "0",
            connected: false,
            createdAt: "2019-09-19 10:41:11",
            id: 69696969696969696969696,
            image: "all.png",
            name: "All",
            package_count: 0,
            pr_status: null,
            property_channel_id: false,
            status: false,
            type: "Internal",
            updatedAt: "2019-09-19 10:41:11",
          });
          this.allChannels = [];
          res.body.data.map((val) => {
            if (val.type == "Internal" || val.type == "Custom") {
              this.allChannels.push(val);
            }
          });
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  reset0() {
    this.arrivals = this.arrivals2;
    this.lastweek = this.lastweek2;
    this.query = "";
    this.param = undefined;
    this.sortedActivities1 = "";
    this.sortedActivities2 = "";
  }

  adte(range) {
    if (range.startDate != null) {
      let sdy = new Date(range.startDate._d).getFullYear();
      let sdm = new Date(range.startDate._d).getMonth() + 1;
      let sdd = new Date(range.startDate._d).getDate();
      let sfd =
        sdy +
        "-" +
        (sdm < 10 ? "0" + sdm : sdm) +
        "-" +
        (sdd < 10 ? "0" + sdd : sdd);
      let edy = new Date(range.endDate._d).getFullYear();
      let edm = new Date(range.endDate._d).getMonth() + 1;
      let edd = new Date(range.endDate._d).getDate();
      let efd =
        edy +
        "-" +
        (edm < 10 ? "0" + edm : edm) +
        "-" +
        (edd < 10 ? "0" + edd : edd);
      this.d = sfd;
      this.e = efd;
      this.param = this.d;

      let startDate = this.d;
      let endDate = this.e;
      this.selectedMembers = this.arrivals.filter(
        (m) =>
          new Date(m.date_arrival) >= new Date(startDate) &&
          new Date(m.date_arrival) <= new Date(endDate)
      );

      this.selectedMembers2 = this.lastweek.filter(
        (m) =>
          new Date(m.date_arrival) >= new Date(startDate) &&
          new Date(m.date_arrival) <= new Date(endDate)
      );
      this.sortedActivities1 = this.selectedMembers.sort(
        (a, b) => b.date_arrival - a.date_arrival
      );
      let b = this.sortedActivities1.sort((val) => {
        return new Date(val.date_arrival);
      });
      this.sortedActivities2 = this.selectedMembers2.sort(
        (a, b) => b.date_arrival - a.date_arrival
      );
      let c = this.sortedActivities2.sort((val) => {
        return new Date(val.date_arrival);
      });

      this.arrivals = this.arrivals2;
      this.lastweek = this.lastweek2;
      this.selectedMembers.map((sval) => {
        this.arrivals = this.arrivals.filter(function (obj) {
          return obj.date_arrival !== sval.date_arrival;
        });
      });
      this.selectedMembers2.map((stval) => {
        this.lastweek = this.lastweek.filter(function (obj) {
          return obj.date_arrival !== stval.date_arrival;
        });
      });
    }
    if (this.query != "") {
      if (range.startDate != null) {
        let sdy = new Date(range.startDate._d).getFullYear();
        let sdm = new Date(range.startDate._d).getMonth() + 1;
        let sdd = new Date(range.startDate._d).getDate();
        let sfd =
          sdy +
          "-" +
          (sdm < 10 ? "0" + sdm : sdm) +
          "-" +
          (sdd < 10 ? "0" + sdd : sdd);
        let edy = new Date(range.endDate._d).getFullYear();
        let edm = new Date(range.endDate._d).getMonth() + 1;
        let edd = new Date(range.endDate._d).getDate();
        let efd =
          edy +
          "-" +
          (edm < 10 ? "0" + edm : edm) +
          "-" +
          (edd < 10 ? "0" + edd : edd);
        this.d = sfd;
        this.e = efd;
        this.param = this.d;

        var startDate = this.d;
        var endDate = this.e;
      }
      var property_id = localStorage.getItem("current_property");
      this.api
        .post(
          "api/booking/filter",
          {
            property_id: property_id,
            guest_firstname: this.query,
            startDate: startDate,
            endDate: endDate,
          },
          true
        )
        .subscribe(
          (res: any) => {
            this.arrivals = res.body.data;
          },
          (err) => this.error_handling.handle_error(err.status)
        );
    }
  }

  adte2(range) {
    let d = new Date(range.startDate._d).toISOString().split("T");
    moment().format("YYYY [escaped] YYYY");
    let f_date = d[0].split("-");
    let fd = f_date[0] + "-" + f_date[1] + "-";
    let f = parseInt(f_date[2]) + 1;
    this.aimin = fd + (f < 10 ? "0" + f : f);
  }

  rest0() {
    if (this.my_status == "All") {
      this.arrivals = this.arrivals2;
      this.lastweek = this.lastweek2;
      this.query = "";
      this.param = undefined;
    }
  }

  viewBtn() {
    let a;
    let langArr = <FormArray>(
      this.reservation_form.get("booking_items")["controls"]
    );
    this.disabled = false;
    this.allPromo.map((val) => {
      if (val.promo_id == this.reservation_form.value.promo) {
        a = val;
      }
    });
    this.getPromo(a, "false");
    this.reservation_form.patchValue({
      guest_card: this.my_object.guest_card,
    });
    this.reservation_form.value.booking_items.forEach((el, i) => {
      langArr[i].patchValue({
        card_no: this.my_object.bookingitems[i].card_no,
      });
    });
  }

  resetModal() {
    this.disabled = true;
    this.roomFilter = false;
    (<HTMLInputElement>document.getElementById("sideBarDetail")).style.right =
      "0px";
  }

  async modal_preview(m_id) {
    this.my_object = m_id;
    this.observeRoomChanges = m_id;
    this.bookingId = [];
    this.inventory_calendar = [];
    this.perDayrates = [];
    this.TaxInclusivee = m_id.TaxInclusive
    await setTimeout(() => {
      m_id.bookingitems.map((val, i) => {
        this.getroomnumber(val.room_id, i, m_id.id);
        this.addNewGuest(this.reservation_form.controls.booking_items, val);
        setTimeout(() => {
          this.chckChckbox(i, val.breakfast);
          let langArr = <FormArray>(
            this.reservation_form.controls["booking_items"]
          );
          langArr.controls[i].patchValue({
            check_in: {
              startDate: moment(m_id.bookingitems[i].check_in),
              endDate: moment(m_id.bookingitems[i].check_in),
            },
            check_out: {
              startDate: moment(m_id.bookingitems[i].check_out),
              endDate: moment(m_id.bookingitems[i].check_out),
            },
          });
        }, 1000);
        setTimeout(() => {
          this.map_boxes("false");
          let statusId = <HTMLInputElement>(
            document.getElementById("Dnoneo" + i)
          );
          if (m_id.status != "cancelled") {
            if (val.check_in == this.todayDate && val.status != "Checked Out") {
              statusId.classList.remove("Pnone");
            } else {
              statusId.classList.add("Pnone");
            }
          } else {
            statusId.classList.add("Pnone");
          }
        }, 1100);
      });
      this.changeSegment(m_id.TaxInclusive, "segmon");
    }, 100);

    this.reservation_form.reset();

    this.reservation_form.controls.booking_items = this.fb.array([]);
    await this.EditReservForm(m_id);
    this.chk_chk();
    this.getChannelId(m_id.channel_id);
    this.fliBox();
    this.nightsTotal(
      this.my_object.date_departure,
      this.my_object.date_arrival
    );
    this.my_object.bookingitems.map((val, i) => {
      this.getroomtype(
        val.room.smoking,
        val.room.wheelchairaccessibility,
        val.room.pets,
        this.urrent_season.date_to,
        this.urrent_season.date_from
      );
    });
    $(function () {
      $("#formSum").draggable();
      $("#formSum").draggable("disable");
    });
    if (
      this.my_object.bookingitems[0].check_in === null ||
      this.my_object.bookingitems[0].check_in == "Invalid date"
    ) {
      this.viewDate = false;
    } else {
      this.viewDate = true;
    }
    if (m_id.guest_card != null) {
      await this.reservation_form.patchValue({
        guest_card: `********${m_id.guest_card.slice(
          m_id.guest_card.length - 4
        )}`,
      });
    }
    await this.getRandomColor();
  }

  addNewGuest(control, data) {
    this.room_no_id_data = data.room_no_id;

    let cardNo;
    if (data.card_no != null) {
      cardNo = `********${data.card_no.slice(data.card_no.length - 4)}`;
    } else {
      cardNo = data.card_no;
    }
    this.bnb = 0;
    this.bnb  = data.fb_price,
    // this.room_number = data.roomnum;
    control.push(
      this.fb.group({
        guest_name: [data.guest_name, Validators.required],
        room_id: [data.room_id, Validators.required],
        room_no_id: [data.room_no_id, Validators.required],
        guest_id: data.guest_id,
        package_id: data.package_id,
        season_id: data.season_id,
        id: data.id,
       
        prices: data.prices,
        promotion_id: data.promotion_id,
        no_of_adults: [data.no_of_adults, Validators.required],
        no_of_childs: [data.no_of_childs, Validators.required],
        no_of_infants: [data.no_of_infants, Validators.required],
        breakfast: [data.breakfast, Validators.required],
        check_in: [
          { startDate: moment(data.check_in), endDate: moment(data.check_in) },
          Validators.required,
        ],
        check_out: [
          {
            startDate: moment(data.check_out),
            endDate: moment(data.check_out),
          },
          Validators.required,
        ],
        status: data.status,
        check_in_time: null,
        check_out_time: null,
        pricesjson: data.pricesjson,
        card_no: cardNo,
        card_expiry: data.card_expiry,
        card_cvv: data.card_cvv,
        checkIn_card_type: data.guest_card_typee,
        id_type: data.id_type,
        id_value: data.id_value,
        email: data.email,
        phone_number: data.phone_number,
      })
    );
  }

  makePDF() {
    this.exte = false;
    setTimeout(() => {
      const options = {
        background: "#f1f1f1",
        scale: 2,
        pagebreak: {
          mode: "avoid",
        },
      };
      html2canvas(document.querySelector(".capture"), options).then(
        (canvas, svg) => {
          var imgData = canvas.toDataURL("image/png");
          var imgWidth = 209;
          var pageHeight = 300;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF("p", "mm", "a4", true);
          var position = 5;
          doc.addImage(imgData, "JPEG", 0.5, 55, imgWidth, imgHeight - 20);
          heightLeft -= pageHeight;
          while (heightLeft >= 0) {
            position += heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "JPEG", 5, 4, imgWidth, imgHeight - 20);
            heightLeft -= pageHeight;
          }
          doc.save("Arrivals.pdf");
          this.exte = true;
        }
      );
    }, 1000);
  }

  get_rooms() {
    var property_id = localStorage.getItem("current_property");
    if(property_id  >  '0'){
    this.api
      .post("api/room/index", { property_id: property_id }, true)
      .subscribe(
        (res: any) => {
          this.rooms = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
    }
  }

  get_room_nm($event) {
    var id;
    if (typeof $event == "object") {
      id = $event.room_type_id;
    }
    let roomNo = this.rooms.find((x) => x.room_type_id === id);
    this.rrn = roomNo.room_num;
  }

  getIdBookin(id, i, data) {
   
    this.bookingId.push({ ide: id, index: i });
  }

  async onSubmit() {
    this.submittedUpodated = true;
    var regexp =
      /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;

    let currentTime = new Date().toLocaleTimeString();
    if (this.reservation_form.value.date_arrival.endDate != undefined) {
      this.reservation_form.patchValue({
        date_arrival: moment(
          this.reservation_form.value.date_arrival.endDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    if (this.reservation_form.value.date_departure.endDate != undefined) {
      this.reservation_form.patchValue({
        date_departure: moment(
          this.reservation_form.value.date_departure.endDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    let langArr = <FormArray>(
      this.reservation_form.get("booking_items")["controls"]
    );
    this.reservation_form.value.booking_items.map((val, i) => {
      if (
        this.reservation_form.value.booking_items[i].check_in.endDate !=
        undefined
      ) {
        langArr[i].patchValue({
          check_in: moment(
            this.reservation_form.value.booking_items[i].check_in.endDate._d
          ).format("YYYY-MM-DD"),
        });
      }
      if (
        this.reservation_form.value.booking_items[i].check_out.endDate !=
        undefined
      ) {
        langArr[i].patchValue({
          check_out: moment(
            this.reservation_form.value.booking_items[i].check_out.endDate._d
          ).format("YYYY-MM-DD"),
        });
      }
      if (val.status == "Checked In") {
        this.bookingId.map((value) => {
          langArr[value.index].patchValue({
            check_in_time: currentTime,
          });
        });
      } else {
        langArr[i].patchValue({
          check_in_time: null,
        });
      }
      if (
        val.status == "Checked In" &&
        this.reservation_form.value.status == "hold"
      ) {
        this.reservation_form.patchValue({
          // payment_status:'paynow',
          status: "confirm",
        });
      }
    });
    if (this.segmonLeft) {
      this.reservation_form.patchValue({ TaxInclusive: "true" });
    } else if (!this.segmonLeft) {
      this.reservation_form.patchValue({ TaxInclusive: "false" });
    }
    await this.reservation_form.value.booking_items.forEach(
      (element, index) => {
        this.observeRoomChanges.bookingitems.forEach((el) => {
          if (element.room_id != el.room_id && element.status == "Checked In") {
            this.reservation_form.patchValue({
              status: "modified",
            });
          }
          if (
            element.room_no_id != el.room_no_id &&
            element.status == "Checked In"
          ) {
            this.reservation_form.patchValue({
              status: "modified",
            });
          }
        });
        if (element.status == "Checked In") {
          langArr[index]
            .get("card_no")
            .setValidators([
              Validators.required,
              Validators.pattern(
                "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
              ),
            ]);
          langArr[index].get("card_expiry").setValidators(Validators.required);
          langArr[index].get("card_cvv").setValidators(Validators.required);

          langArr[index].get("id_type").setValidators(Validators.required);
          langArr[index].get("id_value").setValidators(Validators.required);
          langArr[index].get("email").setValidators(Validators.required);
          langArr[index].get("phone_number").setValidators(Validators.required);
        } else {
          langArr[index].get("card_no").clearValidators();
          langArr[index].get("card_expiry").clearValidators();
          langArr[index].get("card_cvv").clearValidators();

          langArr[index].get("id_type").clearValidators();
          langArr[index].get("id_value").clearValidators();
          langArr[index].get("email").clearValidators();
          langArr[index].get("phone_number").clearValidators();
        }
        langArr[index].get("card_no").updateValueAndValidity();
        langArr[index].get("card_expiry").updateValueAndValidity();
        langArr[index].get("card_cvv").updateValueAndValidity();

        langArr[index].get("id_type").updateValueAndValidity();
        langArr[index].get("id_value").updateValueAndValidity();
        langArr[index].get("email").updateValueAndValidity();
        langArr[index].get("phone_number").updateValueAndValidity();
      }
    );
    if (this.reservation_form.invalid) {
      console.log(this.b);
      this.findInvalidControls();

      return;
    }
    console.log(this.reservation_form.value);
    await this.api
      .post("api/booking/update/" + this.my_object.id,this.reservation_form.value,true).subscribe((res: any) => {
          this.get_arrivals();
          this.helper.alert_success("Booking Update Successfully");
          this.submittedUpodated = false;
        },(err) => this.error_handling.handle_error(err.status)
      );
  }

  get b() {
    return this.reservation_form.controls;
  }

  pNumber() {
    var a = <HTMLInputElement>document.getElementById("c-number");
    a.type = "text";
  }

  nightStay(date_arrival, date_departure) {
    let start = moment(new Date(date_arrival).toISOString());
    let end = moment(new Date(date_departure).toISOString());
    return end.diff(start, "days");
  }

  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
    }
  }

  statusfilter(s, con) {
    this.sortedActivities1 = "";
    this.sortedActivities2 = "";
    if (s == "All") {
      s = localStorage.getItem("current_property");
      this.param = localStorage.getItem("current_property");
    } else {
      this.param = s;
    }
    this.arrivals = this.arrivals2;
    if (con == "payment") {
      this.arrivals = this.arrivals.filter(function (obj) {
        return obj.payment_status !== s;
      });
    } else if (con == "status") {
      this.arrivals = this.arrivals.filter(function (obj) {
        return obj.status !== s;
      });
    }
  }

  channelfilter(c) {
    if (c == "All") {
      c = localStorage.getItem("current_property");
      this.param = "all";
    } else {
      this.param = c;
      console.log(this.param);
    }
    this.sortedActivities1 = "";
    this.sortedActivities2 = "";
    this.arrivals = this.arrivals2;
    this.arrivals = this.arrivals.filter(function (obj) {
      return obj.channel.name !== c;
    });
  }

  paginationPage(url, pn) {
    this.scCustom9(pn);
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + pn,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.arrivals = res.body.data.all.data;
          this.arrivals2 = res.body.data.all.data;
          this.allPcount = res.body.data.all.current_page;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  first_page(one) {
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + one,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.arrivals = res.body.data.all.data;
          this.arrivals2 = res.body.data.all.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    var elmnt = document.getElementById("cuspagidiv");
    elmnt.scrollLeft -= 100000;
  }

  last_page() {
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + this.lastP,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.arrivals = res.body.data.all.data;
          this.arrivals2 = res.body.data.all.data;
          this.active1();
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    var elmnt = document.getElementById("cuspagidiv");
    elmnt.scrollLeft = 100000;
  }

  paginaviHide() {
    var a = document.getElementById("paginav");
    a.style.display = "none";
  }

  paginavishow() {
    var a = document.getElementById("paginav");
    a.style.display = "block";
  }

  querue(a) {
    this.arrivals = this.arrivals2;
    this.lastweek = this.lastweek2;
    this.arrivals = this.arrivals.filter(function (obj) {
      return obj.guest_firstname.toLowerCase() !== a.toLowerCase();
    });
    this.lastweek = this.lastweek.filter(function (obj) {
      return obj.guest_name.toLowerCase() !== a.toLowerCase();
    });

    this.arrivals = this.arrivals.filter(function (obj) {
      return obj.reservation_id !== a;
    });
    this.lastweek = this.lastweek.filter(function (obj) {
      return obj.booking.reservation_id !== a;
    });
  }

  chk_chk() {
    if (this.my_object.package != null) {
      if (this.my_object.package.breakfast_include == "Paid") {
        this.chkchk = true;
      } else if (this.my_object.package.breakfast_include == "Complimentry") {
        this.chkchk2 = true;
      } else {
        this.chkchk = false;
        this.chkchk2 = false;
      }
    }
  }

  active1() {
    let a = this.lastpageNm - 1;
    this.selectedIndex = a;
  }

  scCustom() {
    setTimeout(() => {
      let a = document.getElementById("cuspagidiv");
      var elmnt = (document.getElementById("cuspagidiv").scrollLeft += 30);
    }, 400);
  }

  prev() {
    this.selectedIndex -= 1;
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + this.selectedIndex,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.arrivals = res.body.data.all.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    var elmnt = (document.getElementById("cuspagidiv").scrollLeft -= 30);
  }

  next() {
    this.selectedIndex += 1;
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/booking/arrivals?page=" + this.selectedIndex,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.arrivals = res.body.data.all.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    var elmnt = (document.getElementById("cuspagidiv").scrollLeft += 30);
  }

  chngealpha(name) {
    let a = name.split(".");
    return a[0].toLowerCase();
  }

  scCustom9(no) {
    var element = document.getElementById("cuspagidiv");
    if (this.allPcount > no) {
      if (this.allPcount > 100) {
        element.scrollLeft -= 50;
      } else {
        element.scrollLeft -= 30;
      }
    } else if (this.allPcount < no) {
      if (this.allPcount > 100) {
        element.scrollLeft += 50;
      } else {
        element.scrollLeft += 30;
      }
    }
  }

  rotateDrop(id) {
    let a = document.getElementById(id);
    a.classList.contains("rotate")
      ? a.classList.remove("rotate")
      : a.classList.add("rotate");
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  nightsTotal(en, st) {
    let a = moment(en);
    let b = moment(st);
    this.totalNights = a.diff(b, "days");
  }

  EditReservForm(con) {
    if (this.reservation_form != undefined) {
      this.reservation_form.reset();
    }

    let promocode;
    if (con.SpecialRequests != undefined) {
      var ret = con.SpecialRequests;
      try {
        let testJson;
        testJson = JSON.parse(con.SpecialRequests);
        ret = "";
        testJson.SpecialRequest.map((val) => {
          ret += val.Text["@content"] + "." + "\n";
        });
      } catch (e) {
        // ret = false;
      }
    }
    con.promo != null
      ? con == ""
        ? (promocode = null)
        : (promocode = con.promo.promo_id)
      : (promocode = null);
    let gCt;
    // if(con.guest_card_type == 'MC'){
    // 	gCt = 'Master Card'
    // }else{
    // 	gCt = con.guest_card_type
    // }
    console.log(con);
    if (con.prices != null) {
     
      var strArr = con.prices.split(",");
      var sum = strArr.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
      var tex: any = `0.${con.Taxper}`;
      var with_extra = sum + parseInt(con.extra_person_fee || 0);
      var extras = con.bookingitems[0].extras;
      if (extras != null) {
        let extra = [];
        this.ext = true;
        console.log(this.ext);
        let e = JSON.parse(extras);
        e.forEach((element) => {
          extra.push(element.price);
        });
     
        var extra_sum = extra.reduce(
          (acc, cur) => parseInt(acc) + parseInt(cur),
          0
        );
      }
      else{
        this.ext = false;
      }
    }
    var extra = extra_sum || 0;
    let tac_value = parseInt(tex) * parseInt(sum);
    // var final =    parseInt(con.total +with_extra)
    var collect = ["Hotel Collect", "Expedia Collect"];
    this.reservation_form = this.fb.group({
      property_id: this.current_property,
      channel_id: [con.channel_id, Validators.required],
      room_id: "",
      package_id: "",
      season_id: con.season_id,
      count_adult: "",
      count_child: "",
      total: [con.total, Validators.required],
      prices: [con.prices, Validators.required],
      guest_firstname: [con.guest_firstname, Validators.required],
      guest_lastname: "",
      // payment_status: con.payment_status,
      // status: con.status,
      guest_card_expiry: [con.guest_card_expiry],
      guest_card: con.guest_card,
      guest_card_series_code: con.guest_card_series_code,
      currency: con.currency,
      child_ages: "",
      guest_phone: [con.guest_phone, Validators.required],
      guest_address: [con.guest_address, Validators.required],
      guest_email: [
        con.guest_email,
        Validators.compose([Validators.required, Validators.email]),
      ],
      comments: con.comments,
      room_number: "",
      guest_city: [con.guest_city, Validators.required],
      booking_items: this.fb.array([]),
      payee_name: con.payee_name,
      privilege: [con.privilege, Validators.required],
      payment_status: [con.payment_status, Validators.required],
      status: [con.status, Validators.required],
      marketing_email: "",
      eta: [con.eta, Validators.required],
      etd: [con.etd, Validators.required],
      personal_id_type: [con.personal_id_type, Validators.required],
      personal_id_value: [con.personal_id_value, Validators.required],
  
      company_name: "",
      promo: promocode,
      guest_card_type: con.guest_card_type,
      date_departure: [
        {
          startDate: moment(con.date_departure),
          endDate: moment(con.date_departure),
        },
        Validators.required,
      ],
      date_arrival: [
        {
          startDate: moment(con.date_arrival),
          endDate: moment(con.date_arrival),
        },
        Validators.required,
      ],
      TaxInclusive: con.TaxInclusive,
      SpecialRequests: ret,
      channel_name: [con.channel_name, Validators.required],
      pos: con.pos,
    
      collect_details: [collect[con.collect_details], Validators.required],
      extra_person_fee: [con.extra_person_fee || 0],
      Taxperr: [(tex * sum).toFixed(2)],
      cashDeposit: [con.cashDeposit || 0],
      subtotal: [with_extra],
      discount: [con.discount || 0],
      extras: [extra_sum || 0],
    });
    // final = 0;
    extra = 0;
    sum = 0;
    tex = 0;
    with_extra = 0;
 
  }

  condition(con) {
    let a = con;
    let b = Boolean(a == "true" ? (a = "true") : (a = ""));
    return b;
  }

  getseason() {
    this.api
      .post(
        "api/season_package/index",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0");
          var yyyy = today.getFullYear();
          this.todayDate = yyyy + "-" + mm + "-" + dd;
          res.body.data.seasons.data.map((val1) => {
            if (
              this.todayDate <= val1.date_from &&
              this.todayDate >= val1.date_to
            ) {
              this.urrent_season = val1;
              this.current_season_id = val1.id;
              this.allPackages = val1.packages;
              this.allPackages2 = val1.packages;
            }
          });
          this.getroomtype(
            "",
            "",
            "",
            this.urrent_season.date_to,
            this.urrent_season.date_from
          );
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  getroomtype(smoking, wheelchair, pets, startDate, endDate) {
    let a = {
      property_id: localStorage.getItem("current_property"),
      start_date: startDate,
      end_date: endDate,
      wheelchairaccessibility: wheelchair.toString(),
      smoking: smoking.toString(),
      pets: pets.toString(),
    };
    this.api.post("api/booking/reservation_room_types", a, true).subscribe(
      (res: any) => {
        this.room_name = res.body.data;
        // console.log(this.room_name)
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  getroomnumber(id, index, resId) {
    let idee;
    if (id == undefined) {
      return;
    }

    id.room_id === undefined ? (idee = id) : (idee = id.room_id);
    let data = {
      date_arrival: moment(
        this.reservation_form.value.date_arrival.endDate
      ).format("YYYY-MM-DD"),
      date_departure: moment(
        this.reservation_form.value.date_departure.endDate
      ).format("YYYY-MM-DD"),
      room_id: idee,
      property_id: localStorage.getItem("current_property"),
      edit: true,
    };

    this.api
      .post("api/housekeeping/get_room_no_by_room_id", { data }, true)
      .subscribe(
        (res: any) => {
          this.room_no_id_dataa = res.body.data;
          this.room_number = this.room_no_id_dataa;
          this.api
            .post(
              "api/housekeeping/get_selcted_rooms",
              { room_id: idee, room_no_id_data: this.room_no_id_data },
              true
            )
            .subscribe(
              (res: any) => {
                this.room_number = this.room_no_id_dataa;
                this.room_number.push(res.body.data[0]);
                this.room_no_id = res.body.data[0]["id"];
                // console.log(this.room_number);
              },
              (err) => this.error_handling.handle_error(err.status)
            );
        },
        (err) => this.error_handling.handle_error(err.status)
      );
    let langArr = <FormArray>this.reservation_form.controls["booking_items"];
    this.reservation_form.value.booking_items.map((val, index) => {
      langArr.controls[index].patchValue({
        check_in: {
          startDate: moment(val.check_in),
          endDate: moment(val.check_in),
        },
        check_out: {
          startDate: moment(val.check_out),
          endDate: moment(val.check_out),
        },
      });
    });
  }

  chckChckbox(id, con) {
    let a = <HTMLInputElement>document.getElementById("test" + id);
    let b = <HTMLInputElement>document.getElementById("test2" + id);
    if (con == "Paid") {
      a.checked = true;
      b.checked = false;
    } else if (con == "Complimentry") {
      b.checked = true;
      a.checked = false;
    } else {
      a.checked = false;
      b.checked = false;
    }
  }

  getWeather() {
    let cities = ["karachi", "new york", "toronto", "london", "cape town"];
    let user = JSON.parse(localStorage.getItem("user"));
    cities.forEach((city) => {
      let req =
        "https://api.openweathermap.org/data/2.5/weather?q=" +
        city +
        "&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1";
      let forcast =
        "https://api.openweathermap.org/data/2.5/forecast/daily?q=" +
        city +
        "&cnt=7&lang=en&units=metric&APPID=bfab95ebe3bbb8966c54139aefd539b4";
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split(".")[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString("en-us", { weekday: "short" }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: [],
        };
        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach((element) => {
            var day = new Date(element.dt * 1000).toLocaleTimeString("en-us", {
              weekday: "short",
            });
            var icon = element.weather[0].icon.split(".")[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({ day, icon, min_temp, max_temp });
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });
  }

  fliBox() {
    $(function () {
      setTimeout(() => {
        $("#flip").flipbox({
          vertical: true,
          autoplay: true,
          autoplayReverse: false,
          autoplayWaitDuration: 50000,
          autoplayPauseOnHover: true,
        });
      }, 100);
    });
  }

  map_boxes(rateApiCon) {
    setTimeout(() => {
      let langArr = <FormArray>this.reservation_form.controls["booking_items"];
      this.reservation_form.value.booking_items.map((val00, index) => {
        if (val00.check_in.endDate) {
          if (this.my_object.same_date === "1") {
            this.reservation_form.value.booking_items.map((valp, io) => {
              langArr.controls[io].patchValue({
                check_in: moment(
                  this.reservation_form.value.date_arrival.endDate._d
                ).format("YYYY-MM-DD"),
                check_out: moment(
                  this.reservation_form.value.date_departure.endDate._d
                ).format("YYYY-MM-DD"),
              });
              this.my_object.date_arrival = moment(
                this.reservation_form.value.date_arrival.endDate._d
              ).format("YYYY-MM-DD");
              this.my_object.date_departure = moment(
                this.reservation_form.value.date_departure.endDate._d
              ).format("YYYY-MM-DD");
              this.chngedata(io, rateApiCon);
            });
          } else {
            if (
              this.reservation_form.value.booking_items[index].check_in
                .endDate != undefined ||
              this.reservation_form.value.booking_items[index].check_in
                .endDate != null
            ) {
              langArr.controls[index].patchValue({
                check_in: moment(
                  this.reservation_form.value.booking_items[index].check_in
                    .endDate._d
                ).format("YYYY-MM-DD"),
              });
              if (this.reservation_form.value.date_arrival.endDate) {
                this.my_object.date_arrival = moment(
                  this.reservation_form.value.date_arrival.endDate._d
                ).format("YYYY-MM-DD");
              } else {
                this.my_object.date_arrival = moment(
                  this.reservation_form.value.date_arrival
                ).format("YYYY-MM-DD");
              }
              this.chngedata(index, rateApiCon);
            }
            if (
              this.reservation_form.value.booking_items[index].check_out
                .endDate != undefined ||
              this.reservation_form.value.booking_items[index].check_out
                .endDate != null
            ) {
              langArr.controls[index].patchValue({
                check_out: moment(
                  this.reservation_form.value.booking_items[index].check_out
                    .endDate._d
                ).format("YYYY-MM-DD"),
              });
              if (this.reservation_form.value.date_arrival.endDate) {
                this.my_object.date_departure = moment(
                  this.reservation_form.value.date_departure.endDate._d
                ).format("YYYY-MM-DD");
              } else {
                this.my_object.date_departure = moment(
                  this.reservation_form.value.date_departure
                ).format("YYYY-MM-DD");
              }
              this.chngedata(index, rateApiCon);
            } else {
              langArr.controls[index].patchValue({
                check_in:
                  this.reservation_form.value.booking_items[index].check_in,
                check_out:
                  this.reservation_form.value.booking_items[index].check_out,
              });
              this.my_object.date_arrival =
                this.reservation_form.value.date_arrival;
              this.my_object.date_departure =
                this.reservation_form.value.date_departure;
              this.chngedata(index, rateApiCon);
            }
          }
        } else {
          if (this.my_object.same_date === "1") {
            this.reservation_form.value.booking_items.map((valp, io) => {
              langArr.controls[io].patchValue({
                check_in: moment(
                  this.reservation_form.value.date_arrival.endDate._d
                ).format("YYYY-MM-DD"),
                check_out: moment(
                  this.reservation_form.value.date_departure.endDate._d
                ).format("YYYY-MM-DD"),
              });
              this.my_object.date_arrival = moment(
                this.reservation_form.value.date_arrival.endDate._d
              ).format("YYYY-MM-DD");
              this.my_object.date_departure = moment(
                this.reservation_form.value.date_departure.endDate._d
              ).format("YYYY-MM-DD");
              this.chngedata(io, rateApiCon);
            });
          } else {
            langArr.controls[index].patchValue({
              check_in:
                this.reservation_form.value.booking_items[index].check_in,
              check_out:
                this.reservation_form.value.booking_items[index].check_out,
            });
            this.my_object.date_arrival = moment(
              this.reservation_form.value.date_arrival.endDate._d
            ).format("YYYY-MM-DD");
            this.my_object.date_departure = moment(
              this.reservation_form.value.date_departure.endDate._d
            ).format("YYYY-MM-DD");
            this.chngedata(index, rateApiCon);
          }
        }
      });
      //  this.chngedata(ii,rateApiCon)
    }, 100);
  }

  getChannelId(id) {
    this.allPromo = [];
    let isee;
    id.id === undefined ? (isee = id) : (isee = id.id);
    let a = {
      channel_id: isee,
      property_id: localStorage.getItem("current_property"),
    };
    this.api
      .post("api/package/promo_search_by_channel", a, true)
      .subscribe((res: any) => {
        this.allPromo = res.body.data;
      });
  }

  getPromo(data, con) {
    setTimeout(() => {
      let a = <HTMLInputElement>document.getElementById("discountPromo");
      let b = [];
      this.map_boxes("false");
      if (data != undefined) {
        a.readOnly = true;
        this.roomFilter = false;
        this.reservation_form.patchValue({
          discount: data.promo.discount,
        });
        this.promoDiscount = data.promo.discount;
        this.allPackages.map((val) => {
          val.promo_allocations.map((val2) => {
            if (val2.promo_id == data.promo_id) {
              b.push(val);
            }
          });
        });
        this.allPackages = b;
      } else {
        a.readOnly = false;
        this.roomFilter = true;
        this.allPackages = this.allPackages2;
      }
      if (con == "true") {
        this.calculatPrice();
      }
    }, 100);
  }

  async chngedata(ii,hitApi){
    let pId;
    let langArr = <FormArray>this.reservation_form.controls["booking_items"];
    if(this.reservation_form.value.booking_items.length == 0){
      return
    }if(ii == undefined){
      return
    }
      if(this.reservation_form.value.booking_items[ii].package_id == 0){
           pId = 0
      }else{
           pId = this.reservation_form.value.booking_items[ii].package_id
    }
    let abh ;
    let abo ;
    if(this.reservation_form.value.booking_items[ii].check_in.endDate){
      abh = moment(this.reservation_form.value.booking_items[ii].check_in.endDate._d).format('YYYY-MM-DD')
    }else{
      abh = this.reservation_form.value.booking_items[ii].check_in
    }
    if(this.reservation_form.value.booking_items[ii].check_out.endDate){
      abo = moment(this.reservation_form.value.booking_items[ii].check_out.endDate._d).format('YYYY-MM-DD')
    }else{
      abo = this.reservation_form.value.booking_items[ii].check_out
    }
    if(this.reservation_form.value.booking_items[ii].check_in.endDate != undefined ){
      langArr.controls[ii].patchValue({
        check_in:moment(this.reservation_form.value.booking_items[ii].check_in.endDate._d).format('YYYY-MM-DD'),
      });
    }
    if(this.reservation_form.value.booking_items[ii].check_out.endDate != undefined ){
      langArr.controls[ii].patchValue({
        check_out:moment(this.reservation_form.value.booking_items[ii].check_out.endDate._d).format('YYYY-MM-DD')
      });
    } 
    
    
    let a = {
      start_date:abh,
      end_date:abo,
      property_id:localStorage.getItem("current_property"),
      room_id:this.reservation_form.value.booking_items[ii].room_id,
      package_id:pId,
      }
      if(this.reservation_form.value.booking_items[ii].room_id == null){
        return
      }
    if(hitApi == 'true'){
      await this.api.post('api/booking/get_rates_by_checkin_checkout',a , true).subscribe((res: any) => {
        console.log(res.body.data);
        // this.inventory_calendar.unshift({
        // 	roomName:res.body.data[0].name,
        // 	roomIndex:ii,
        // 	rates:res.body.data
        // })
        let yu = {
          roomName:res.body.data[0].name,
          roomIndex:ii,
          rates:res.body.data,
          display_name:res.body.data[0].display_name == null ? res.body.data[0].name  : res.body.data[0].display_name,
        }
        // this.inventory_calendar = Array.from(new Set(this.inventory_calendar.map(a => a.roomIndex)))
        // .map(roomIndex => {
        // 	return this.inventory_calendar.find(a => a.roomIndex === roomIndex)
        // })
        langArr.controls[ii].patchValue({
          pricesjson:JSON.stringify(yu)
        })
        let comaArrayRate = [];
        // this.inventory_calendar.map((val)=>{
        // 	if(val.roomIndex == ii){
        // 		val.rates.map((val2)=>{
        // 			comaArrayRate.push(val2.price)
        // 		})
        // 	}
        // })
        this.reservation_form.value.booking_items.map((val)=>{
          if(JSON.parse(val.pricesjson).roomIndex == ii){
            JSON.parse(val.pricesjson).rates.map((val2)=>{
              comaArrayRate.push(val2.price)
            })
          }
        })
        langArr.controls[ii].patchValue({
          prices:comaArrayRate.toString()
        });
        this.pricesArray=[];
        this.reservation_form.value.booking_items.map((val)=>{
          this.pricesArray.push(val.prices)
        })
        this.reservation_form.patchValue({
          prices:this.pricesArray.toString()
        })
        this.calculatPrice()
      }, err => this.error_handling.handle_error(err.status, err.message)) 
    }
    else if(hitApi == 'false'){
      try{
        let comaArrayRate = [];
        this.inventory_calendar = JSON.parse(this.my_object.bookingitems[ii].pricesjson);
        this.inventory_calendar.map((val)=>{
          if(val.roomIndex == ii){
            val.rates.map((val2)=>{
              comaArrayRate.push(val2.price)
            })
          }
        })
  
        langArr.controls[ii].patchValue({
          prices:comaArrayRate.toString()
        });
        this.pricesArray=[];
        this.my_object.bookingitems.map((val)=>{
          this.pricesArray.push(val.prices)
        })
        this.reservation_form.patchValue({
          prices:this.pricesArray.toString()
        })
        this.calculatPrice()
      }
      catch(err){
      }
    }
    
    this.applyChkOutDate = false
    let discountPromo = (<HTMLInputElement>document.getElementById('discountPromo'))
    if(this.reservation_form.value.promo != null){
      discountPromo.readOnly = true
    }else{
      discountPromo.readOnly = false
    }
  }

  // calculatPrice() {
  //   setTimeout(() => {
  //     let sum = 0;
  //     let comaArrayRate = [];
  //     let g = this.reservation_form.value.prices.split(",");
  //     let z = g.map((elem) => parseInt(elem, 10));
  //     for (let i = 0; i < z.length; i++) {
  //       sum += z[i];
  //       comaArrayRate.push(z[i]);
  //     }
  //     let r = sum / 100;
  //     let discountedTotal = sum - r * this.reservation_form.value.discount;
  //     this.reservation_form.patchValue({
  //       total: Math.round(discountedTotal),
  //       season_id: this.current_season_id,
  //     });
  //   }, 1000);
  // }

  calculatPrice() {
    if(this.disabled == false){
    if (this.reservation_form.value.prices != null) {
       
      var sum: any = 0;
      var extra = this.extra_person_fee == null ? 0 : this.extra_person_fee;
      var comaArrayRate = [];
      var g = this.reservation_form.value.prices.split(",");
      var z = g.map((elem) => parseInt(elem, 10));

      for (let i = 0; i < z.length; i++) {
        sum += z[i];
        comaArrayRate.push(z[i]);
      }
     console.log(sum);
      var summ = parseInt(extra) + parseInt(sum);

      var r = summ / 100;
      var dis = this.reservation_form.value.discount || 0;
     
      var discountedTotal = summ - r *  dis;
      var b = JSON.parse(localStorage.getItem("property"));
      var formula: any;
      console.log(sum);
      this.TaxInclusive == "false"
        ? (formula = "0." + b.taxper)
        : (formula = 0);

      this.TaxInclusive = b.taxInclusive;
      this.subtotal = summ
       
      
      var percantage = discountedTotal * formula;
    
      this.TaxInclusive == "false" ? (b.taxper = formula) : "";

      this.TaxInclusive == "false" ? (this.Tax = Math.round(percantage)) : "";
 
      this.reservation_form.patchValue({
        total: Math.round(discountedTotal + percantage),
        season_id: this.current_season_id,
        Taxper: this.taxper,
      });
    } else {
      return;
    }
  }
  }

  chngeAcc(con) {
    con = !con;
  }

  chkPackage(data) {
    this.getroomtype(
      data.room.smoking,
      data.room.wheelchairaccessibility,
      data.room.pets,
      this.urrent_season.date_to,
      this.urrent_season.date_from
    );
  }

  chnageicon1() {
    this.showsmoke = !this.showsmoke;
  }

  chnageicon2() {
    this.showwheel = !this.showwheel;
  }

  chnageicon3() {
    this.showpets = !this.showpets;
  }

  RoomIconFilter() {
    setTimeout(() => {
      this.getroomtype(
        this.showsmoke,
        this.showwheel,
        this.showpets,
        this.urrent_season.date_to,
        this.urrent_season.date_from
      );
    }, 200);
  }

  sideMenu(id1) {
    let a = <HTMLInputElement>document.getElementById(id1);
    a.style.right == "0px"
      ? (a.style.right = "-244px")
      : (a.style.right = "0px");
  }

  getRoomOfGuest(data) {
    if (data.bookingitems.length == 1) {
      if (data.room.display_name == "" || data.room.display_name == null) {
        return data.bookingitems[0].room.roomtype.name;
      } else {
        return data.room.display_name;
      }
    } else {
      return "Bulk Booking";
    }
  }

  daysTotal(en, st) {
    let a = moment(en);
    let b = moment(st);
    let c = a.diff(b, "days");
    let days;
    c == 1 ? (days = "night") : (days = "nights");
    return c + " " + days;
  }

  changeSvg(param) {
    this[param] = !this[param];
  }

  changeSegment(con, id) {
    let segment = <HTMLInputElement>document.getElementById(id);
    con == "true"
      ? ((segment.style.left = "2%"),
        setTimeout(() => {
          this.segmonLeft = true;
        }, 200))
      : con == "false"
      ? ((segment.style.left = "52%"),
        setTimeout(() => {
          this.segmonLeft = false;
        }, 200))
      : null;
  }

  getCheckOut(data) {
    this.expenceTotalPrice = [];
    let a = {
      start_date: data.check_in,
      end_date: data.check_out,
      property_id: localStorage.getItem("current_property"),
      room_id: data.room_id,
      package_id: data.package_id,
    };
    let b = [];
    if (data.room_id == null) {
      return;
    }
    this.api
      .post("api/booking/get_rates_by_checkin_checkout", a, true)
      .subscribe((res: any) => {
        res.body.data.map((val) => {
          b.push(val.price);
        });
        b = b.reduce((a, b) => a + b, 0);
        this.checkoutdata = data;
        this.checkoutdata["totalPrice"] = b;
      });
    this.checked_out_guestform = this.fb.group({
      expenses: this.fb.array([]),
    });
  }

  async addExpenses(control, name, price) {
    await control.push(
      this.fb.group({
        expense_name: [name, Validators.required],
        price: [price, Validators.required],
      })
    );

    console.log(this.checked_out_guestform.value.expenses);
    this.calculatPrice_extra(price);
    this.expensePrice = null;
    this.expenseVal = null;
    await this.expenceTotal();
  }

  get f() {
    return this.checked_out_guestform.controls;
  }

  checkoutGuest(invoice) {
  
    this.submittedCheckOut = true;
    if (this.checked_out_guestform.invalid) {
      return;
    }
 
    this.api
      .post(
        "api/booking/update_booking_item/" +invoice.id,
        {
          status: "Checked Out",
          extras: JSON.stringify(this.checked_out_guestform.value.expenses),
          fb_price:invoice.fb_price,
          fb_tax:invoice.fb_tax,
          total:invoice.finaltotal

        },
        true
      )
      .subscribe(
        (res: any) => {
          this.helper.alert_success(invoice.name + " " + "check out successfully!");
          this.get_arrivals();
          $("#payNowGuest").modal("hide");
        },
        (err) => this.helper.alert_error(err)
      );
  }

  removeExpence(i) {
    console.log(i);
    let checkOutArr = <FormArray>(
      this.checked_out_guestform.controls["expenses"]
    );
    console.log(checkOutArr.value);
    checkOutArr.removeAt(i);
    this.calculatPrice_extra(checkOutArr);
  }

  chngeDateArr(data) {
    if (typeof data.date_arrival == "object") {
      return moment(data.date_arrival.endDate._d).format("YYYY-MM-DD");
    } else {
      return data.date_arrival;
    }
  }

  expenceTotal() {
    this.expenceTotalPrice = this.checked_out_guestform.value.expenses
      .map((item) => parseInt(item.price))
      .reduce((prev, curr) => prev + curr, 0);
  }

  chngeDatedep(data) {
    if (typeof data.date_departure == "object") {
      return moment(data.date_departure.endDate._d).format("YYYY-MM-DD");
    } else {
      return data.date_departure;
    }
  }

  async paynowMainRes(data) {
    if (data.payment_status == "paid") {
      this.paymentPaid = true;
    } else {
      this.paymentPaid = false;
    }
    this.paynowPopup = data;

    this.paynowPopup.bookingitems.forEach((element) => {
      if (element.status == "Checked In") {
        this.showCancellOnPay = false;
      } else {
        this.showCancellOnPay = true;
      }
    });
    this.reservation_form.patchValue(data);
  }

  chkPayStatus(data) {
    if (data == "paid") {
      this.reservation_form.patchValue({
        status: "confirm",
      });
    }
  }

  savePayment() {
    let a = {
      payment_status: this.reservation_form.value.payment_status,
      status: this.reservation_form.value.status,
      property_id: localStorage.getItem("current_property"),
    };
    this.api
      .post("api/booking/update/" + this.paynowPopup.id, a, true)
      .subscribe(
        (res: any) => {
          this.get_arrivals();
          $("#payNow").modal("hide");
          this.helper.alert_success("Booking update successfully.");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  chngeFilters(data) {
    this.filterBy = data;
  }

  getExpenses() {
    this.api
      .get(
        "api/property/get_extras/" + localStorage.getItem("current_property"),
        true
      )
      .subscribe(
        (res: any) => {
          this.allExpenses = res.body.data;
          this.allExpenses = this.allExpenses.filter(
            (item) => item.type !== "upsell"
          );
        },
        (err) => this.error_handling.handle_error(err)
      );
  }

  getExpenceVal(val) {
    this.allExpenses.forEach((element) => {
      if (element.name == val) {
        this.expensePrice = element.price;
      }
    });
  }

  convertJson(data, i) {
    let a = JSON.parse(data[i].pricesjson);

    return a;
  }

  getRandomColor() {
    let elements;
    console.log(this.randomColors);
    elements = document.getElementsByClassName("modal-bar-2");
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.reservation_form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
  }
  permissions() {
    var data = this.user.permissions;

    for (let i = 0; i < data.length; i++) {
      if (this.user.permissions[i]["app_permission"]["name"] == "Discounts") {
        this.Discounts = "true";
      }
    }
  }
  showerror() {
    this.toastr.error("Access Denied");
  }

  getValA(data) {
    this.applyChkOutDate = data.target.checked;
    data.target.checked
      ? (this.toolChkData =
          "Uncheck the checkbox to apply seperate dates on each room.")
      : (this.toolChkData =
          "Check the checkbox to apply same dates on all rooms.");
    let a = <FormArray>this.reservation_form.controls["booking_items"];
    a.reset();
    if (!this.applyChkOutDate) {
      for (
        let i = 0;
        i < this.reservation_form.value.booking_items.length;
        i++
      ) {
        a.controls[i].patchValue({
          check_in: {
            startDate: moment(new Date()),
            endDate: moment(new Date()),
          },
          check_out: {
            startDate: moment(new Date()),
            endDate: moment(new Date()),
          },
        });
      }
      this.pricesArray = [];
      this.comaArrayRate = [];
    }
  }

  //   addNewGuest(control,data,index){
  //     if(data == null){
  //       control.push(
  //         this.fb.group({
  //           guest_name:  [null, Validators.required],
  //           room_id: [null, Validators.required],
  //           room_no_id: null,
  //           guest_id: '',
  //           package_id: null,
  //           season_id: this.current_season_id,
  //           promotion_id: '',
  //           no_of_adults: [null, Validators.required],
  //           no_of_childs: [null, Validators.required],
  //           no_of_infants: [null, Validators.required],
  //           breakfast:[null, Validators.required],
  //           check_in:null,
  //           check_out:null,
  //           prices:null,
  //           status:'Pending',
  //           pricesjson:null
  //         })
  //       );
  //     }else{
  //       let pId;
  //       if(data.package_id == 0){
  //         pId=null
  //       }else{
  //         pId = data.package_id
  //       }
  //       control.push(
  //         this.fb.group({
  //           guest_name:  [data.guest_name, Validators.required],
  //           room_id: [data.room_id, Validators.required],
  //           room_no_id: data.room_no_id,
  //           guest_id: data.guest_id,
  //           package_id: pId,
  //           season_id: data.season_id,
  //           promotion_id: data.promotion_id,
  //           no_of_adults: [data.no_of_adults, Validators.required],
  //           no_of_childs: [data.no_of_childs, Validators.required],
  //           no_of_infants: [data.no_of_infants, Validators.required],
  //           breakfast:[data.breakfast, Validators.required],
  //           check_in:[
  //             {startDate: moment(data.check_in), endDate: moment(data.check_in)},
  //             Validators.required],
  //           check_out:[
  //             {startDate: moment(data.check_out), endDate: moment(data.check_out)},
  //             Validators.required],
  //           prices:data.prices
  //         })
  //       );

  //     }
  //   }

  // openChannelsModal(){
  //   // this.showBoxOta = true;
  //   setTimeout(()=>{
  //     $( "#draggable" ).draggable();
  //   }, 300);
  // }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
 
  pay_now(arrival) {
   
    this.paynow_invoice ='';
    arrival.finaltotal = 0;
    var sub: any = 0;
    var extra_person_fee = arrival.booking.extra_person_fee || 0;
    var deposite: any = arrival.booking.cashDeposit || 0;
    var property = JSON.parse(localStorage.getItem("property"));

    this.price_json = JSON.parse(arrival.pricesjson);
    this.paynow_dis = arrival.booking.discount || `$ 0`;
    //  let prices = arrival.prices.split(',');
    //  var sum = prices.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
    var prices = JSON.parse(arrival.pricesjson);
    var prices_sum = 0;
    prices.rates.forEach((element) => {
      prices_sum += element.price;
    });
    let with_extra = prices_sum + parseInt(arrival.booking.extra_person_fee);

    var tax: any = `0.` + arrival.booking.Taxper || 0;
    var tax_value = with_extra * tax || 0;
    arrival.subtotal = with_extra || 0;
    arrival.tax_value = tax_value || 0;
    
    arrival.finaltotal = (arrival.booking.total - deposite );
    this.paynow_invoice = arrival;
  }

  calculatPrice_extra(a) {
 

   this.extra_price.push(a);
   let sum = this.extra_price.reduce((a, b) => parseInt(a) + parseInt(b));
 


    let prices = JSON.parse(this.paynow_invoice.pricesjson);
    let prices_sum: any = 0;
    prices.rates.forEach((element) => {
      prices_sum += element.price;
    });
    this.paynow_invoice.booking.extas_amount = sum
    var fb_price = this.paynow_invoice.fb_price || 0
    if(this.paynow_invoice.booking.TaxInclusive == 'false'){
      this.paynow_invoice.finaltotal = 0
    let with_extra =  prices_sum + parseInt(this.paynow_invoice.booking.extra_person_fee);
    let subtotal = with_extra + parseInt(sum);  
    this.paynow_invoice.subtotal = subtotal;
    let tax: any = `0.` + this.paynow_invoice.booking.Taxper || 0;
    let deposite: any = this.paynow_invoice.booking.cashDeposit || 0;
    let tax_value =  (tax * subtotal).toFixed(2)  
    this.paynow_invoice.tax_value = tax_value;
    var min_dep = subtotal - parseInt(deposite);
    this.paynow_invoice.finaltotal = min_dep + parseInt(tax_value) + parseInt(fb_price)  ;
    console.log(`min_dep ${min_dep} - sum ${sum} tax_value ${tax_value} - fb_price ${fb_price}` )

    }
    else{
      let with_extra =  prices_sum + parseInt(this.paynow_invoice.booking.extra_person_fee);
      let subtotal = with_extra + parseInt(sum);  
      let deposite: any = this.paynow_invoice.booking.cashDeposit || 0;
      this.paynow_invoice.subtotal = subtotal;
      this.paynow_invoice.finaltotal = subtotal - parseInt(deposite);

    }
    
  }

  discount_offer(val) {
    if (this.restriction == true) {
      let mini = this.paynow_invoice.total_Price - val;
      this.paynow_invoice.total = mini;
      this.paynow_invoice.final_value = mini + this.paynow_invoice.texrate;
    }

    if (this.restriction == false) {
      let per: any = `0.${val}`;
      let cal = per * this.paynow_invoice.total_Price;
      let mini = this.paynow_invoice.total_Price - cal;
      this.paynow_invoice.total = mini;
      this.paynow_invoice.final_value = mini + this.paynow_invoice.texrate;
    }
  }

  convert_date(date) {
    return moment(date).format("DD  MMM");
  }
  convert_month(date) {
    return moment(date).format("ddd");
  }
  date_type(date) {
    return moment(date).format("ddd DD MMM");
  }
  debug(a: any) {
    console.clear();
    console.log(a);
  }

  guest_status(a: any) {
    console.log(a);
    let status = document.getElementById("status");
    let nowshow = "assets/img/newstaticicons/arrivals/no-show-icon.svg";
    let Pending_light = "assets/img/newstaticicons/arrivals/waiting-icon-2.svg";
    let Pending_dark = "assets/img/newstaticicons/arrivals/waiting-icon-2.svg";
    let checkedIn = "assets/img/dashboard/in-house2.svg";
    if (a == "No Show") {
      status.setAttribute("src", nowshow);
    }
    if (a == "Checked In") {
      status.setAttribute("src", checkedIn);
    }
    if (a == "Pending") {
      this.darkMode == false
        ? status.setAttribute("src", Pending_light)
        : status.setAttribute("src", Pending_dark);
    }
  }
  letmecheck(a:any){
    console.log(a);
  }
  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;
 
    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}
fnb(a){
 
 var b:any =  (<HTMLInputElement>document.getElementById('price'));
 var tax:any = `0.${a}`;
  
 var tax_amount:any = (tax * b.value);
 var amount = (parseInt(tax_amount)+parseInt(b.value))
 
 let prices = JSON.parse(this.paynow_invoice.pricesjson);
 let prices_sum: any = 0;
 prices.rates.forEach((element) => {
   prices_sum += element.price;
 });
var sum = this.paynow_invoice.booking.extas_amount || 0
 if(this.paynow_invoice.booking.TaxInclusive == 'false'){
  this.paynow_invoice.finaltotal = 0
let with_extra =  prices_sum + parseInt(this.paynow_invoice.booking.extra_person_fee);
let subtotal = with_extra + parseInt(sum);  
this.paynow_invoice.subtotal = subtotal;
let tax: any = `0.` + this.paynow_invoice.booking.Taxper || 0;
let deposite: any = this.paynow_invoice.booking.cashDeposit || 0;
let tax_value =  (tax * subtotal).toFixed(2)  
this.paynow_invoice.tax_value = tax_value;
var min_dep = subtotal - parseInt(deposite);
this.paynow_invoice.fb_price = amount;
this.paynow_invoice.fb_tax = tax_amount;
this.paynow_invoice.finaltotal = min_dep + parseInt(tax_value) + amount ;



}
 
 

}
}

 
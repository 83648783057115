import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { HelperService } from "../../../services/helper.service";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
declare var $:any;
declare var google:any;
@Component({
  selector: 'app-select-hotel',
  templateUrl: './select-hotel.component.html',
  styleUrls: ['./select-hotel.component.css']
})
export class SelectHotelComponent implements OnInit {

  map : any;
  @ViewChild('map', {read:ElementRef, static: false}) mapRef: ElementRef;

  properties = [];
  channels = [];
  users = [];
  propertyList : FormGroup;
  base_url;
  base_url2;
  user;
  showAminModal:boolean = false;
  showDetaModal:boolean = false;
  detaildata;
  amenityData:any;
  darkMode:boolean = false;
  amenCol = ['#7ae997','#de888d','#6c6ce7','#da8639','#f1c1f8','#d4575a','#e7d67c','#4cdbbf','#81a2ff'];
  amenColot = ['#5f5fc0','#51f3d6','#e59196','#ce7219','#daca78','#ba585b','#72df8b','#eabdf5'];
  default_property;

  constructor(
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private confirm:ConfirmationAlertService,
    private router: Router,
  ) {

   }

  ngOnInit() {
    this.checkDarkMode();
    this.get_channels();
    this.getPropertyIndex();
    this.getLocation();
    this.base_url=this.api.base_url;
    this.base_url2=this.api.base_url2;
    this.user = JSON.parse(localStorage.getItem('user'));



  }

  checkDarkMode(){
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if(mode == 'dark'){
      this.darkMode = true;
    }
  }

  getPropertyIndex(){
    let user = JSON.parse(localStorage.getItem('user'));
    let obj = {
      id:user.id,
      email:user.email,
      arr_room_empty:false
    }
    let id = JSON.parse(localStorage.getItem('user')).id;
    this.api.post('api/property/index', obj , true).subscribe((res:any) => {
      this.properties = res.body.data;
      this.get_users();
    }, err => this.error_handling.handle_error(err.status));
  }

  get_channels(){
    this.api.post('api/channels/index?per_page=50',{} ,true).subscribe((res:any) => {
      this.channels = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  get_users(){
    // let id = JSON.parse(localStorage.getItem('user')).id;
    this.properties.forEach((property) => {
      this.api.post('api/user/index',{admin_id:property.id} ,true).subscribe((res:any) => {
        property.users_count = res.body.data.length;
      }, err => this.error_handling.handle_error(err.status, err.message));
    })
  }


  delete(){
    this.confirm.confirm(
        "Back Office",
        'Delete Property - Leisurex Resort',
        ['Delete Property - Leisurex Resort'],
        function(){

        }
    );
  }

  AddNewPropety(){
    // this.confirm.confirm(
    //   "Back Office",
    //   'Add New Property',
    //   ['Add New Property'],
    //   function(){

    //   }
    // );
    this.router.navigate(['/app/back_office/hotel_setup']);
  }

  // switchproperty(id){
  //   this.user.default_property = id;
  //   localStorage.setItem('current_property', id);
  //   this.helper.alert_success('Active propety switched.');
  //   this.api.post('api/user/update/' + this.user.id, this.user, true).subscribe((res:any) => {
  //     localStorage.setItem('user' , JSON.stringify(res.body.data))
  //     localStorage.setItem('current_property', id);
  //     this.helper.alert_success('Active propety switched.');
  //   }, err => this.error_handling.handle_error(err.status));
  // }
  switchproperty(id){
    this.user.default_property = id;
    this.api.post('api/user/update/' + this.user.id, this.user, true).subscribe((res:any) => {
      localStorage.setItem('user' , JSON.stringify(res.body.data))
      localStorage.setItem('current_property', id);
      this.api.get('api/property/show/'+res.body.data.default_property,true).subscribe((res2:any)=>{
        localStorage.setItem('eta', res2.body.data.eta);
        localStorage.setItem('etd', res2.body.data.etd);
      })
      this.helper.alert_success('Active propety switched.');
      // }, err => this.error_handling.handle_error(err.status, err.message));
    }, err => this.error_handling.handle_error(err.status));

  }

  get current_property(){
    return localStorage.getItem('current_property');
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((res)=>{
        this.showMap(res.coords.latitude, res.coords.longitude);
      })
    } else {

    }
  }



  showMap(lat, lng){
    console.log(lng);
    const location = new google.maps.LatLng(lat, lng);
    const options = {
      center: location,
      zoom: 15,

    }
    // this.map = new google.maps.Map(this.mapRef.nativeElement, options);
    // this.addMarker()
  }



addMarker(){

    let marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: this.map.getCenter()
    });

    let content = "<h4>Information!</h4>";
  }

  amenityPro(data){
    this.showAminModal = true;
    this.amenityData = data
    setTimeout(()=>{
      $( "#amenityModal" ).draggable();
      $( "#amenityModal" ).draggable("disable");
    });
  }

  dodrag(id){
    $( "#"+id ).draggable("enable");
  }
  stopDrag(id){
    $( "#"+id ).draggable("disable");
  }

  DetPro(data){
    this.showDetaModal = true;
    this.detaildata = data
    setTimeout(()=>{
      $( "#DetailModal" ).draggable();
      $( "#DetailModal" ).draggable("disable");
    }, 300);
  }
  grab(id){
   document.getElementById(id).style.cursor = 'grab'
  }
  grabbing(id){
    document.getElementById(id).style.cursor = 'grabbing'
   }

}

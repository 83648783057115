import { Component, OnInit } from "@angular/core";
import { LayoutService } from "../../layouts/layout.service";
import { AuthService } from "../../services/auth.service";
declare var $: any;
import { EventsService } from "../../services/events.service";
import { Router } from "@angular/router";

import { ApiService } from "src/app/services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { ToastrService } from "ngx-toastr";
import { HelperService } from "../../services/helper.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  user = JSON.parse(localStorage.getItem("user"));
  darkMode: boolean = false;
  collapse;
  Inventory = "false";
  Reservation = "false";
  Payments = "false";
  Rates_Packages = "false";
  Manage_Otas = "false";
  Reports = "false";
  Billing = "false";
  House_Keeping = "false";
  Back_Office = "false";
  Arrivals = "false";
  Performance = "false";

  base_url2;
  logo;
  constructor(
    private toastr: ToastrService,
    private layout: LayoutService,
    private auth: AuthService,
    public events: EventsService,
    private router: Router,
    private error_handling: ErrorHandlingService,
    private api: ApiService
  ) {
    this.events.receiveDarkMode().subscribe((res: any) => {
      this.darkMode = res.darkMode;
    });

    this.events.receiveLogo().subscribe((res: any) => {
      this.logo = res;
    });
    this.base_url2 = this.api.base_url2;
  }

  ngOnInit() {

    this.collapse = this.layout.collapse;
    this.checkDarkMode();

    this.user = JSON.parse(localStorage.getItem("user"));

    setTimeout(() => {
      this.logo = localStorage.getItem("logo");
    }, 1000);

    $(() => {
      $(".nav li")
        .not(".nav li.bg-white")
        .on("click", () => {
          if ($(window).width() < 456) {
            this.do_collapse();
          }
        });
    });
    this.checking();
    this.permissions();
  }

  do_collapse() {
    this.layout.sidebar_collapse();
    this.collapse = this.layout.collapse;
  }

  logout() {
    this.auth.logout();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  permissions() {
    var user = JSON.parse(localStorage.getItem("user"));
    var data = user.permissions;

    for (let i = 0; i < data.length; i++) {
      if (this.user.permissions[i]["app_permission"]["name"] == "Inventory") {
        this.Inventory = "true";
      }
      if (this.user.permissions[i]["app_permission"]["name"] == "Reservation") {
        this.Reservation = "true";
      }
      if (
        this.user.permissions[i]["app_permission"]["name"] == "House Keeping"
      ) {
        this.House_Keeping = "true";
      }
      if (this.user.permissions[i]["app_permission"]["name"] == "Reports") {
        this.Reports = "true";
      }
      if (
        this.user.permissions[i]["app_permission"]["name"] == "Rates & Packages"
      ) {
        this.Rates_Packages = "true";
      }
      if (this.user.permissions[i]["app_permission"]["name"] == "Back Office") {
        this.Back_Office = "true";
      }
      if (this.user.permissions[i]["app_permission"]["name"] == "Arrivals") {
        this.Arrivals = "true";
      }
      if (
        this.user.permissions[i]["app_permission"]["name"] ==
        "Dashboard > Performance"
      ) {
        this.Performance = "true";
      }
    }
  }

  showerror() {
    this.toastr.error("Access Denied");
  }
  checking() {
    var users = JSON.parse(localStorage.getItem("user"));
    console.log(users);
    if (users == undefined) {
      window.location.reload();
    }
  }
}
